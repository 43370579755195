import styles from "./CompanyInfoHeader.module.css";
import { SafeReportResponse } from "./Conversion";
import { brokerageFromBroker } from "./dashboard/util";

type CompanyInfoHeaderProps = {
  specialtyPropertyInfo: SafeReportResponse;
};

export const CompanyInfoHeader: React.FC<CompanyInfoHeaderProps> = ({
  specialtyPropertyInfo,
}) => {
  const industry =
    specialtyPropertyInfo.report_json.company_info.business_description;
  return (
    <div className={styles.companyInfoContainer}>
      {industry && (
        <div className={styles.companyInfoComponentContainer}>
          <i className="pi pi-briefcase" />
          <span>{industry}</span>
        </div>
      )}
      <div className={styles.companyInfoComponentContainer}>
        <i className="pi pi-building" />
        <span>{`${specialtyPropertyInfo.report_json.naics_info.code_type?.toUpperCase()} ${
          specialtyPropertyInfo.report_json.naics_info.code
        }`}</span>
      </div>
      <div className={styles.companyInfoComponentContainer}>
        <i className="pi pi-user" />
        <span>
          {brokerageFromBroker(
            specialtyPropertyInfo.report_json.company_info.broker_name
          )}
        </span>
      </div>
      <div className={styles.companyInfoComponentContainer}>
        <i className="pi pi-calendar" />
        <span>
          {specialtyPropertyInfo.report_json.company_info.effective_date}
        </span>
      </div>
    </div>
  );
};
