import { useMemo, useRef, useState } from "react";
import { TimesCircleIcon } from "primereact/icons/timescircle";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { ReportStatus } from "../../../api/types";
import { MenuItem } from "primereact/menuitem";
import { readableStatus } from "./util";
import { colorForStatus, iconForStatus } from "./StatusIcon";
import styles from "./ReportActionMenu.module.css";
import { useUpdateReport, useUsers } from "../data";
import * as client from "../../../client";
import { classNames } from "primereact/utils";
import { generateIdenticonDataUrl } from "../../../components/avatar/Avatar";
import { Avatar } from "primereact/avatar";
import { displayNameForUser } from "../../../util";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { SafeReportMetadataResponse } from "../Conversion";

type ReportActionMenuProps = {
  report: SafeReportMetadataResponse;
  isUpcomingRenewal?: boolean;
  setActionMenuIsOpen?: (value: boolean) => void;
};

export const ReportActionMenu: React.FC<ReportActionMenuProps> = ({
  report,
  isUpcomingRenewal,
  setActionMenuIsOpen,
}) => {
  const users = useUsers()?.data;
  const menu = useRef<TieredMenu>(null);
  const updateReport = useUpdateReport(report.report_id);
  const [renewalInquiryModalVisible, setRenewalInquiryModalVisible] =
    useState(false);
  const items = useMemo(() => {
    const baseItems: MenuItem[] = [
      {
        label: "Update status",
        icon: "pi pi-file-edit",
        items: Object.values(ReportStatus).map(
          (status): MenuItem => ({
            icon: (
              <span
                className={classNames(styles.circle, iconForStatus(status))}
                style={{ color: colorForStatus(status) }}
              />
            ),
            label: readableStatus(status),
            command: () => {
              updateReport.mutate({ status: { new_status: status } });
            },
          })
        ),
      },
      {
        label: "Update assignee",
        icon: "pi pi-user-edit",
        items: (users?.all_company_users ?? ([] as (client.User | null)[]))
          .concat([null])
          .map((user) => {
            const label = user ? displayNameForUser(user) : "Unassign";
            return {
              label,
              icon: user ? (
                <Avatar
                  label={label[0].toUpperCase()}
                  shape="circle"
                  image={generateIdenticonDataUrl(label)}
                  style={{
                    height: "24px",
                    width: "24px",
                    marginRight: "8px",
                  }}
                />
              ) : (
                <TimesCircleIcon
                  style={{
                    height: "24px",
                    width: "24px",
                    marginRight: "8px",
                  }}
                />
              ),
              command: () => {
                updateReport.mutate({
                  assignee: { new_assignee: user ? user.id : null },
                });
              },
            };
          }),
      },
    ];
    if (isUpcomingRenewal) {
      baseItems.push({
        label: "Compose email",
        icon: "pi pi-send",
        command: () => setRenewalInquiryModalVisible(true),
      });
    }

    return baseItems;
  }, [users, updateReport, isUpcomingRenewal]);

  return (
    <div>
      <TieredMenu
        model={items}
        popup
        ref={menu}
        breakpoint="767px"
        onShow={() => setActionMenuIsOpen && setActionMenuIsOpen(true)}
        onHide={() => setActionMenuIsOpen && setActionMenuIsOpen(false)}
      />
      <Button
        text
        severity="secondary"
        icon="pi pi-ellipsis-v"
        onClick={(e) => {
          menu.current?.toggle(e);
          e.stopPropagation();
        }}
      />
      {isUpcomingRenewal && (
        <EmailEditor
          renewalInquiryModalVisible={renewalInquiryModalVisible}
          setRenewalInquiryModalVisible={setRenewalInquiryModalVisible}
          report={report}
        />
      )}
    </div>
  );
};

const generateInitialEmailValue = (report: SafeReportMetadataResponse) => `
  <div>Hello,</div>
  <br>
  <div>Last year we quoted <b>${report.report_metadata.company_info.company_name}</b>. We are 60 days from the renewal and have not received a copy of this submission.</div>
  <br>
  <div>We provided quotes on the layers listed below:</div>
  <br>
  <div>15MM primary layer</div>
  <div>10MM xs 15MM</div>
  <div>25MM xs 25MM</div>
  <br>
  <div>The renewal on this policy is coming up and we'd love another chance to win your business. Please send us a submission and we will provide a quote!</div>
  <br>
  <div>Thank you,</div>
  <div>Canopius Team</div>
`;

type EmailEditorProps = {
  renewalInquiryModalVisible: boolean;
  setRenewalInquiryModalVisible: (value: boolean) => void;
  report: SafeReportMetadataResponse;
};

const EmailEditor: React.FC<EmailEditorProps> = ({
  renewalInquiryModalVisible,
  setRenewalInquiryModalVisible,
  report,
}) => {
  const [text, setText] = useState(generateInitialEmailValue(report));

  return (
    <Dialog
      header={"Renewal Inquiry"}
      visible={renewalInquiryModalVisible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={() => setRenewalInquiryModalVisible(false)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => e.stopPropagation()}
        className={styles.emailModalDiv}
      >
        <Editor
          value={text}
          onTextChange={(e) => {
            setText(e.htmlValue ?? "");
          }}
          headerTemplate={
            <span className="ql-formats">
              <button className="ql-bold" aria-label="Bold"></button>
              <button className="ql-italic" aria-label="Italic"></button>
              <button className="ql-underline" aria-label="Underline"></button>
            </span>
          }
          style={{ height: "400px" }}
        />
        <div className={styles.buttonContainer}>
          <Button
            label="Cancel"
            severity="secondary"
            onClick={() => setRenewalInquiryModalVisible(false)}
          />
          <Button
            label="Send Email"
            onClick={() => setRenewalInquiryModalVisible(false)}
          />
        </div>
      </div>
    </Dialog>
  );
};
