import { useContext } from "react";
import { ThemeContext } from "../../../themes/Theme";
import styles from "./AppetiteIcon.module.css";
import React from "react";
import { CompanyContext } from "../../../CompanyConfig";
import * as client from "../../../client";

type AppetiteIconProps = {
  appetite: number;
  naicsInfo: client.NaicsInfo;
  onClick?: () => void;
  size?: "small" | "large" | "full_width";
  fontSize?: string;
  justifyContent?: string;
};

const colorForAttributes = (appetite: number, isLightMode: boolean) => {
  switch (appetite) {
    case 5:
      return isLightMode ? "var(--green-100)" : "#0e4f2644";
    case 4:
      return isLightMode ? "var(--cyan-100)" : "#18346244";
    case 3:
      return isLightMode ? "var(--yellow-100)" : "#5e480344";
    case 2:
      return isLightMode ? "var(--orange-100)" : "#642e0944";
    case 1:
      return isLightMode ? "var(--red-100)" : "#66181444";
  }
};

const borderColorForAttributes = (appetite: number, isLightMode: boolean) => {
  let value = "";
  switch (appetite) {
    case 5:
      value = isLightMode ? "var(--green-400)" : "var(--green-700)";
      break;
    case 4:
      value = isLightMode ? "var(--cyan-400)" : "var(--cyan-700)";
      break;
    case 3:
      value = isLightMode ? "var(--yellow-400)" : "var(--yellow-700)";
      break;
    case 2:
      value = isLightMode ? "var(--orange-400)" : "var(--orange-700)";
      break;
    case 1:
      value = isLightMode ? "var(--red-400)" : "var(--red-700)";
      break;
  }

  return value;
};

export const appetiteTitleForScore = (score: number) => {
  switch (score) {
    case 1:
      return "Out-of-Appetite";
    case 2:
      return "Restricted / Limit";
    case 3:
      return "Opportunistic / Strategic";
    case 4:
      return "Desirable / Build";
    case 5:
      return "Highly Desirable / Target";
  }
};

// From Greg's doc here: https://www.notion.so/Feedback-1bb4ed8de8d880a4828fcc206d7bda57
export const isWithinBlueskyAppetite = (naicsInfo: client.NaicsInfo) => {
  return (
    naicsInfo.code_type === "sic" &&
    (naicsInfo.code.startsWith("79") ||
      naicsInfo.code.startsWith("70") ||
      naicsInfo.code.startsWith("65") ||
      naicsInfo.code === "5813" ||
      naicsInfo.code === "5812")
  );
};

const AppetiteIconCanopius: React.FC<AppetiteIconProps> = ({
  appetite,
  onClick,
  size = "small",
  fontSize = "12px",
  justifyContent = "center",
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  return (
    <div
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? "button" : undefined}
      className={styles.iconContainer}
      style={{
        width: size === "small" ? "132px" : size === "large" ? "170px" : "100%",
        height: size === "small" ? "22px" : "32px",
        backgroundColor: colorForAttributes(appetite, isLightMode), // Base color
        borderColor: borderColorForAttributes(appetite, isLightMode),
        borderRadius: size === "small" ? "4px" : "6px",
        cursor: onClick ? "pointer" : "auto",
        fontSize: fontSize,
        justifyContent: justifyContent,
      }}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      onKeyDown={(event) => {
        if ((event.key === "Enter" || event.key === " ") && onClick) {
          event.stopPropagation();
          onClick();
        }
      }}
    >
      <span className={styles.text}>{appetiteTitleForScore(appetite)}</span>
    </div>
  );
};

const AppetiteIconBluesky: React.FC<AppetiteIconProps> = ({
  naicsInfo,
  size = "small",
  fontSize = "12px",
  justifyContent = "center",
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const isInAppetite = isWithinBlueskyAppetite(naicsInfo);

  return (
    <div
      className={styles.iconContainer}
      style={{
        width: size === "small" ? "132px" : size === "large" ? "170px" : "100%",
        height: size === "small" ? "22px" : "32px",
        backgroundColor: colorForAttributes(isInAppetite ? 5 : 1, isLightMode), // Base color
        borderColor: borderColorForAttributes(
          isInAppetite ? 5 : 1,
          isLightMode
        ),
        borderRadius: size === "small" ? "4px" : "6px",

        fontSize: fontSize,
        justifyContent: justifyContent,
      }}
    >
      <span className={styles.text}>
        {`${naicsInfo.code_type?.toUpperCase()} ${naicsInfo.code} ${
          isInAppetite ? "(Desirable)" : "(Undesirable)"
        }`}
      </span>
    </div>
  );
};

export const AppetiteIcon: React.FC<AppetiteIconProps> = ({
  appetite,
  onClick,
  naicsInfo,
  size = "small",
  fontSize = "12px",
  justifyContent = "center",
}) => {
  const company = React.useContext(CompanyContext);
  if (company === "canopius") {
    return (
      <AppetiteIconCanopius
        appetite={appetite}
        naicsInfo={naicsInfo}
        onClick={onClick}
        size={size}
        fontSize={fontSize}
        justifyContent={justifyContent}
      />
    );
  }

  return (
    <AppetiteIconBluesky
      appetite={appetite}
      naicsInfo={naicsInfo}
      onClick={onClick}
      size={size}
      fontSize={fontSize}
      justifyContent={justifyContent}
    />
  );
};
