import styles from "./DashboardRow.module.css";
import { Skeleton } from "primereact/skeleton";

export const DashboardLoadingRows = () => {
  return (
    <div className={styles.verticalContainer}>
      <SkeletonRow />
      <div className={styles.line} />
      <SkeletonRow />
      <div className={styles.line} />
      <SkeletonRow />
      <div className={styles.line} />
      <SkeletonRow />
      <div className={styles.line} />
      <SkeletonRow />
      <div className={styles.line} />
      <SkeletonRow />
      <div className={styles.line} />
      <SkeletonRow />
      <div className={styles.line} />
      <SkeletonRow />
    </div>
  );
};

const SkeletonRow = () => {
  return (
    <div className={styles.specialtyRow}>
      <div className={styles.rowContentSmall}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContentSmall}>
        <Skeleton height="32px" />
      </div>
    </div>
  );
};
