import * as client from "../../../client";
import {
  deductibleAndNetIncurredDoNotSumToGrossLoss,
  Loss,
  LossRunByYear,
  lossRunInfoToLossRunYears,
  mergedLossYearsForPolicyYear,
  totalsForMonetaryFieldsForLossYearsInPolicyYear,
} from "./util";

type BackendLossRunFlagIds = client.ParsedLoss["flags"][string][number];

// Backend flags, as well as some local ones
type LossRunFlagIds =
  | BackendLossRunFlagIds
  | "deductible_calculation_error"
  | "user_added_claim"
  | "user_added_year"
  | "user_edited"
  | "claims_have_issues_for_field"
  | "year_mismatch_ground_up_loss"
  | "year_mismatch_net_incurred";

export type LossFlag = {
  flagId: LossRunFlagIds;
  originalValue: string;
  // 0 - 100
  severity: number;
};

// TODO: add a flag when parsed year does not equal computed (and no overrides?)

const severityForBackendFlag = (backendFlagId: BackendLossRunFlagIds) => {
  switch (backendFlagId) {
    case "could_not_parse":
      return 75;
  }
};

// Currently this is used for losses and loss years
const flagText = (flag: LossRunFlagIds, originalValue: string): string => {
  switch (flag) {
    case "could_not_parse":
      return `Could not parse value: "${originalValue}"`;
    case "deductible_calculation_error":
      return "Ground Up Loss - Deductible does not equal Net Incurred";
    case "user_added_claim":
      return "A user added this claim";
    case "user_edited":
      return "A user edited this value";
    case "user_added_year":
      return "A user added this year";
    case "claims_have_issues_for_field":
      return `There ${
        originalValue === "1" ? "was 1 issue" : `were ${originalValue} issues`
      } with the claims for this year`;
    case "year_mismatch_ground_up_loss":
      return `There was a mismatch on Ground Up Loss. We found multiple year summaries with differing values: ${originalValue}`;
    case "year_mismatch_net_incurred":
      return `There was a mismatch on Net Incurred. We found multiple year summaries with differing values: ${originalValue}`;
  }
};

export const flagTextForFlags = (flags: LossFlag[]) => {
  if (flags.length === 0) {
    return undefined;
  }
  return flags
    .map((flag) => flagText(flag.flagId, flag.originalValue))
    .map((text) => `- ${text}`)
    .join("\n");
};

export const flagsForLoss = (loss: Loss, lossField: keyof Loss): LossFlag[] => {
  if (lossField === undefined) {
    return [];
  }

  if (loss.source === "user_generated") {
    return [{ flagId: "user_added_claim", originalValue: "", severity: 0 }];
  }

  if (loss.overrides[lossField]) {
    return [{ flagId: "user_edited", originalValue: "", severity: 0 }];
  }

  const originalValue = loss.original_values[lossField] ?? "NONE GIVEN";

  const flags: LossFlag[] = (loss.flags[lossField] ?? []).map((flag) => {
    return {
      flagId: flag,
      originalValue: originalValue,
      severity: severityForBackendFlag(flag),
    };
  });

  // This flag is calculated client side
  if (lossField === "net_incurred") {
    if (deductibleAndNetIncurredDoNotSumToGrossLoss(loss)) {
      flags.push({
        flagId: "deductible_calculation_error",
        originalValue: originalValue,
        severity: 75,
      });
    }
  }

  return flags;
};

const claimFlagsForComputedLossYear = (
  lossYear: client.ComputedLossRunByYear,
  lossField?: keyof Loss | keyof LossRunByYear
) => {
  if (lossYear.claims.length === 0) {
    return [];
  }

  const firstClaim = lossYear.claims[0];
  const claimKeys = Array.from(Object.keys(firstClaim)) as string[];
  if (lossField && !claimKeys.includes(lossField)) {
    return [];
  }

  const lossFields = lossField
    ? ([lossField] as (keyof Loss)[])
    : (claimKeys as (keyof Loss)[]);

  return lossYear.claims
    .flatMap((claim) =>
      lossFields.flatMap((field) => flagsForLoss(claim, field))
    )
    .filter((flag) => flag.severity > 0);
};

export const flagsForLossYear = (
  lossYear: LossRunByYear,
  lossRunInfo: client.LossRunInfo,
  lossField: keyof LossRunByYear
): LossFlag[] => {
  if (lossField === undefined) {
    return [];
  }

  if (lossYear.source === "inferred") {
    return [];
  }

  const flags: LossFlag[] = [];

  // Check for mismatches
  if (lossField === "policy_year") {
    const lossYearsForPolicyYear = mergedLossYearsForPolicyYear(
      lossRunInfo,
      lossYear.policy_year
    );
    const [groundUpLossTotals, netIncurredTotals] =
      totalsForMonetaryFieldsForLossYearsInPolicyYear(lossYearsForPolicyYear);

    if (groundUpLossTotals.size > 1) {
      flags.push({
        flagId: "year_mismatch_ground_up_loss",
        originalValue: Array.from(groundUpLossTotals).join(", "),
        severity: 75,
      });
    }

    if (netIncurredTotals.size > 1) {
      flags.push({
        flagId: "year_mismatch_net_incurred",
        originalValue: Array.from(netIncurredTotals).join(", "),
        severity: 75,
      });
    }
  }

  if (lossYear.source === "user_generated") {
    flags.push({ flagId: "user_added_year", originalValue: "", severity: 0 });
    return flags;
  }

  if (lossYear.source === "computed_from_claims") {
    const numFlags = claimFlagsForComputedLossYear(lossYear, lossField).length;
    if (numFlags === 0) {
      return flags;
    }

    flags.push({
      flagId: "claims_have_issues_for_field",
      originalValue: numFlags.toString(),
      severity: 75,
    });

    return flags;
  }

  // For parsed years
  if (lossYear.overrides[lossField]) {
    flags.push({ flagId: "user_edited", originalValue: "", severity: 75 });
    return flags;
  }

  const originalValue = lossYear.original_values[lossField] ?? "NONE GIVEN";

  flags.concat(
    (lossYear.flags[lossField] ?? []).map((flag) => {
      return {
        flagId: flag,
        originalValue: originalValue,
        severity: severityForBackendFlag(flag),
      };
    })
  );
  return flags;
};

export const flagsForAllLossYears = (
  lossRunInfo: client.LossRunInfo
): LossFlag[] => {
  const lossYears = lossRunInfoToLossRunYears(lossRunInfo);

  return lossYears.flatMap((lossYear) => {
    switch (lossYear.source) {
      case "inferred":
        return [];
      case "user_generated":
      case "parsed_year": {
        const yearFields = Array.from(
          Object.keys(lossYear)
        ) as (keyof LossRunByYear)[];
        return yearFields
          .flatMap((field) => flagsForLossYear(lossYear, lossRunInfo, field))
          .filter((flag) => flag.severity > 0);
      }
      case "computed_from_claims":
        // Include the policy year of the computed year object, as this will
        // detect mismatches on monetary values
        return claimFlagsForComputedLossYear(lossYear).concat(
          flagsForLossYear(lossYear, lossRunInfo, "policy_year")
        );
    }
  });
};
