export const BUSINESS_DESCRIPTIONS = [
  "Restaurant",
  "Office",
  "Clubs - Nightclubs",
  "Bar/Tavern",
  "Beauty Palour/Barber Shop",
  "Beverage Stores-Liquor and/or Beer",
  "Boarding House",
  "Bowling Alleys",
  "Builders Risks/Renovation Risk",
  "Carpentry/Woodworker",
  "Churches",
  "Clubs - Social / Scouts etc",
  "Convenience/Grocery Store with Gas (includes Gas Stations)",
  "Condominium/Homeowner/Townhouse Association without Mercantile",
  "Condominium/Homeowner/Townhouse Association with Mercantile",
  "Daycare",
  "Delicatessen",
  "Distributors-no electronics",
  "Laundry / Dry Cleaners",
  "Dwellings (Rental)",
  "Electronics Store/Distributor",
  "Farm Risks",
  "Grocery Stores-No gas sales",
  "Hotels/Motels without Restaurant",
  "Hotels/Motels with Restaurant",
  "Jewelery Stores/Distributors",
  "Mercantile-Multi-Occupancy (LRO)",
  "Mercantile-Single Occupancy (LRO)",
  "Manufacturing-Food Products",
  "Manufacturing-Heavy",
  "Manufacturing-Light",
  "Retail-no electronics",
  "Sawmills",
  "Vacant",
  "Warehouses/Storage",
  "Other",
];
