import { cloneDeep } from "lodash";
import * as client from "../../client";

export type SafeSOV = Omit<client.Sov, "addresses"> & {
  addresses: client.RawAddress[];
  unsafeAddresses: client.Address[];
};
export type SafeCommercialReport = Omit<
  client.CommercialReport,
  "sovs" | "company_info"
> & {
  sovs: SafeSOV[];
  company_info: client.RawCompanyInfo;
};

export type SafeReportMetadata = Omit<
  client.CommericalReportMetadata,
  "company_info"
> & {
  company_info: client.RawCompanyInfo;
};

export type SafeReportResponse = Omit<client.ReportResponse, "report_json"> & {
  report_json: SafeCommercialReport;
};

export type SafeReportMetadataResponse = Omit<
  client.ReportMetadataResponse,
  "report_metadata"
> & {
  report_metadata: SafeReportMetadata;
};

export const applyOverridesToAddressField = <K extends keyof client.RawAddress>(
  address: client.Address,
  k: K
): client.RawAddress[K] => {
  return (
    (address.private_overrides?.[k] as client.RawAddress[K]) ??
    address.private_computed_values[k]
  );
};

export const applyOverridesToAddress = (
  address: client.Address
): client.RawAddress => {
  // Probably common case: no overrides.
  if (Object.keys(address.private_overrides).length === 0) {
    return address.private_computed_values;
  }

  return Object.fromEntries(
    Object.entries(address.private_computed_values).map(([k, v]) => [
      k,
      address.private_overrides?.[k] ?? v,
    ])
  ) as client.RawAddress;
};

export const applyOverridesToSov = (sov: client.Sov): SafeSOV => {
  return {
    sov_id: sov.sov_id,
    is_enabled: sov.is_enabled,
    filename: sov.filename,
    all_document_headers: sov.all_document_headers,
    column_mappings: sov.column_mappings,
    column_overrides: sov.column_overrides,
    addresses: sov.addresses.map(applyOverridesToAddress),
    unsafeAddresses: sov.addresses,
  };
};

export const applyOverridesToCompanyInfo = (
  companyInfo: client.CompanyInfo
): client.RawCompanyInfo => {
  if (Object.keys(companyInfo.private_overrides).length === 0) {
    return companyInfo.private_computed_values;
  }

  return Object.fromEntries(
    Object.entries(companyInfo.private_computed_values).map(([k, v]) => [
      k,
      companyInfo.private_overrides?.[k] ?? v,
    ])
  ) as client.RawCompanyInfo;
};

export const applyOverridesToReport = (
  report: client.CommercialReport
): SafeCommercialReport => {
  return {
    report_id: report.report_id,
    appetite_score: report.appetite_score,
    naics_info: report.naics_info,
    env_risks: report.env_risks,
    loss_run: report.loss_run,
    company_info: applyOverridesToCompanyInfo(report.company_info),
    layer_info: report.layer_info,
    risk_details: report.risk_details,
    deductible_info: report.deductible_info,
    due_date: report.due_date,
    business_classification: report.business_classification,
    ofac_result: report.ofac_result,
    news_info: report.news_info,
    policy_questions: report.policy_questions,
    additional_files_used: report.additional_files_used,
    loss_control_reports: report.loss_control_reports,

    sovs: report.sovs.map(applyOverridesToSov),
  };
};

export const applyOverridesToMetadata = (
  reportMetadata: client.CommericalReportMetadata
): SafeReportMetadata => {
  return {
    ...reportMetadata,
    company_info: applyOverridesToCompanyInfo(reportMetadata.company_info),
  };
};

export const applyOverridesToMetadataReponse = (
  reportMetadata: client.ReportMetadataResponse
): SafeReportMetadataResponse => {
  return {
    ...reportMetadata,
    report_metadata: applyOverridesToMetadata(reportMetadata.report_metadata),
  };
};

export const applyOverridesToResponse = (
  response: client.ReportResponse
): SafeReportResponse => {
  return {
    report_id: response.report_id,
    email_subject: response.email_subject,
    created_at_ts: response.created_at_ts,
    modified_at_ts: response.modified_at_ts,
    human_review_info: response.human_review_info,
    assignee: response.assignee,
    status: response.status,
    report_json: applyOverridesToReport(response.report_json),
  };
};

export const applyDeltasAsOverride = (
  old: client.Address,
  edited: client.RawAddress
): client.Address => {
  const currentEffective = applyOverridesToAddress(old);
  const copy = cloneDeep(old);
  for (const _key of Object.keys(edited)) {
    const key = _key as keyof client.RawAddress;
    if (edited[key] !== currentEffective[key]) {
      copy.private_overrides[key] = edited[key] ?? null;

      // Drop overrides that have no effect
      if (copy.private_overrides[key] === copy.private_computed_values[key]) {
        delete copy.private_overrides[key];
      }
    }
  }
  return copy;
};
