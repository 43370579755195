import { InputText } from "primereact/inputtext";
import styles from "./Dashboard.module.css";
import { useContext, useMemo, useRef, useState } from "react";
import { ReportStatus } from "../../../api/types";
import { SpecialtyReport } from "../SpecialtyReport";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { DashboardLoadingRows } from "./DashboardRow";
import {
  appetiteScoreForReportInfo,
  borderColorForAttributes,
  brokerageFromBroker,
  readableStatus,
} from "./util";
import { useReports, useUserById, useUsers } from "../data";
import * as client from "../../../client";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { AppetiteModal } from "../appetite/AppetiteModal";
import { DashboardAnalytics } from "./Analytics";
import { PantheonAvatar } from "../../../components/avatar/Avatar";
import { UseQueryResult } from "@tanstack/react-query";
import { displayNameForUser } from "../../../util";
import { SearchAddresses } from "./SearchAddresses";
import { Menu } from "primereact/menu";
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AppetiteIcon } from "../appetite/AppetiteIcon";
import { StatusIcon } from "./StatusIcon";
import { formatAbbreviatedMoney } from "../Money";
import { ThemeContext } from "../../../themes/Theme";
import { ReportActionMenu } from "./ReportActionMenu";
import { SortOrder } from "primereact/api";
import {
  applyOverridesToMetadataReponse,
  SafeReportMetadataResponse,
} from "../Conversion";
import { useLogUIEventOnMount } from "../../../amplitude";

type StatusOption = {
  name: string;
  code: ReportStatus;
};

type UserOption = { name: string; id: string };

export const Dashboard = ({ clientId }: { clientId: string }) => {
  const apiReports = useReports();
  const specialtyReports: SafeReportMetadataResponse[] = useMemo(
    () =>
      (apiReports.isSuccess ? apiReports.data : []).map((report) =>
        applyOverridesToMetadataReponse(report)
      ),
    [apiReports.isSuccess, apiReports.data]
  );

  return (
    <Routes>
      <Route
        path="report/:reportId/:tabPath?/:tabInfo?"
        element={<SpecialtyReport clientId={clientId} />}
      />
      <Route
        path="analytics"
        element={<DashboardAnalytics specialtyReports={specialtyReports} />}
      />
      <Route
        path="search-addresses"
        element={<SearchAddresses specialtyReports={specialtyReports} />}
      />
      <Route
        path="reports/casualty"
        element={
          <Reports
            isCasualty={true}
            apiReports={apiReports}
            specialtyReports={specialtyReports}
          />
        }
      />
      <Route
        path="reports/property"
        element={
          <Reports
            isCasualty={false}
            apiReports={apiReports}
            specialtyReports={specialtyReports}
          />
        }
      />
      <Route path="*" element={<Navigate to={ROUTES.DASHBOARD_PROPERTY} />} />
    </Routes>
  );
};

type ReportsProps = {
  apiReports: UseQueryResult<client.ReportMetadataResponse[], Error>;
  specialtyReports: SafeReportMetadataResponse[];
  isCasualty: boolean;
};

const Reports: React.FC<ReportsProps> = ({
  apiReports,
  specialtyReports,
  isCasualty,
}) => {
  useLogUIEventOnMount("acropolis_show_dashboard");

  const [searchText, setSearchText] = useState("");
  const [selectedAssigneeUsers, setSelectedAssigneeUsers] = useState<
    UserOption[]
  >([]);
  const [selectedStatuses, setSelectedStatuses] = useState<StatusOption[]>([]);
  const statuses = Object.values(ReportStatus).map((status) => {
    return {
      name: readableStatus(status),
      code: status,
    };
  });

  const [
    selectedReportIdForBusinessClassification,
    setSelectedReportIdForBusinessClassification,
  ] = useState<string | undefined>(undefined);
  const selectedReportForBusinessClassification = specialtyReports.find(
    (report) => report.report_id === selectedReportIdForBusinessClassification
  );

  const navigate = useNavigate();

  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  const filteredAndSortedReports = useMemo(() => {
    const filteredReports = specialtyReports.filter((report) => {
      if (selectedStatuses.length > 0) {
        if (!selectedStatuses.some((status) => status.code === report.status)) {
          return false;
        }
      }

      if (selectedAssigneeUsers.length > 0) {
        if (
          !selectedAssigneeUsers.some(
            (user) =>
              (user.id === "Unassigned" && !report.assignee) ||
              user.id === report.assignee
          )
        ) {
          return false;
        }
      }

      if (searchText !== "") {
        const searchTextLower = searchText.toLowerCase();
        if (
          // TODO: what else should we search on?
          !report.report_metadata.company_info.company_name
            .toLowerCase()
            .includes(searchTextLower) &&
          !brokerageFromBroker(report.report_metadata.company_info.broker_name)
            .toLowerCase()
            .includes(searchTextLower)
        ) {
          return false;
        }
      }

      return true;
    });

    return filteredReports;
  }, [
    selectedStatuses,
    specialtyReports,
    searchText,
    selectedAssigneeUsers,
    isCasualty,
  ]);

  const menuRef = useRef<Menu>(null);
  const columnPtOptions = { bodyCell: { style: { padding: "6px 8px" } } };

  return (
    <>
      <div className={styles.searchContainer}>
        <InputText
          className={styles.searchInput}
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className={styles.rowsContainer}>
          <DataTable
            scrollable
            value={filteredAndSortedReports}
            scrollHeight="calc(100vh - 234px)"
            rowClassName={() => styles.row}
            paginator
            rows={30}
            sortField="created_at_ts"
            sortOrder={SortOrder.DESC}
            pt={{
              headerRow: {
                style: { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)" },
              },
            }}
            emptyMessage={
              apiReports.isPending ? (
                <DashboardLoadingRows />
              ) : (
                "No results found"
              )
            }
            onRowClick={(event) => {
              const report = event.data as client.ReportMetadataResponse;
              navigate(ROUTES.DASHBOARD_REPORT(report.report_id, "summary"));
            }}
          >
            <Column
              style={{ width: "3%", padding: "16px 8px" }}
              header=""
              pt={columnPtOptions}
            />
            <Column
              style={{ width: "6%", padding: "16px 8px" }}
              header={() => (
                <AssigneeFilterDropdown
                  selectedUsers={selectedAssigneeUsers}
                  setSelectedUsers={setSelectedAssigneeUsers}
                />
              )}
              body={(report: SafeReportMetadataResponse) => {
                const assignee = useUserById(report.assignee);
                return <PantheonAvatar label={displayNameForUser(assignee)} />;
              }}
              pt={columnPtOptions}
            />
            <Column
              header="Company Name"
              style={{ width: "20%", padding: "16px 8px" }}
              field="report_metadata.company_info.company_name"
              sortable
              body={(report: SafeReportMetadataResponse) => {
                return (
                  <span>
                    {report.report_metadata.company_info.company_name}
                  </span>
                );
              }}
              pt={columnPtOptions}
            />
            <Column
              header="Appetite"
              style={{ width: "15%", padding: "16px 8px" }}
              body={(report: SafeReportMetadataResponse) => {
                return (
                  <AppetiteIcon
                    naicsInfo={report.report_metadata.naics_info}
                    justifyContent="flex-start"
                    fontSize="10px"
                    appetite={appetiteScoreForReportInfo(
                      report.report_metadata.business_classification,
                      report.report_metadata.appetite_score
                    )}
                    onClick={() =>
                      setSelectedReportIdForBusinessClassification(
                        report.report_id
                      )
                    }
                  />
                );
              }}
              pt={columnPtOptions}
            />
            <Column
              header="Brokerage"
              style={{ width: "12%", padding: "16px 8px" }}
              field="report_metadata.company_info.broker_name"
              sortable
              body={(report: SafeReportMetadataResponse) => {
                return (
                  <span>
                    {brokerageFromBroker(
                      report.report_metadata.company_info.broker_name
                    )}
                  </span>
                );
              }}
              pt={columnPtOptions}
            />
            <Column
              header="Effective Date"
              style={{ width: "12%", padding: "16px 8px" }}
              field="report_metadata.company_info.effective_date"
              sortable
              body={(report: SafeReportMetadataResponse) => {
                return (
                  <span>
                    {report.report_metadata.company_info.effective_date}
                  </span>
                );
              }}
              pt={columnPtOptions}
            />
            <Column
              style={{ width: "12%", padding: "16px 8px" }}
              field="status"
              sortable
              body={(report: SafeReportMetadataResponse) => {
                return <StatusIcon status={report.status} />;
              }}
              pt={columnPtOptions}
              header={() => (
                <div className={styles.horizontalContainer}>
                  <span>Status</span>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <StatusFilterDropdown
                      statuses={statuses}
                      selectedStatuses={selectedStatuses}
                      setSelectedStatuses={setSelectedStatuses}
                    />
                  </div>
                </div>
              )}
            />
            <Column
              header="TIV"
              style={{ width: "10%", padding: "16px 8px" }}
              field="report_metadata.sov_metadata.total_tiv"
              sortable
              body={(report: SafeReportMetadataResponse) => {
                const totalTiv = report.report_metadata.sov_metadata.total_tiv;
                const difficulty =
                  totalTiv < 10_000_000
                    ? "good"
                    : totalTiv < 25_000_000
                    ? "medium"
                    : "bad";
                return (
                  <div style={{ display: "flex" }}>
                    <div className={styles.verticalContainer}>
                      <span>{formatAbbreviatedMoney(totalTiv)}</span>

                      <div
                        style={{
                          marginLeft: "-2px",
                          marginRight: "-2px",
                          height: "2px",
                          backgroundColor: borderColorForAttributes(
                            difficulty,
                            isLightMode
                          ),
                        }}
                      />
                    </div>
                  </div>
                );
              }}
              pt={columnPtOptions}
            />
            <Column
              style={{ width: "6%", padding: "16px 8px" }}
              header={<i className="pi pi-calendar" />}
              field="created_at_ts"
              body={(report: SafeReportMetadataResponse) => {
                const formatted = new Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                }).format(new Date(report.created_at_ts));
                return <span>{formatted}</span>;
              }}
              pt={columnPtOptions}
              sortable
            />
            <Column
              header={
                <>
                  <Menu
                    model={[
                      /*
              {
                label: "Analytics",
                icon: "pi pi-chart-line",
                command: () => navigate(ROUTES.DASHBOARD_ANALYTICS),
              },*/
                      {
                        label: "Address Search",
                        icon: "pi pi-bullseye",
                        command: () =>
                          navigate(ROUTES.DASHBOARD_ADDRESS_SEARCH),
                      },
                    ]}
                    popup
                    ref={menuRef}
                    id="popup_menu"
                    popupAlignment="left"
                  />
                  <Button
                    icon="pi pi-ellipsis-v"
                    onClick={(event) => menuRef.current?.toggle(event)}
                    aria-controls="popup_menu"
                    aria-haspopup
                    text
                    severity="secondary"
                    style={{ color: "var(--text-color)" }}
                  />
                </>
              }
              style={{ width: "8%", padding: "16px 8px" }}
              body={(report: SafeReportMetadataResponse) => {
                return <ActionMenu report={report} />;
              }}
              pt={columnPtOptions}
            />
            <Column
              style={{ width: "3%", padding: "16px 8px" }}
              header=""
              pt={columnPtOptions}
            />
          </DataTable>
        </div>
      </div>
      {selectedReportForBusinessClassification && (
        <AppetiteModal
          report={selectedReportForBusinessClassification.report_metadata}
          visible={selectedReportForBusinessClassification !== undefined}
          onHide={() => setSelectedReportIdForBusinessClassification(undefined)}
        />
      )}
    </>
  );
};

const ActionMenu = ({ report }: { report: SafeReportMetadataResponse }) => {
  return <ReportActionMenu report={report} isUpcomingRenewal={false} />;
};

type StatusFilterDropdownProps = {
  statuses: StatusOption[];
  selectedStatuses: StatusOption[];
  setSelectedStatuses: (statuses: StatusOption[]) => void;
};

const StatusFilterDropdown: React.FC<StatusFilterDropdownProps> = ({
  statuses,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  return (
    <MultiSelect
      value={selectedStatuses}
      onChange={(e) => setSelectedStatuses(e.value)}
      options={statuses}
      optionLabel="name"
      placeholder={""}
      maxSelectedLabels={3}
      className={styles.multiSelect}
      dropdownIcon="pi pi-filter"
    />
  );
};

type AssigneeFilterDropdownProps = {
  selectedUsers: UserOption[];
  setSelectedUsers: (ids: UserOption[]) => void;
};

const avatarTemplate = ({ name }: { name: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <PantheonAvatar label={name === "Unassigned" ? undefined : name} />
      <span>{name === "Unassigned" ? "Unassigned" : name}</span>
    </div>
  );
};

const AssigneeFilterDropdown: React.FC<AssigneeFilterDropdownProps> = ({
  selectedUsers,
  setSelectedUsers,
}) => {
  const users = useUsers();
  const options = [
    ...(users?.data?.all_company_users ?? []).map((u) => ({
      name: displayNameForUser(u),
      id: u.id,
    })),
    { name: "Unassigned", id: "Unassigned" },
  ];

  return (
    <MultiSelect
      value={selectedUsers}
      onChange={(e) => setSelectedUsers(e.value)}
      options={options}
      itemTemplate={avatarTemplate}
      optionLabel="name"
      placeholder={""}
      className={styles.multiSelect}
      dropdownIcon="pi pi-user"
    />
  );
};
