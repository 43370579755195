import { RawAddress } from "../../../client";

type TierDataType = {
  tier_1_cities: string[];
  tier_2_cities: string[];
  tier_1_counties: string[];
  tier_2_counties: string[];
  all_non_tier1_are_tier2?: boolean;
  all_counties_tier1?: boolean;
};

export const calculateTivByWindzoneTiers = (addresses: RawAddress[]) => {
  const tivByTiers = {
    no_tier: 0,
    tier_1: 0,
    tier_2: 0,
  };
  for (const address of addresses) {
    const windzoneTier = categorizeLocation(
      address.state,
      address.county,
      address.city
    );
    tivByTiers[windzoneTier] = tivByTiers[windzoneTier] + address.tiv;
  }

  return tivByTiers;
};

function categorizeLocation(
  stateCode: string | null | undefined,
  county: string | null | undefined,
  city: string | undefined | null
) {
  // Normalize inputs to handle case sensitivity and trailing spaces
  const normalizedState = stateCode?.toLowerCase().trim() || "";
  const normalizedCounty = county?.toLowerCase().trim() || "";
  const normalizedCity = city?.toLowerCase().trim() || "";

  if (!normalizedState || !normalizedCounty || !normalizedCity) {
    return "no_tier";
  }

  // Define the state-county-city tier mapping based on the tables
  const tierData: {
    [key: string]: TierDataType;
  } = {
    // Image 1 states
    ma: {
      tier_1_counties: ["barnstable", "dukes", "nantucket"],
      tier_2_counties: [
        "bristol",
        "essex",
        "plymouth",
        "norfolk",
        "suffolk",
        "cecil",
      ],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    ms: {
      tier_1_counties: ["hancock", "harrison", "jackson"],
      tier_2_counties: ["george", "pearl river", "stone"],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    nj: {
      tier_1_counties: ["atlantic", "cape may", "monmouth", "ocean"],
      tier_2_counties: [], // All non-Tier 1 counties
      all_non_tier1_are_tier2: true,
      tier_1_cities: [],
      tier_2_cities: [],
    },
    ny: {
      tier_1_counties: ["kings", "nassau", "queens", "richmond", "suffolk"],
      tier_2_counties: [], // All non-Tier 1 counties
      all_non_tier1_are_tier2: true,
      tier_1_cities: [],
      tier_2_cities: [],
    },
    nc: {
      tier_1_counties: [
        "bertie",
        "brunswick",
        "camden",
        "carteret",
        "chowan",
        "currituck",
        "dare",
        "hyde",
        "new hanover",
        "onslow",
        "pamlico",
        "pasquotank",
        "pender",
        "perquimans",
        "tyrrell",
        "washington",
      ],
      tier_2_counties: [
        "beaufort",
        "bladen",
        "columbus",
        "craven",
        "duplin",
        "gates",
        "hertford",
        "jones",
        "martin",
        "sampson",
      ],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    ri: {
      tier_1_counties: [], // All counties
      tier_2_counties: [],
      all_counties_tier1: true,
      tier_1_cities: [],
      tier_2_cities: [],
    },
    sc: {
      tier_1_counties: [
        "beaufort",
        "charleston",
        "colleton",
        "georgetown",
        "horry",
        "jasper",
      ],
      tier_2_counties: [
        "bamberg",
        "berkeley",
        "dillon",
        "dorchester",
        "hampton",
        "marion",
        "williamsburg",
      ],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    tx: {
      tier_1_counties: [
        "aransas",
        "brazoria",
        "calhoun",
        "cameron",
        "chambers",
        "galveston",
        "jefferson",
        "kennedy",
        "kleberg",
        "matagorda",
        "nueces",
        "refugio",
        "san patricio",
        "willacy",
      ],
      tier_2_counties: [
        "bee",
        "brooks",
        "fort bend",
        "goliad",
        "hardin",
        "hidalgo",
        "jackson",
        "jim wells",
        "liberty",
        "live oak",
        "orange",
        "victoria",
        "wharton",
        // "Wind excluded in Harris County – refer to CUSI for consideration"
        // Not sure what that means, so just including as tier 2 for now
        "harris",
      ],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    va: {
      tier_1_counties: ["accomack", "northampton"],
      tier_2_counties: [
        "york",
        "gloucester",
        "mathews",
        "middlesex",
        "lancaster",
        "northumberland",
      ],
      tier_1_cities: ["virginia beach", "norfolk", "portsmouth"],
      tier_2_cities: ["hampton", "poquoson", "chesapeake"],
    },

    // Image 2 states
    al: {
      tier_1_counties: ["baldwin", "mobile"],
      tier_2_counties: [
        "clarke",
        "covington",
        "escambia",
        "geneva",
        "monroe",
        "washington",
      ],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    de: {
      tier_1_counties: ["kent", "new castle", "sussex"],
      tier_2_counties: [], // Special case: "Risks within 1 mile of the coast must be referred"
      tier_1_cities: [],
      tier_2_cities: [],
    },
    fl: {
      tier_1_counties: [], // Wind/Hail is excluded
      tier_2_counties: [], // Wind/Hail is excluded
      tier_1_cities: [],
      tier_2_cities: [],
    },
    ga: {
      tier_1_counties: [
        "chatham",
        "bryan",
        "liberty",
        "mcintosh",
        "glynn",
        "camden",
      ],
      tier_2_counties: [
        "brantley",
        "bulloch",
        "charlton",
        "effingham",
        "evans",
        "long",
        "tattnall",
        "wayne",
      ],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    hi: {
      tier_1_counties: ["hawaii", "honolulu", "kalawao", "kauai", "maui"],
      tier_2_counties: [],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    la: {
      tier_1_counties: [
        "cameron",
        "vermillion",
        "iberia",
        "st. mary",
        // Technically south is tier 1 and north is tier 2, but we don't have that level of info
        "st. martin",
        "assumption",
        "terrebonne",
        "lafourche",
        "plaquemines",
        "st. bernard",
        "jefferson",
        "orleans",
        "st. charles",
        "st. tammany",
      ],
      tier_2_counties: [
        "calcasieu",
        "jefferson davis",
        "acadia",
        "lafayette",
        "livingston",
        "iberville",
        "ascension",
        "st. james",
        "st. john the baptist",
        "tangipahoa",
      ],
      tier_1_cities: [],
      tier_2_cities: [],
    },
    md: {
      tier_1_counties: ["wicomico", "worcester", "somerset"],
      tier_2_counties: [
        "saint marys",
        "calvert",
        "anne arundel",
        "harford",
        "baltimore",
        "talbot",
        "queen annes",
        "caroline",
        "kent",
        "dorchester",
      ],
      tier_1_cities: [],
      tier_2_cities: ["baltimore"],
    },
  };

  // State not found in our data
  if (!tierData[normalizedState]) {
    return "no_tier";
  }

  const stateData = tierData[normalizedState];

  // Special case handling for Rhode Island "All counties"
  if (stateData.all_counties_tier1 && normalizedCounty) {
    return "tier_1";
  }

  // Check if city is in Tier 1
  if (normalizedCity && stateData.tier_1_cities.includes(normalizedCity)) {
    return "tier_1";
  }

  // Check if city is in Tier 2
  if (normalizedCity && stateData.tier_2_cities.includes(normalizedCity)) {
    return "tier_2";
  }

  // Check if county is in Tier 1
  if (
    normalizedCounty &&
    stateData.tier_1_counties.includes(normalizedCounty)
  ) {
    return "tier_1";
  }

  // Check if county is in Tier 2
  if (
    normalizedCounty &&
    stateData.tier_2_counties.includes(normalizedCounty)
  ) {
    return "tier_2";
  }

  // Special case handling for NJ and NY "All non-Tier 1 counties"
  if (
    stateData.all_non_tier1_are_tier2 &&
    normalizedCounty &&
    !stateData.tier_1_counties.includes(normalizedCounty)
  ) {
    return "tier_2";
  }

  // Not found in any tier
  return "no_tier";
}
