import * as client from "../../../client";
import {
  originalBuildingDescriptionFromRawAddress,
  originalConstructionDescriptionFromRawAddress,
} from "./EditSovModal";

type FlagIds = client.Address["private_flags"][string][number];

export const severityForFlag = (flagId: FlagIds): number => {
  if (typeof flagId === "string") {
    switch (flagId) {
      case "date_parsing_error":
        return 65;
      case "missing_coverage_headers":
        return 90;
      case "number_parsing_error":
        return 70;
      case "suspicious_year_warning":
        return 65;
      case "general_mismatch":
        return 70;
      case "large_number_warning":
        return 90;
      case "low_confidence":
        return 81;
      case "medium_confidence":
        return 65;
      case "year_out_of_range_warning":
        return 64;
      case "cooking_class_guess":
        return 65;
      case "non_property_insurance_value":
        return 65;
      case "unrecognized_central_alarm_value":
        return 65;
      case "unrecognized_sprinkler_value":
        return 65;
      case "low_sprinkler_percentage":
        return 60;
      case "zip_parsing_error":
        return 85;
    }
  }

  switch (flagId.flag_id) {
    case "unkonwn_coverage_column":
      return 90;
    case "non_property_insurance_value_v2":
      return 65;
  }
};

export const maxSeverityForAddressFlags = (
  address: client.Address,
  addressField: keyof client.RawAddress | undefined
): number => {
  if (addressField === undefined) {
    return 0;
  }
  let maxSeverity = undefined;
  for (const flagId of address.private_flags[addressField] ?? []) {
    const newSeverity = severityForFlag(flagId);

    if (newSeverity > (maxSeverity ?? 0)) {
      maxSeverity = newSeverity;
    }
  }
  return maxSeverity ?? 0;
};

const flagText = (
  flag: FlagIds,
  originalValue: string | null,
  computedValue: string | null | number | undefined
): string => {
  const safeOriginalValue = originalValue ?? "NONE GIVEN";
  const confidenceString =
    (originalValue ?? "") === "" || originalValue === "NONE GIVEN"
      ? computedValue === null ||
        computedValue === undefined ||
        computedValue === ""
        ? "No value was found so we left this blank."
        : "No value was found so we inferred a value from company context."
      : `The original value was "${safeOriginalValue}"`;

  if (typeof flag === "string") {
    switch (flag) {
      case "date_parsing_error":
        return `Failed to parse date: "${safeOriginalValue}"`;
      case "missing_coverage_headers":
        return "No coverage headers found that sum to the TIV. Defaulting all TIV to Building Value.";
      case "number_parsing_error":
        return `Failed to parse number value: "${safeOriginalValue}"`;
      case "suspicious_year_warning":
        return `Warning when extracting a 4 digit year from: "${safeOriginalValue}"`;
      case "general_mismatch":
        return `The extracted value looks different than the original: "${safeOriginalValue}"`;
      case "large_number_warning":
        return "This number looks too large to be correct.";
      case "low_confidence":
        return `We have low confidence in this value. ${confidenceString}`;
      case "medium_confidence":
        return `We have medium confidence in this value. ${confidenceString}`;
      case "year_out_of_range_warning":
        return `This year was adjusted up to 1905 to avoid issues in excel (originally, this value was "${safeOriginalValue}")`;
      case "cooking_class_guess":
        return "The Business Information on this location does not have a perfect Cooking Class match. We inferred Cooking Class from the Business Information.";
      case "non_property_insurance_value":
        return "We found a non-property insurance subject for this location in the Acord app.";
      case "unrecognized_central_alarm_value":
        return `Unrecognized alarm type. The original value was "${safeOriginalValue}"`;
      case "unrecognized_sprinkler_value":
        return `Unrecognized sprinkler type. The original value was "${safeOriginalValue}"`;
      case "low_sprinkler_percentage":
        return `We found a low sprinkler percentage. The original value was "${safeOriginalValue}"`;
      case "zip_parsing_error":
        return `Failed to parse zip code: "${safeOriginalValue}"`;
    }
  }

  switch (flag.flag_id) {
    case "unkonwn_coverage_column":
      return `These column headers don't appear to be related to property insurance, but they were included in the spreadsheet's TIV calculation: ${flag.columns
        .map((column) => `"${column}"`)
        .join(", ")}`;
    case "non_property_insurance_value_v2":
      return `These insurance subjects don't appear to be related to property insurance, so we did not include them in the TIV calculation: ${flag.subjects
        .map((subject) => `"${subject}"`)
        .join(", ")}`;
  }
};

export const flagTextFor = (
  address: client.Address,
  addressField: keyof client.RawAddress | undefined
) => {
  if (addressField === undefined) {
    return undefined;
  }

  if (address.private_overrides[addressField]) {
    return "A user edited this value";
  }

  let originalValue = address.private_original_values[addressField];

  switch (addressField) {
    case "construction_code":
      originalValue = originalConstructionDescriptionFromRawAddress(address);
      break;
    case "occupancy_code":
      originalValue = originalBuildingDescriptionFromRawAddress(address);
      break;
    case "building_description":
      originalValue = originalBuildingDescriptionFromRawAddress(address);
      break;
    default:
      break;
  }

  return (address.private_flags[addressField] ?? [])
    .map((flag) =>
      flagText(
        flag,
        originalValue,
        address.private_computed_values[addressField]
      )
    )
    .map((text) => `- ${text}`)
    .join("\n");
};
