import { ReportStatus } from "../../../api/types";
import * as client from "../../../client";

export const TEMP_PIPELINE_EXAMPLES = new Set([
  "BentallGreenOak (U.S.) Limited Partnership",
  "Marathon Patent Group Inc",
]);

export const readableStatus = (status: ReportStatus): string => {
  switch (status) {
    case ReportStatus.BOUND:
      return "Bound";
    case ReportStatus.DECLINED:
      return "Declined";
    case ReportStatus.IN_FLIGHT:
      return "In flight";
    case ReportStatus.UNINTERESTED:
      return "Quoted";
  }
};

export const brokerageFromBroker = (broker: string): string => {
  switch (broker.toLowerCase()) {
    case "kristin oloriz":
      return "amwins";
    case "diana ticu":
      return "marsh";
    case "kara wilson":
      return "jencap";
    case "mark molzer":
      return "marsh";
    case "samantha meiklejohn":
      return "marsh";
    case "kaitlyn mcquillan":
      return "aon";
    case "daniel oconnor":
      return "wtwco";
    case "sara hardy":
      return "marsh";
    case "pip clarke":
      return "crcgroup";
    case "kyle merle":
      return "aon";
    case "bryan drazner":
      return "rtspecialty";
    case "maggie carlin":
      return "marsh";
    case "erin kriete":
      return "bbrown";
    case "tammi yee":
      return "amwins";
    case "adrienne kolacia":
      return "rtspecialty";
    default:
      return broker;
  }
};

// Assuming 5 industries
export const appetiteScoreForReportInfo = (
  businessClassification: client.BusinessClassification | null | undefined,
  appetiteScore: number
) => {
  if (businessClassification !== null && businessClassification !== undefined) {
    const score = 5 - businessClassification.minor_industry_index;
    return score;
  }
  return appetiteScore;
};

export type Severity = "good" | "bad" | "medium" | "other" | "neutral";

export const colorForAttributes = (
  severity: Severity,
  isLightMode: boolean
) => {
  switch (severity) {
    case "good":
      return isLightMode ? "var(--green-100)" : "#0e4f2644";
    case "bad":
      return isLightMode ? "var(--red-100)" : "#66181444";
    case "medium":
      return isLightMode ? "var(--yellow-100)" : "#5e480344";
    case "other":
      return isLightMode ? "var(--cyan-100)" : "#18346244";
    default:
      return "var(--surface-100)";
  }
};

export const borderColorForAttributes = (
  severity: Severity,
  isLightMode: boolean
) => {
  switch (severity) {
    case "good":
      return isLightMode ? "var(--green-400)" : "var(--green-700)";
    case "bad":
      return isLightMode ? "var(--red-400)" : "var(--red-700)";
    case "medium":
      return isLightMode ? "var(--yellow-400)" : "var(--yellow-700)";
    case "other":
      return isLightMode ? "var(--cyan-400)" : "var(--cyan-700)";
    default:
      return "var(--surface-300)";
  }
};

export const colorForConfidence = (
  confidence: string,
  isLightMode: boolean
) => {
  switch (confidence) {
    case "low":
      return isLightMode
        ? HIGHLIGHT_ERROR_LIGHT_MODE
        : HIGHLIGHT_ERROR_DARK_MODE;
    case "medium":
      return isLightMode
        ? HIGHLIGHT_WARNING_LIGHT_MODE
        : HIGHLIGHT_WARNING_DARK_MODE;
    default:
      return undefined;
  }
};

export const HIGHLIGHT_ERROR_DARK_MODE = "var(--red-900)";
export const HIGHLIGHT_WARNING_DARK_MODE = "var(--yellow-900)";
export const HIGHLIGHT_USER_OVERRIDE_DARK_MODE = "var(--blue-800)";
export const HIGHLIGHT_ERROR_LIGHT_MODE = "var(--red-100)";
export const HIGHLIGHT_WARNING_LIGHT_MODE = "var(--yellow-200)";
export const HIGHLIGHT_USER_OVERRIDE_LIGHT_MODE = "var(--blue-100)";

export const LOW_CONFIDENCE_MINIMUM_SEVERITY = 80;

export const flaggedHighlightColor = (
  maxSeverity: number,
  isLightMode: boolean,
  hasOverride: boolean
) => {
  if (hasOverride) {
    if (isLightMode) {
      return HIGHLIGHT_USER_OVERRIDE_LIGHT_MODE;
    }
    return HIGHLIGHT_USER_OVERRIDE_DARK_MODE;
  }
  if (maxSeverity > LOW_CONFIDENCE_MINIMUM_SEVERITY) {
    if (isLightMode) {
      return HIGHLIGHT_ERROR_LIGHT_MODE;
    }
    return HIGHLIGHT_ERROR_DARK_MODE;
  }
  if (isLightMode) {
    return HIGHLIGHT_WARNING_LIGHT_MODE;
  }
  return HIGHLIGHT_WARNING_DARK_MODE;
};
