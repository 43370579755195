import * as client from "../../../client";

export type LossRunByYear =
  | client.ParsedLossRunByYear
  | (client.ComputedLossRunByYear & {
      net_incurred: number;
      ground_up_loss: number;
      open_claims: number;
      closed_claims: number;
    })
  | client.UserGeneratedLossRunByYear
  | {
      policy_year: string;
      open_claims: 0;
      closed_claims: 0;
      description_of_losses: string;
      ground_up_loss: 0;
      net_incurred: 0;
      source: "inferred";
    };

export type Loss = client.ParsedLoss | client.UserGeneratedLoss;

export const deductibleAndNetIncurredDoNotSumToGrossLoss = (loss: Loss) => {
  // Only do the calculation if we have all values
  if (loss.deductible && loss.net_incurred && loss.ground_up_loss) {
    if (loss.ground_up_loss <= loss.deductible && loss.net_incurred === 0) {
      return false;
    }

    return (
      Math.round(loss.deductible + loss.net_incurred) !==
      Math.round(loss.ground_up_loss)
    );
  }

  return false;
};

const potentialNullToNone = (input: string | null) => {
  if (input) {
    return input;
  }
  return "None";
};

export const signatureForSourceDetails = (details: client.SourceDetails) => {
  return `${details.filename} - ${potentialNullToNone(details.sheet_name)} - ${
    details.data_type
  } - ${potentialNullToNone(details.calc_mode)} - ${potentialNullToNone(
    details.carrier
  )}`;
};

// NOTE(john) the way this function currently works, we can't trust some fields after the
// merge. For instance, differing claim types in the same year would be lost, we
// just take the first one. We can convert fields like this into an array if needed
export const mergedLossYearsForPolicyYear = (
  lossRunInfo: client.LossRunInfo,
  policyYear: string
) => {
  // Filter out disabled sources
  const lossYears = lossRunInfo.loss_runs_by_year[policyYear].filter((year) => {
    const signature = signatureForSourceDetails(year.source_details);
    return (
      signature in lossRunInfo.enabled_by_source &&
      lossRunInfo.enabled_by_source[signature].enabled
    );
  });

  const mergedYears: LossRunByYear[] = [];

  // First, merge computed years
  const computedYears = lossYears.filter(
    (year) => year.source === "computed_from_claims"
  ) as client.ComputedLossRunByYear[];
  if (computedYears.length > 0) {
    const computedSummary = {
      ...computedYears[0],
      claims: computedYears.flatMap((year) => year.claims),
      net_incurred: computedYears
        .flatMap((year) => year.claims)
        .flatMap((claim) => claim.net_incurred ?? 0)
        .reduce((total, current) => total + current, 0),
      ground_up_loss: computedYears
        .flatMap((year) => year.claims)
        .flatMap((claim) => claim.ground_up_loss ?? 0)
        .reduce((total, current) => total + current, 0),
      open_claims: computedYears
        .flatMap((year) => year.claims)
        .flatMap((claim) => (claim.status === "open" ? 1 : 0) as number)
        .reduce((total, current) => total + current, 0),
      closed_claims: computedYears
        .flatMap((year) => year.claims)
        .flatMap((claim) => (claim.status !== "open" ? 1 : 0) as number)
        .reduce((total, current) => total + current, 0),
    };

    mergedYears.push(computedSummary);
  }

  // Merging parsed years is a little trickier. We will merge all of the
  // years with carriers together, then take the one with the biggest
  // net incurred and group up loss
  const parsedYears = lossYears.filter(
    (year) => year.source === "parsed_year"
  ) as client.ParsedLossRunByYear[];

  const parsedYearsWithCarrier = parsedYears.filter(
    (year) => year.source_details.carrier ?? "" !== ""
  );
  const parsedYearsWithoutCarrier = parsedYears.filter(
    (year) => year.source_details.carrier ?? "" === ""
  );

  const allParsedYears = parsedYearsWithoutCarrier;
  if (parsedYearsWithCarrier.length > 0) {
    const combinedCarrierYear = {
      ...parsedYearsWithCarrier[0],
      net_incurred: parsedYearsWithCarrier
        .flatMap((year) => year.net_incurred ?? 0)
        .reduce((total, current) => total + current, 0),
      ground_up_loss: parsedYearsWithCarrier
        .flatMap((year) => year.ground_up_loss ?? 0)
        .reduce((total, current) => total + current, 0),
      open_claims: parsedYearsWithCarrier
        .flatMap((year) => year.open_claims)
        .reduce((total, current) => total + current, 0),
      closed_claims: parsedYearsWithCarrier
        .flatMap((year) => year.closed_claims)
        .reduce((total, current) => total + current, 0),
    };
    allParsedYears.push(combinedCarrierYear);
  }

  allParsedYears.sort((a, b) => {
    if (b.net_incurred !== a.net_incurred) {
      return b.net_incurred ?? 0 - (a.net_incurred ?? 0);
    }
    if (b.ground_up_loss !== a.ground_up_loss) {
      return (b.ground_up_loss ?? 0) - (a.ground_up_loss ?? 0);
    }
    return signatureForSourceDetails(a.source_details).localeCompare(
      signatureForSourceDetails(b.source_details)
    );
  });
  for (const year of allParsedYears) {
    mergedYears.push(year);
  }

  // We should never have more than one user generated year. For now,
  // just add them all together
  const userGeneratedYears = lossYears.filter(
    (year) => year.source === "user_generated"
  ) as client.UserGeneratedLossRunByYear[];
  if (userGeneratedYears.length > 0) {
    const userGeneratedSummary = {
      ...userGeneratedYears[0],
      net_incurred: userGeneratedYears
        .flatMap((year) => year.net_incurred ?? 0)
        .reduce((total, current) => total + current, 0),
      ground_up_loss: userGeneratedYears
        .flatMap((year) => year.ground_up_loss ?? 0)
        .reduce((total, current) => total + current, 0),
      open_claims: userGeneratedYears
        .flatMap((year) => year.open_claims)
        .reduce((total, current) => total + current, 0),
      closed_claims: userGeneratedYears
        .flatMap((year) => year.closed_claims)
        .reduce((total, current) => total + current, 0),
    };

    mergedYears.push(userGeneratedSummary);
  }

  return mergedYears;
};

export const lossRunInfoToLossRunYears = (lossRunInfo: client.LossRunInfo) => {
  const lossesByYear: LossRunByYear[] = [];
  for (const policyYear of Object.keys(lossRunInfo.loss_runs_by_year)) {
    const mergedYears = mergedLossYearsForPolicyYear(lossRunInfo, policyYear);
    if (mergedYears.length === 0) {
      continue;
    }
    lossesByYear.push(mergedYears[0]);
  }

  const sortedYears = lossesByYear.sort((a, b) =>
    b.policy_year.localeCompare(a.policy_year)
  );

  if (sortedYears.length < 2) return sortedYears;

  const result: LossRunByYear[] = [];

  for (let i = 0; i < sortedYears.length - 1; i++) {
    const currentYear = parseInt(sortedYears[i].policy_year);
    const nextYear = parseInt(sortedYears[i + 1].policy_year);

    // Add the current actual year
    result.push(sortedYears[i]);

    // Fill in missing years between current and next
    for (let year = currentYear - 1; year > nextYear; year--) {
      result.push({
        policy_year: year.toString(),
        open_claims: 0,
        closed_claims: 0,
        description_of_losses: "No reported losses",
        ground_up_loss: 0,
        net_incurred: 0,
        source: "inferred",
      });
    }
  }

  // Add the last year
  result.push(sortedYears[sortedYears.length - 1]);

  return result;
};

export const claimsFromLossYears = (lossesByYear: LossRunByYear[]): Loss[] => {
  return lossesByYear.flatMap((loss) =>
    loss.source === "computed_from_claims" ? loss.claims : []
  );
};

export const claimsFromLossRunInfo = (lossRunInfo: client.LossRunInfo) => {
  const lossYears = lossRunInfoToLossRunYears(lossRunInfo);
  return claimsFromLossYears(lossYears);
};

export const totalsForMonetaryFieldsForLossYearsInPolicyYear = (
  lossRunsByYear: LossRunByYear[]
) => {
  const groundUpLossTotals = new Set<string>();
  const netInccuredTotals = new Set<string>();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  for (const lossYear of lossRunsByYear) {
    groundUpLossTotals.add(
      lossYear.ground_up_loss
        ? formatter.format(Math.round(lossYear.ground_up_loss))
        : "N/A"
    );
    netInccuredTotals.add(
      lossYear.net_incurred
        ? formatter.format(Math.round(lossYear.net_incurred))
        : "N/A"
    );
  }

  return [groundUpLossTotals, netInccuredTotals];
};
