export type Color = [number, number, number];
export const rgbString = (c: Color): string => {
  return `rgb(${Math.round(c[0])}, ${Math.round(c[1])}, ${Math.round(c[2])})`;
};
const interp = (x: number, y: number, alpha: number): number =>
  x * (1 - alpha) + y * alpha;

export const interpRgb = (c1: Color, c2: Color, alpha: number): Color => {
  return [
    interp(c1[0], c2[0], alpha),
    interp(c1[1], c2[1], alpha),
    interp(c1[2], c2[2], alpha),
  ];
};
