export const ROUTES = {
  // Logged out
  LANDING: "/",
  COMPANY: "/company",
  SIGN_IN: "/sign-in",
  TOS: "/tos",
  PRIVACY_POLICY: "/privacy-policy",
  SECURITY: "/security",
  CONTACT: "/contact",

  // Logged in
  STR: "/str",
  RENEWAL: "/nd-renewal",
  DENTAL: "/dental",
  DASHBOARD: "/dashboard",
  DASHBOARD_PROPERTY: "/dashboard/reports/property",
  DASHBOARD_CASUALTY: "/dashboard/reports/casualty",
  DASHBOARD_ANALYTICS: "/dashboard/analytics",
  DASHBOARD_ADDRESS_SEARCH: "/dashboard/search-addresses",
  DASHBOARD_REPORT: (reportId: string | number, tabPath?: string) =>
    `/dashboard/report/${reportId}${tabPath ? "/" + tabPath : ""}`,

  // Both
  ARTICLES: "/articles",
  FULL_ARTICLE: (id: string) => `/articles/${id}`,
};
