import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import * as client from "../../../client";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import styles from "./EditSovModal.module.css";
import { useUpdateReport } from "../data";
import { isEqual, clone, cloneDeep } from "lodash";
import { applyOverridesToAddress } from "../Conversion";
import { CONSTRUCTION_CODE_DICT } from "./construction";
import { OCCUPANCY_CODE_DICT } from "./occupancy";
import { ThemeContext } from "../../../themes/Theme";
import { colorForConfidence } from "../dashboard/util";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import { BUILDING_DESCRIPTIONS } from "./building";

export const CONSTRUCTION_MODAL_INDEX = 0;
export const BUILDING_DESCRIPTION_MODEL_INDEX = 1;

type EditSovModalProps = {
  sovs: client.Sov[];
  reportId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  initialActiveIndex: number | undefined;
};

export const EditSovModal: React.FC<EditSovModalProps> = ({
  sovs,
  reportId,
  visible,
  setVisible,
  initialActiveIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex ?? 0);
  useEffect(() => {
    setActiveIndex(initialActiveIndex ?? 0);
  }, [initialActiveIndex]);
  const [newSovs, setNewSovs] = useState<client.Sov[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  // If the input sovs change, set is saving to false. This likely means
  // our edits have finished.
  useEffect(() => {
    if (visible) {
      setIsSaving(false);
      setNewSovs(cloneDeep(sovs));
    }
  }, [sovs, visible]);

  const updateReport = useUpdateReport(reportId);

  const saveEdits = async () => {
    // Is saving will be set to false automatically once this succeeds
    setIsSaving(true);
    updateReport.mutate({ sovs: newSovs });
  };

  const sovsAreEqual = useMemo(() => isEqual(sovs, newSovs), [sovs, newSovs]);

  return (
    <Dialog
      header={
        <div className={styles.buttonContainer}>
          <span style={{ marginRight: "16px" }}>Edit SOV</span>
          <Button
            label="Save Edits"
            disabled={sovsAreEqual}
            onClick={saveEdits}
            loading={isSaving}
          />
          <Button
            severity="secondary"
            label="Clear Edits"
            disabled={sovsAreEqual}
            onClick={() => setNewSovs(cloneDeep(sovs))}
          />
          <EditSheets
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </div>
      }
      visible={visible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={() => setVisible(false)}
    >
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        {/*
        <TabPanel header="Occupancy">
          <EditOccupancy
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </TabPanel>
        */}
        <TabPanel header="Construction">
          <EditConstruction
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </TabPanel>
        <TabPanel header="Business Information">
          <EditBusinessInformation
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

type EditProps = {
  sovs: client.Sov[];
  setNewSovs: Dispatch<SetStateAction<client.Sov[]>>;
  isSaving: boolean;
};

const EditSheets: React.FC<EditProps> = ({ sovs, setNewSovs, isSaving }) => {
  const filenames = sovs.map((sov) => {
    return { name: sov.filename };
  });

  const selectedFilenames = sovs
    .filter((sov) => sov.is_enabled)
    .map((sov) => {
      return { name: sov.filename };
    });

  return (
    <MultiSelect
      disabled={isSaving}
      value={selectedFilenames}
      onChange={(e) => {
        setNewSovs((sovs: client.Sov[]) => {
          const enabledFilenames = e.value as { name: string }[];
          const newSovs = sovs.map((sov) => ({
            ...sov,
            is_enabled: enabledFilenames.some(
              ({ name }) => name === sov.filename
            ),
          }));

          return newSovs;
        });
      }}
      options={filenames}
      optionLabel="name"
      placeholder="Select Files/Sheets"
      style={{ width: "260px" }}
      selectAllLabel="All"
    />
  );
};

type GroupedAddresses = {
  [key: string]: {
    [key: string]: {
      [key: string]: client.Address[];
    };
  };
};
type CodeCount = {
  code: string;
  codeType: string;
  description: string;
  originalDescription: string;
  count: number;
  confidence: string;
};
const NONE_GIVEN = "NONE GIVEN";
const NONE_FOUND = "NONE FOUND";
const CODE_SPLITTER = "___";

const textEditor = (options: ColumnEditorOptions, isSaving: boolean) => {
  return (
    <InputText
      disabled={isSaving}
      type="text"
      value={options.value}
      onChange={(e) => {
        options.editorCallback && options.editorCallback(e.target.value);
      }}
    />
  );
};

const AutocompleteEditor = ({
  options,
  isSaving,
  codeDicts,
}: {
  options: ColumnEditorOptions;
  isSaving: boolean;
  codeDicts: typeof CONSTRUCTION_CODE_DICT;
}) => {
  const [suggestions, setSuggestions] = useState<
    { description: string; code: string }[]
  >([]);
  const originalData = options.rowData as CodeCount;

  const updateSuggestions = (query: string) => {
    const codeDict = codeDicts?.[originalData.codeType];
    setSuggestions(
      codeDict
        ? Object.entries(codeDict)
            .map(([code, description]) => ({
              description: `${code} - ${description}`,
              code,
            }))
            .filter(({ description }) =>
              description.toLowerCase().includes(query.toLowerCase())
            )
        : []
    );
  };

  return (
    <AutoComplete
      disabled={isSaving}
      type="text"
      scrollHeight="500px"
      value={options.value}
      onChange={(e) => {
        const val = e.target.value;
        options.editorCallback?.(typeof val === "string" ? val : val.code);
      }}
      field="description"
      completeMethod={(e) => updateSuggestions(e.query)}
      suggestions={suggestions}
      dropdown
    />
  );
};

const AutocompleteEditorFromStrings = ({
  options,
  isSaving,
  allSuggestions,
}: {
  options: ColumnEditorOptions;
  isSaving: boolean;
  allSuggestions: string[];
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const updateSuggestions = (query: string) => {
    setSuggestions(
      allSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  return (
    <AutoComplete
      disabled={isSaving}
      type="text"
      scrollHeight="500px"
      value={options.value}
      onChange={(e) => {
        const val = e.target.value;
        options.editorCallback?.(val);
      }}
      completeMethod={(e) => updateSuggestions(e.query)}
      suggestions={suggestions}
      dropdown
    />
  );
};

export const originalOccupancyDescriptionFromRawAddress = (
  rawAddress: client.Address
) => {
  return (
    rawAddress.private_original_values["occupancy_description"] ??
    rawAddress.private_original_values["location_description"] ??
    rawAddress.private_original_values["location_name"] ??
    NONE_GIVEN
  );
};

const occupancyDescriptionFromCode = (
  codeType: string | undefined,
  code: string | undefined
) => {
  return OCCUPANCY_CODE_DICT[codeType ?? ""]?.[code ?? ""] ?? NONE_FOUND;
};

const Confidence = ({ data }: { data: CodeCount }) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const capitalizedConfidence =
    data.confidence.charAt(0).toUpperCase() + data.confidence.slice(1);

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          padding: "2px 4px",
          display: "flex",
          backgroundColor: colorForConfidence(data.confidence, isLightMode),
        }}
      >
        <span>{capitalizedConfidence}</span>
      </div>
    </div>
  );
};

export const EditOccupancy: React.FC<EditProps> = ({
  sovs,
  setNewSovs,
  isSaving,
}) => {
  const groupedOccupancies: GroupedAddresses = {};
  for (const sov of sovs) {
    if (sov.is_enabled) {
      for (const rawAddress of sov.addresses) {
        const address = applyOverridesToAddress(rawAddress);
        if (address.occupancy_code && address.occupancy_code_type) {
          const occCode =
            address.occupancy_code +
            CODE_SPLITTER +
            address.occupancy_code_type;
          if (!(occCode in groupedOccupancies)) {
            groupedOccupancies[occCode] = {};
          }
          const originalDescription =
            originalOccupancyDescriptionFromRawAddress(rawAddress);
          const occDescription = occupancyDescriptionFromCode(
            address.occupancy_code_type,
            address.occupancy_code
          );
          if (!(occDescription in groupedOccupancies[occCode])) {
            groupedOccupancies[occCode][occDescription] = {};
          }
          if (
            !(
              originalDescription in groupedOccupancies[occCode][occDescription]
            )
          ) {
            groupedOccupancies[occCode][occDescription][originalDescription] =
              [];
          }
          groupedOccupancies[occCode][occDescription][originalDescription].push(
            rawAddress
          );
        }
      }
    }
  }

  const occupancyCounts: CodeCount[] = [];
  for (const code of Object.keys(groupedOccupancies)) {
    for (const description of Object.keys(groupedOccupancies[code])) {
      for (const originalDescription of Object.keys(
        groupedOccupancies[code][description]
      )) {
        const addresses =
          groupedOccupancies[code][description][originalDescription];
        const firstAddress = addresses[0];
        const occupancyFlags =
          firstAddress.private_flags["occupancy_code"] ?? [];
        let confidence = "high";
        if (occupancyFlags.includes("low_confidence")) {
          confidence = "low";
        } else if (occupancyFlags.includes("medium_confidence")) {
          confidence = "medium";
        }
        const codeParts = code.split(CODE_SPLITTER);
        const occupancyCount: CodeCount = {
          code: codeParts[0],
          codeType: codeParts[1],
          description: description,
          originalDescription: originalDescription,
          count: addresses.length,
          confidence: confidence,
        };
        occupancyCounts.push(occupancyCount);
      }
    }
  }
  occupancyCounts.sort((a, b) => {
    if (b.confidence === "low" && a.confidence !== "low") {
      return 1;
    } else if (a.confidence === "low" && b.confidence !== "low") {
      return -1;
    } else if (b.confidence === "medium" && a.confidence !== "medium") {
      return 1;
    } else if (a.confidence === "medium" && b.confidence !== "medium") {
      return -1;
    }
    return b.count - a.count;
  });

  return (
    <DataTable
      value={occupancyCounts}
      editMode="row"
      onRowEditComplete={(e) => {
        // If there were no edits, exit early
        if (JSON.stringify(e.data) === JSON.stringify(e.newData)) {
          return;
        }
        // Prevent saving the empty string, which can have a bad effect
        if (e.newData.code === "" || e.newData.codeType === "") {
          return;
        }

        setNewSovs((sovs) => {
          const newSovs = [];
          for (const sov of sovs) {
            const newSov = clone(sov);
            for (const rawAddress of newSov.addresses) {
              const address = applyOverridesToAddress(rawAddress);
              if (
                address.occupancy_code_type === e.data.codeType &&
                address.occupancy_code === e.data.code &&
                originalOccupancyDescriptionFromRawAddress(rawAddress) ===
                  e.data.originalDescription
              ) {
                rawAddress.private_overrides.occupancy_code = e.newData.code;
                rawAddress.private_overrides.occupancy_code_type =
                  e.newData.codeType;
              }
            }
            newSovs.push(newSov);
          }
          return newSovs;
        });
      }}
    >
      <Column field="originalDescription" header="Input Description" />
      <Column
        field="code"
        header="Occupancy Code"
        headerStyle={{ minWidth: "200px" }}
        editor={(options) => (
          <AutocompleteEditor
            options={options}
            isSaving={isSaving}
            codeDicts={OCCUPANCY_CODE_DICT}
          />
        )}
      />
      <Column
        field="codeType"
        header="Code Type"
        editor={(options) => textEditor(options, isSaving)}
      />
      <Column
        field="description"
        header="Occupancy Code Description"
        editor={(options) => (
          <span style={{ opacity: "0.5" }}>{options.value}</span>
        )}
      />
      <Column
        field="confidence"
        header="Confidence"
        body={(data: CodeCount) => <Confidence data={data} />}
      />
      <Column field="count" header="Count" />
      <Column
        rowEditor={true}
        headerStyle={{ width: "10%", minWidth: "8rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};

export const originalConstructionDescriptionFromRawAddress = (
  rawAddress: client.Address
) => {
  return (
    rawAddress.private_original_values["construction_description"] ?? NONE_GIVEN
  );
};

const constructionDescriptionFromCode = (
  codeType: string | undefined | null,
  code: string | undefined | null
) => {
  return CONSTRUCTION_CODE_DICT[codeType ?? ""]?.[code ?? ""] ?? NONE_FOUND;
};

const EditConstruction: React.FC<EditProps> = ({
  sovs,
  setNewSovs,
  isSaving,
}) => {
  const groupedConstructions: GroupedAddresses = {};
  for (const sov of sovs) {
    if (sov.is_enabled) {
      for (const rawAddress of sov.addresses) {
        const address = applyOverridesToAddress(rawAddress);

        const conCode =
          (address.construction_code ?? NONE_FOUND) +
          CODE_SPLITTER +
          (address.construction_code_type ?? NONE_FOUND);
        if (!(conCode in groupedConstructions)) {
          groupedConstructions[conCode] = {};
        }

        const conDescription = constructionDescriptionFromCode(
          address.construction_code_type,
          address.construction_code
        );
        const originalDescription =
          originalConstructionDescriptionFromRawAddress(rawAddress);
        if (!(conDescription in groupedConstructions[conCode])) {
          groupedConstructions[conCode][conDescription] = {};
        }
        if (
          !(
            originalDescription in groupedConstructions[conCode][conDescription]
          )
        ) {
          groupedConstructions[conCode][conDescription][originalDescription] =
            [];
        }

        groupedConstructions[conCode][conDescription][originalDescription].push(
          rawAddress
        );
      }
    }
  }

  const constructionCounts: CodeCount[] = [];
  for (const code of Object.keys(groupedConstructions)) {
    for (const description of Object.keys(groupedConstructions[code])) {
      for (const originalDescription of Object.keys(
        groupedConstructions[code][description]
      )) {
        const addresses =
          groupedConstructions[code][description][originalDescription];
        const firstAddress = addresses[0];
        const constructionFlags =
          firstAddress.private_flags["construction_code"] ?? [];
        let confidence = "high";
        if (constructionFlags.includes("low_confidence")) {
          confidence = "low";
        } else if (constructionFlags.includes("medium_confidence")) {
          confidence = "medium";
        }

        const codeParts = code.split(CODE_SPLITTER);
        const constructionCount: CodeCount = {
          code: codeParts[0],
          codeType: codeParts[1],
          description: description,
          originalDescription: originalDescription,
          count: addresses.length,
          confidence: confidence,
        };
        constructionCounts.push(constructionCount);
      }
    }
  }
  constructionCounts.sort((a, b) => {
    if (b.confidence === "low" && a.confidence !== "low") {
      return 1;
    } else if (a.confidence === "low" && b.confidence !== "low") {
      return -1;
    } else if (b.confidence === "medium" && a.confidence !== "medium") {
      return 1;
    } else if (a.confidence === "medium" && b.confidence !== "medium") {
      return -1;
    }
    return b.count - a.count;
  });

  return (
    <DataTable
      value={constructionCounts}
      editMode="row"
      onRowEditComplete={(e) => {
        // If there were no edits, exit early
        if (JSON.stringify(e.data) === JSON.stringify(e.newData)) {
          return;
        }
        // Prevent saving the empty string, which can have a bad effect
        if (e.newData.code === "" || e.newData.codeType === "") {
          return;
        }

        setNewSovs((sovs) => {
          const newSovs = [];
          for (const sov of sovs) {
            const newSov = clone(sov);
            for (const rawAddress of newSov.addresses) {
              const address = applyOverridesToAddress(rawAddress);
              if (
                (address.construction_code_type ?? NONE_FOUND) ===
                  e.data.codeType &&
                (address.construction_code ?? NONE_FOUND) === e.data.code &&
                originalConstructionDescriptionFromRawAddress(rawAddress) ===
                  e.data.originalDescription
              ) {
                rawAddress.private_overrides.construction_code = e.newData.code;
                rawAddress.private_overrides.construction_code_type =
                  e.newData.codeType;
              }
            }
            newSovs.push(newSov);
          }
          return newSovs;
        });
      }}
    >
      <Column field="originalDescription" header="Input Description" />
      <Column
        field="code"
        header="Construction Code"
        headerStyle={{ minWidth: "200px" }}
        editor={(options) => (
          <AutocompleteEditor
            options={options}
            isSaving={isSaving}
            codeDicts={CONSTRUCTION_CODE_DICT}
          />
        )}
      />
      <Column
        field="codeType"
        header="Code Type"
        editor={(options) => textEditor(options, isSaving)}
      />
      <Column
        field="description"
        header="Construction Code Description"
        editor={(options) => (
          <span style={{ opacity: "0.5" }}>{options.value}</span>
        )}
      />
      <Column
        field="confidence"
        header="Confidence"
        body={(data: CodeCount) => <Confidence data={data} />}
      />
      <Column field="count" header="Count" />
      <Column
        rowEditor={true}
        headerStyle={{ width: "10%", minWidth: "8rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};

export const originalBuildingDescriptionFromRawAddress = (
  rawAddress: client.Address
) => {
  const originalBuildingDescription =
    rawAddress.private_original_values["building_description"];
  const originalOccupancyDescription =
    rawAddress.private_original_values["occupancy_description"];
  if (
    originalBuildingDescription &&
    originalOccupancyDescription &&
    originalBuildingDescription !== originalOccupancyDescription
  ) {
    return `${originalBuildingDescription} -
      ${originalOccupancyDescription}`;
  }

  return (
    originalBuildingDescription ?? originalOccupancyDescription ?? NONE_GIVEN
  );
};

const EditBusinessInformation: React.FC<EditProps> = ({
  sovs,
  setNewSovs,
  isSaving,
}) => {
  const groupedBuildingDescriptions: {
    [key: string]: { [key: string]: client.Address[] };
  } = {};
  for (const sov of sovs) {
    if (sov.is_enabled) {
      for (const rawAddress of sov.addresses) {
        const address = applyOverridesToAddress(rawAddress);
        const buildingDescription = address.building_description ?? NONE_FOUND;
        if (!(buildingDescription in groupedBuildingDescriptions)) {
          groupedBuildingDescriptions[buildingDescription] = {};
        }

        const originalDescription =
          originalBuildingDescriptionFromRawAddress(rawAddress);

        if (
          !(
            originalDescription in
            groupedBuildingDescriptions[buildingDescription]
          )
        ) {
          groupedBuildingDescriptions[buildingDescription][
            originalDescription
          ] = [];
        }

        groupedBuildingDescriptions[buildingDescription][
          originalDescription
        ].push(rawAddress);
      }
    }
  }

  const buildingDescriptionCounts: CodeCount[] = [];
  for (const description of Object.keys(groupedBuildingDescriptions)) {
    for (const originalDescription of Object.keys(
      groupedBuildingDescriptions[description]
    )) {
      const addresses =
        groupedBuildingDescriptions[description][originalDescription];
      const firstAddress = addresses[0];
      const constructionFlags =
        firstAddress.private_flags["building_description"] ?? [];
      let confidence = "high";
      if (constructionFlags.includes("low_confidence")) {
        confidence = "low";
      } else if (constructionFlags.includes("medium_confidence")) {
        confidence = "medium";
      }

      const constructionCount: CodeCount = {
        code: "",
        codeType: "",
        description: description,
        originalDescription: originalDescription,
        count: addresses.length,
        confidence: confidence,
      };
      buildingDescriptionCounts.push(constructionCount);
    }
  }
  buildingDescriptionCounts.sort((a, b) => {
    if (b.confidence === "low" && a.confidence !== "low") {
      return 1;
    } else if (a.confidence === "low" && b.confidence !== "low") {
      return -1;
    } else if (b.confidence === "medium" && a.confidence !== "medium") {
      return 1;
    } else if (a.confidence === "medium" && b.confidence !== "medium") {
      return -1;
    }
    return b.count - a.count;
  });

  return (
    <DataTable
      value={buildingDescriptionCounts}
      editMode="row"
      onRowEditComplete={(e) => {
        // If there were no edits, exit early
        if (JSON.stringify(e.data) === JSON.stringify(e.newData)) {
          return;
        }
        // Prevent saving the empty string, which can have a bad effect
        if (e.newData.description === "") {
          return;
        }

        setNewSovs((sovs) => {
          const newSovs = [];
          for (const sov of sovs) {
            const newSov = clone(sov);
            for (const rawAddress of newSov.addresses) {
              const address = applyOverridesToAddress(rawAddress);
              if (
                address.building_description === e.data.description &&
                originalBuildingDescriptionFromRawAddress(rawAddress) ===
                  e.data.originalDescription
              ) {
                rawAddress.private_overrides.building_description =
                  e.newData.description;
              }
            }
            newSovs.push(newSov);
          }
          return newSovs;
        });
      }}
    >
      <Column field="originalDescription" header="Input Description" />
      <Column
        field="description"
        header="Business Information"
        editor={(options) => (
          <AutocompleteEditorFromStrings
            options={options}
            isSaving={isSaving}
            allSuggestions={BUILDING_DESCRIPTIONS}
          />
        )}
      />
      <Column
        field="confidence"
        header="Confidence"
        body={(data: CodeCount) => <Confidence data={data} />}
      />
      <Column field="count" header="Count" />
      <Column
        rowEditor={true}
        headerStyle={{ width: "10%", minWidth: "8rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};
