import { useCallback, useContext, useEffect } from "react";
import { CompanyContext } from "./CompanyConfig";
import { buildChannel, isRunningLocally } from "./util";
import amplitude from "amplitude-js";
import { useUsers } from "./pages/specialty-assistant/data";
import { useParams } from "react-router-dom";
import { User } from "./client";

const AMPLITUDE_API_KEY = isRunningLocally()
  ? "70c88231ad2fe45d2498a07e78adc5d2"
  : "1a5881921e6fb1d6b71eaccf9745528c";

const instance = amplitude.getInstance();
instance.init(AMPLITUDE_API_KEY);

export const logEvent = (
  eventName: string,
  eventProperties?: { [field: string]: unknown }
) => {
  const allEventProperties = eventProperties ?? {};
  allEventProperties["build_channel"] = buildChannel();

  if (isRunningLocally()) {
    console.log(
      `Log ${eventName} to Amplitude with event properties`,
      eventProperties
    );
  }

  instance.logEvent(eventName, allEventProperties);
};

const createLogUIEventPayload = (
  company: string | null,
  localUser: User | undefined,
  reportId: string | undefined,
  eventName: string,
  eventProperties?: { [field: string]: unknown }
) => {
  const allEventProperties = eventProperties ?? {};
  if (company) {
    allEventProperties["pantheon_client_id"] = company;
  }
  if (localUser) {
    allEventProperties["pantheon_user_id"] = localUser.id;
    allEventProperties["pantheon_user_email"] = localUser.email;
  }
  if (reportId) {
    allEventProperties["acropolis_report_id"] = reportId;
  }

  return { eventName, allEventProperties };
};

// Hook that automatically logs on mount via useEffect
export const useLogUIEventOnMount = (
  eventName: string,
  eventProperties?: { [field: string]: unknown }
) => {
  const company = useContext(CompanyContext);
  const localUser = useUsers(company)?.data?.current_user;
  const { reportId } = useParams<{ reportId: string }>();

  useEffect(() => {
    // Only log once we have these
    if (!company || !localUser) {
      return;
    }

    const { eventName: name, allEventProperties } = createLogUIEventPayload(
      company,
      localUser,
      reportId,
      eventName,
      eventProperties
    );
    logEvent(name, allEventProperties);
  }, [company, localUser, reportId, eventName, eventProperties]);
};

// Hook that returns a callable function
export const useLogUIEventCallback = () => {
  const company = useContext(CompanyContext);
  const localUser = useUsers(company)?.data?.current_user;
  const { reportId } = useParams<{ reportId: string }>();

  return useCallback(
    (eventName: string, eventProperties?: { [field: string]: unknown }) => {
      const { eventName: name, allEventProperties } = createLogUIEventPayload(
        company,
        localUser,
        reportId,
        eventName,
        eventProperties
      );
      logEvent(name, allEventProperties);
    },
    [company, localUser, reportId]
  );
};
