import * as React from "react";
import { CompanyContext } from "./CompanyConfig";

export const PantheonOnly: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <SomeClientsOnly clientIds={["pantheon"]}>{children}</SomeClientsOnly>;

export const SomeClientsOnly: React.FC<{
  clientIds: string[];
  children: React.ReactNode;
}> = ({ clientIds, children }) => {
  const company = React.useContext(CompanyContext);
  return company && clientIds.includes(company) ? <>{children}</> : null;
};
