export const perpendiculars = (
  d: [number, number]
): [[number, number], [number, number]] => {
  const [dx, dy] = d;
  return [
    [-dy, dx],
    [dy, -dx],
  ];
};

export const add = (
  v1: [number, number],
  v2: [number, number]
): [number, number] => {
  return [v1[0] + v2[0], v1[1] + v2[1]];
};

export const mileDegreeConversions = (
  latitude: number
): {
  milesToDegreeLat: number;
  milesToDegreeLon: number;
  degreesToMilesLat: number;
  degreesToMilesLon: number;
} => {
  const earthRadiusMiles = 3958.8;
  const milesToDegreeLat = 360 / (2 * Math.PI * earthRadiusMiles);
  const milesToDegreeLon =
    milesToDegreeLat / Math.max(Math.cos(latitude * (Math.PI / 180)), 1e-6);

  return {
    milesToDegreeLat,
    milesToDegreeLon,
    degreesToMilesLat: 1 / milesToDegreeLat,
    degreesToMilesLon: 1 / milesToDegreeLon,
  };
};

export const unit = (a: [number, number]): [number, number] => {
  const m = Math.sqrt(a[0] * a[0] + a[1] * a[1]);
  return scale(a, 1 / m);
};

export const scale = (a: [number, number], alpha: number): [number, number] => {
  return [a[0] * alpha, a[1] * alpha];
};

export const interp = (
  a: [number, number],
  b: [number, number],
  x: number
): [number, number] => {
  return add(scale(a, 1 - x), scale(b, x));
};
