import { Card } from "primereact/card";
import * as client from "../../../client";
import { applyOverridesToCompanyInfo } from "../Conversion";
import styles from "./GeneralInput.module.css";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { cloneDeep, isEqual } from "lodash";
import { useUpdateReport } from "../data";
import { flaggedHighlightColor } from "../dashboard/util";
import { ThemeContext } from "../../../themes/Theme";
import { Tooltip } from "primereact/tooltip";
import { v4 as uuid } from "uuid";
import { EnumEditor } from "../sov/CellEditors";
import { InputTextarea } from "primereact/inputtextarea";
import { GoogleSheetsModal } from "../sov/GoogleSheetsModal";
import { BUSINESS_DESCRIPTIONS } from "./business-descriptions";

type EditableFlaggedFieldProps = {
  rawCompanyInfo: client.CompanyInfo;
  safeCompanyInfo: client.RawCompanyInfo;
  setSafeCompanyInfo: Dispatch<SetStateAction<client.RawCompanyInfo>>;
  field: keyof client.RawCompanyInfo;
  label: string;
  isEditing: boolean;
  enumValues?: string[];
};

const EditableFlaggedField: React.FC<EditableFlaggedFieldProps> = ({
  rawCompanyInfo,
  safeCompanyInfo,
  field,
  label,
  isEditing,
  setSafeCompanyInfo,
  enumValues,
}) => {
  const currentValue =
    field === "additional_named_insureds"
      ? (safeCompanyInfo.additional_named_insureds ?? []).join("\n")
      : safeCompanyInfo[field] ?? "";
  const [textValue, setTextValue] = useState(currentValue);
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const hasOverride = field in rawCompanyInfo.private_overrides;
  // TODO: Add flags
  const maxSeverity = 0;

  const flagText = hasOverride
    ? `A user edited this value. The original value was "${
        rawCompanyInfo.private_original_values[field] ?? "None"
      }".`
    : "";
  const tooltipId = `tooltip-${uuid()}`;
  const inputTextStyles: React.CSSProperties = {
    height: "24px",
    width: "200px",
    boxShadow:
      hasOverride || maxSeverity
        ? `0 0 0 2px ${flaggedHighlightColor(
            maxSeverity,
            isLightMode,
            hasOverride
          )}`
        : "",
  };
  const labelStyles: React.CSSProperties =
    hasOverride || maxSeverity
      ? {
          backgroundColor: flaggedHighlightColor(
            maxSeverity,
            isLightMode,
            hasOverride
          ),
          padding: "2px",
        }
      : {};

  const onEdit = (newValue: string) => {
    setSafeCompanyInfo((oldCompanyInfo) => {
      const newCompanyInfo = { ...oldCompanyInfo };
      if (field === "additional_named_insureds") {
        const newValues = newValue
          .split("\n")
          .map((value) => value.trim())
          .filter((value) => value !== "");

        newCompanyInfo[field] = newValues;
      } else {
        newCompanyInfo[field] = newValue;
      }
      return newCompanyInfo;
    });
  };

  useEffect(() => {
    if (field === "additional_named_insureds") {
      const newValues = textValue
        .split("\n")
        .map((value) => value.trim())
        .filter((value) => value !== "");
      if (!isEqual(newValues, safeCompanyInfo[field])) {
        onEdit(newValues.join("\n"));
      }
    }
  }, [textValue]);

  return (
    <div className={styles.horizontalContainer}>
      {field !== "additional_named_insureds" && (
        <span style={{ fontWeight: "bold", width: "150px", fontSize: "14px" }}>
          {label}
        </span>
      )}

      <Tooltip target={`#${tooltipId}`} position="right" />
      {isEditing ? (
        enumValues ? (
          <EnumEditor
            options={{ value: currentValue, editorCallback: onEdit }}
            enumValues={enumValues}
            additionalStyles={{ ...inputTextStyles, height: "26px" }}
            disabled={false}
            pt={{ input: { style: { paddingTop: "4px" } } }}
          />
        ) : field === "additional_named_insureds" ? (
          <InputTextarea
            style={{ ...inputTextStyles, height: "auto", width: "360px" }}
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            data-pr-tooltip={flagText}
            data-pr-showdelay={500}
            id={tooltipId}
            rows={4}
            placeholder="Separate with newlines"
          />
        ) : (
          <InputText
            style={inputTextStyles}
            value={currentValue}
            onChange={(e) => onEdit(e.target.value)}
            data-pr-tooltip={flagText}
            data-pr-showdelay={500}
            id={tooltipId}
          />
        )
      ) : (
        <>
          {field === "additional_named_insureds" ? (
            <div className={styles.verticalContainer}>
              {safeCompanyInfo.additional_named_insureds?.map((name) => (
                <span
                  key={name}
                  style={labelStyles}
                  data-pr-tooltip={flagText}
                  data-pr-showdelay={500}
                  id={tooltipId}
                >
                  {name}
                </span>
              ))}
            </div>
          ) : (
            <div
              style={{ height: "26px", display: "flex", alignItems: "center" }}
            >
              <span
                style={labelStyles}
                data-pr-tooltip={flagText}
                data-pr-showdelay={500}
                id={tooltipId}
              >
                {currentValue}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

type GenearlInputProps = {
  specialtyPropertyInfo: client.ReportResponse;
};

export const GeneralInput: React.FC<GenearlInputProps> = ({
  specialtyPropertyInfo,
}) => {
  const rawCompanyInfo = specialtyPropertyInfo.report_json.company_info;
  const originalSafeCompanyInfo = applyOverridesToCompanyInfo(rawCompanyInfo);
  const [isEditing, setIsEditing] = useState(false);
  const [safeCompanyInfo, setSafeCompanyInfo] = useState(
    originalSafeCompanyInfo
  );
  const [googleSheetsModalVisible, setGoogleSheetsModalVisible] =
    useState(false);
  const updateReport = useUpdateReport(specialtyPropertyInfo.report_id);

  return (
    <Card
      header={
        <div
          className={styles.horizontalContainer}
          style={{
            paddingTop: "20px",
            paddingLeft: "20px",
          }}
        >
          <span className="p-card-title" style={{ marginRight: "12px" }}>
            General Input
          </span>
          {!isEditing && (
            <>
              <Button
                disabled={updateReport.isPending}
                label="Populate Google Sheets"
                onClick={() => setGoogleSheetsModalVisible(true)}
                icon="pi pi-google"
                raised
                text
              />
              <Button
                disabled={updateReport.isPending}
                label="Edit"
                onClick={() => setIsEditing(true)}
                icon="pi pi-pencil"
                raised
                severity="secondary"
                text
              />
            </>
          )}
          {isEditing && (
            <>
              <Button
                label="Cancel"
                disabled={updateReport.isPending}
                onClick={() => {
                  setSafeCompanyInfo(originalSafeCompanyInfo);
                  setIsEditing(false);
                }}
                raised
                severity="secondary"
                text
              />
              <Button
                label={"Save"}
                loading={updateReport.isPending}
                disabled={isEqual(originalSafeCompanyInfo, safeCompanyInfo)}
                onClick={async () => {
                  const allKeys = Array.from(
                    Object.keys(safeCompanyInfo)
                  ) as (keyof client.RawCompanyInfo)[];
                  const newCompanyInfo = cloneDeep(rawCompanyInfo);
                  for (const key of allKeys) {
                    const newValue = safeCompanyInfo[key];

                    if (
                      newValue !== undefined &&
                      newValue !== null &&
                      !isEqual(newValue, originalSafeCompanyInfo[key])
                    ) {
                      newCompanyInfo.private_overrides[key] = newValue;
                    }

                    // Drop fields that are the same as their original values
                    if (
                      isEqual(
                        newValue,
                        newCompanyInfo.private_original_values[key]
                      )
                    ) {
                      delete newCompanyInfo.private_overrides[key];
                    }
                  }
                  await updateReport.mutateAsync({
                    companyInfo: newCompanyInfo,
                  });
                  setIsEditing(false);
                }}
                raised
                text
              />
            </>
          )}
        </div>
      }
    >
      <div className={styles.verticalContainer} style={{ marginTop: "-20px" }}>
        <span className={styles.header} style={{ marginTop: "0px" }}>
          GENERAL
        </span>
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Named Insured"
          field="named_insured"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="DBA"
          field="dba"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />

        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Effective Date"
          field="effective_date"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Expiration Date"
          field="expiration_date"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Business Description"
          field="business_description"
          enumValues={BUSINESS_DESCRIPTIONS}
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Entity Type"
          field="entity_type"
          enumValues={[
            "LLC",
            "Corporation",
            "Joint Venture",
            "Parternship",
            "Individual",
          ]}
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Years in Business"
          field="years_in_business"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Brokerage"
          field="broker_name"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Broker Contact"
          field="broker_email"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <div className={styles.line} />
        <span className={styles.header}>INSURED MAILING ADDRESS</span>
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Mailing Street Address"
          field="company_address"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Mailing City"
          field="company_city"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Mailing State"
          field="company_state"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label="Mailing Zip Code"
          field="company_zip"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
        <div className={styles.line} />
        <span className={styles.header}>ADDITIONAL NAMED INSUREDS</span>
        <EditableFlaggedField
          rawCompanyInfo={rawCompanyInfo}
          safeCompanyInfo={safeCompanyInfo}
          label=""
          field="additional_named_insureds"
          isEditing={isEditing}
          setSafeCompanyInfo={setSafeCompanyInfo}
        />
      </div>
      <GoogleSheetsModal
        visible={googleSheetsModalVisible}
        setVisible={setGoogleSheetsModalVisible}
        headerText={`Send General Input to Google Sheets`}
        reportId={specialtyPropertyInfo.report_id}
        sendGeneralInput={true}
      />
    </Card>
  );
};
