import { Dialog } from "primereact/dialog";
import styles from "./MapModal.module.css";
import {
  Circle,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import { Fragment, useEffect, useMemo, useState } from "react";
import { SafeReportResponse } from "../Conversion";
import { AbbreviatedMoney } from "../Money";
import haversineDistance from "haversine-distance";
import {} from "haversine-distance";
import * as V from "./vector";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from "primereact/slider";
import { interpRgb, rgbString } from "./colors";
import { useWindowSize } from "../../../util";
import { RawAddress } from "../../../client";

const DEBUG = false;
const MILES_TO_METERS = 1609.34;
type RiskCluster = {
  lat: number;
  lon: number;
  radius: number;
  tiv: number;
  included: Plottable[];
  debug: {
    seedPoints: Plottable[];
    perCircle: {
      midPoint: { lat: number; lon: number };
      norms: { lat: number; lon: number }[];
    }[];
  };
};

const centerCluster = (a: RiskCluster): RiskCluster => {
  // adjust the centers for visual purposes
  const maxLat = a.included.reduce((a, b) => Math.max(a, b.lat), -Infinity);
  const minLat = a.included.reduce((a, b) => Math.min(a, b.lat), Infinity);
  const maxLon = a.included.reduce((a, b) => Math.max(a, b.lon), -Infinity);
  const minLon = a.included.reduce((a, b) => Math.min(a, b.lon), Infinity);
  const proposedLat = (maxLat + minLat) / 2;
  const proposedLon = (maxLon + minLon) / 2;

  // Binary search to find the position closest to proposed that still contains all the points.
  let i = 0.5;
  for (let dec = 0.25; dec >= 0.001; dec /= 2.0) {
    const newCenter = V.interp([a.lon, a.lat], [proposedLon, proposedLat], i);

    if (
      a.included.every((o) => haversineDistanceMiles(o, newCenter) <= a.radius)
    ) {
      i += dec;
    } else {
      i -= dec;
    }
  }

  const [lon, lat] = V.interp([a.lon, a.lat], [proposedLon, proposedLat], i);
  const maxDistance = a.included
    .map((x) => haversineDistanceMiles(x, [lon, lat]))
    .reduce((a, b) => Math.max(a, b));
  return {
    ...a,
    lat,
    lon,
    radius: Math.max(0.25, Math.min(a.radius, maxDistance * 1.05)),
  };
};

// Roughly follows the approach described here: https://stackoverflow.com/a/3229582
// The runtime is at least O(n^3) though it seems fast enough in practice due to early exits;
// For large numbers of addresses we would probably need to use an acceleration
// structure like a quadtree to avoid comparing every point against every other
// so often.
export const findAddressesInRadius = (
  addresses: Plottable[],
  radiusMiles: number
): RiskCluster[] => {
  const clusters: RiskCluster[] = [];
  for (let i = 0; i < addresses.length; i++) {
    for (let j = i + 1; j < addresses.length; j++) {
      const a = addresses[i];
      const b = addresses[j];
      const dist = haversineDistanceMiles(a, b);
      if (dist <= 2.0 * radiusMiles) {
        const mid: [number, number] = [
          (a.lon + b.lon) / 2,
          (a.lat + b.lat) / 2,
        ];
        const {
          milesToDegreeLon,
          milesToDegreeLat,
          degreesToMilesLat,
          degreesToMilesLon,
        } = V.mileDegreeConversions(mid[1]);
        const [norm1, norm2] = V.perpendiculars(
          V.unit([
            b.lon * degreesToMilesLon - a.lon * degreesToMilesLon,
            b.lat * degreesToMilesLat - a.lat * degreesToMilesLat,
          ])
        );
        const distanceToCenterAlongNormMiles = Math.sqrt(
          radiusMiles * radiusMiles - (dist / 2) * (dist / 2)
        );

        // NB norm are unit
        const [cos1, sin1] = norm1;
        const [cos2, sin2] = norm2;

        const center1 = V.add(
          [
            distanceToCenterAlongNormMiles * milesToDegreeLon * cos1,
            distanceToCenterAlongNormMiles * milesToDegreeLat * sin1,
          ],
          mid
        );
        const center2 = V.add(
          [
            distanceToCenterAlongNormMiles * milesToDegreeLon * cos2,
            distanceToCenterAlongNormMiles * milesToDegreeLat * sin2,
          ],
          mid
        );

        for (const c of [center1, center2]) {
          const included = [];
          for (const x of addresses) {
            const d = haversineDistanceMiles(x, c);
            if (d <= radiusMiles * 1.01) {
              included.push(x);
            }
          }
          const tiv = included.reduce((a, b) => a + b.tiv, 0.0);
          if (included.length > 0) {
            const cluster = {
              lon: c[0],
              lat: c[1],
              radius: radiusMiles,
              tiv,
              included,
              debug: {
                seedPoints: [a, b],
                perCircle: [
                  {
                    midPoint: { lat: center1[1], lon: center1[0] },
                    norms: [
                      {
                        lat: norm1[1] * milesToDegreeLat,
                        lon: norm1[0] * milesToDegreeLon,
                      },
                      {
                        lat: norm2[1] * milesToDegreeLat,
                        lon: norm2[0] * milesToDegreeLon,
                      },
                    ],
                  },
                  {
                    midPoint: { lat: center2[1], lon: center2[0] },
                    norms: [
                      {
                        lat: norm1[1] * milesToDegreeLat,
                        lon: norm1[0] * milesToDegreeLon,
                      },
                      {
                        lat: norm2[1] * milesToDegreeLat,
                        lon: norm2[0] * milesToDegreeLon,
                      },
                    ],
                  },
                ],
              },
            };
            clusters.push(centerCluster(cluster));
          }
        }
      }
    }
  }

  clusters.sort((a, b) => b.tiv - a.tiv);
  const accepted: RiskCluster[] = [];
  for (const cluster of clusters) {
    const hasConflict = accepted.some(
      (prior) =>
        haversineDistanceMiles(
          [prior.lon, prior.lat],
          [cluster.lon, cluster.lat]
        ) <
        prior.radius + cluster.radius
    );
    if (!hasConflict) {
      accepted.push(cluster);
    }
  }

  return accepted;
};

type Coord = Parameters<typeof haversineDistance>[0];
const haversineDistanceMiles = (a: Coord, b: Coord): number => {
  const meters = haversineDistance(a, b);
  return meters * (1 / MILES_TO_METERS);
};

const formatMiles = (miles: number): string => {
  const factor = miles < 10 ? 10.0 : miles < 1000.0 ? 1.0 : 0.1;
  const roundedMiles = Math.round(miles * factor) / factor;
  return roundedMiles.toString();
};
const haversineDistanceMilesString = (a: Coord, b: Coord): string => {
  return formatMiles(haversineDistanceMiles(a, b));
};

const averageCoordinate = (xs: Plottable[]): [number, number] => {
  return [
    xs.reduce((a, b) => a + b.lat, 0.0) / xs.length,
    xs.reduce((a, b) => a + b.lon, 0.0) / xs.length,
  ];
};

const Cluster = ({
  cluster: c,
  maxClusterTiv,
}: {
  maxClusterTiv: number;
  cluster: RiskCluster;
}) => {
  return (
    <Fragment key={`${c.lat}-${c.lon}`}>
      {" "}
      <Circle
        center={[c.lat, c.lon]}
        radius={c.radius * MILES_TO_METERS}
        color={rgbString(
          interpRgb([128, 128, 128], [255, 0, 0], c.tiv / (maxClusterTiv ?? 1))
        )}
        key={`${c.lat}-${c.lon}`}
      >
        <Popup>
          <AbbreviatedMoney dollars={c.tiv} /> across {c.included.length}{" "}
          properties
          <br />
          within diameter of {formatMiles(2 * c.radius)} miles
        </Popup>
      </Circle>
      {DEBUG ? (
        <>
          <Polyline
            key={`${c.lat} ${c.lon} x`}
            positions={c.debug.seedPoints.map((s) => [s.lat, s.lon])}
          />
          {c.debug.perCircle.flatMap(({ midPoint, norms }) =>
            norms.map((n, i) => [
              <Polyline
                key={`${c.lat} ${c.lon} n${i}`}
                color="#FF0000"
                positions={[
                  [midPoint.lat, midPoint.lon],
                  [midPoint.lat + n.lat, midPoint.lon + n.lon],
                ]}
              />,
            ])
          )}
        </>
      ) : null}
    </Fragment>
  );
};

const SetBounds = ({
  minLat,
  minLon,
  maxLat,
  maxLon,
}: {
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
}) => {
  const map = useMap();
  useEffect(() => {
    // Expand by a bit so everything is certainly in view
    const latBuffer = (maxLat - minLat) / 4;
    const longBuffer = (maxLon - minLon) / 4;
    map.fitBounds([
      [minLat - latBuffer, minLon - longBuffer],
      [maxLat + latBuffer, maxLon + longBuffer],
    ]);
  }, []);
  return null;
};

type Plottable = {
  lat: number;
  lon: number;
  street_address: string;
  city: string;
  zip: string;
  state: string;
  tiv: number;
};

export const addressesToPlottable = (addresses: RawAddress[]) => {
  return addresses
    .map(
      ({
        lat: rawLat,
        long: rawLong,
        street_address,
        city,
        zip,
        state,
        tiv,
      }): Plottable | undefined => {
        if (rawLat && rawLong && street_address && city && state && zip) {
          const lat = parseFloat(rawLat);
          const long = parseFloat(rawLong);
          if (isNaN(lat) || isNaN(long)) {
            return undefined;
          }
          return {
            lat,
            lon: long,
            street_address,
            city,
            state,
            zip,
            tiv,
          };
        }
        return undefined;
      }
    )
    .filter((a) => a !== undefined);
};

export const MapModal = ({
  specialtyPropertyInfo,
  visible,
  onHide,
}: {
  specialtyPropertyInfo: SafeReportResponse;
  visible: boolean;
  onHide: () => void;
}) => {
  const data = useMemo(() => {
    return addressesToPlottable(
      specialtyPropertyInfo.report_json.sovs
        .filter((sov) => sov.is_enabled)
        .flatMap((sov) => sov.addresses)
    );
  }, [specialtyPropertyInfo]);

  const tiv = useMemo(() => data.reduce((a, b) => a + b.tiv, 0.0), [data]);

  const { minLat, minLon, maxLat, maxLon } = useMemo(() => {
    let minLat = Infinity;
    let minLon = Infinity;
    let maxLat = -Infinity;
    let maxLon = -Infinity;
    for (const address of data) {
      minLat = Math.min(minLat, address.lat);
      minLon = Math.min(minLon, address.lon);
      maxLat = Math.max(maxLat, address.lat);
      maxLon = Math.max(maxLon, address.lon);
    }

    return {
      minLat,
      minLon,
      maxLat,
      maxLon,
    };
  }, [data]);

  const maxDistance = haversineDistanceMiles(
    [minLon, minLat],
    [maxLon, maxLat]
  );

  const [showClusters, setShowClusters] = useState(false);
  const [riskRadius, setRiskRadius] = useState(
    Math.max(1.0, Math.min(10.0, maxDistance / 20.0))
  );
  const [minTIV, setMinTIV] = useState(
    Math.max(Math.round((tiv * 0.05) / 1_000_000) * 1_000_000, 1_000)
  );
  const [selected, setSelected] = useState<Plottable[]>([]);
  const clusters = useMemo(() => {
    if (showClusters) {
      return findAddressesInRadius(data, riskRadius);
    }
    return undefined;
  }, [showClusters, data, riskRadius]);
  const maxClusterTiv = clusters?.reduce((a, b) => Math.max(a, b.tiv), 0.0);
  const windowSize = useWindowSize();

  const onClick = (address: Plottable) => {
    setSelected((selected) => {
      if (selected.includes(address)) {
        return [];
      }

      if (selected.length === 2) {
        return [selected[1], address];
      }

      return selected.concat([address]);
    });
  };

  return (
    <Dialog
      header={`${specialtyPropertyInfo.report_json.company_info.company_name} Addresses`}
      visible={visible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={onHide}
    >
      {data.length === 0 ? (
        "No geographical data found"
      ) : (
        <div className={styles.content}>
          <MapContainer
            zoom={3}
            boundsOptions={{}}
            className={styles.map}
            style={{ height: windowSize[1] - 225 }}
          >
            <SetBounds
              minLat={minLat}
              minLon={minLon}
              maxLat={maxLat}
              maxLon={maxLon}
            />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></TileLayer>
            {data.map((address, i) => {
              return (
                <Marker
                  key={i}
                  position={[address.lat, address.lon]}
                  eventHandlers={{ click: () => onClick(address) }}
                  opacity={
                    selected.length > 0
                      ? selected.includes(address)
                        ? 1.0
                        : 0.5
                      : 1.0
                  }
                >
                  <Popup>
                    Address: {address.street_address}
                    <br />
                    TIV: <AbbreviatedMoney dollars={address.tiv} />
                  </Popup>
                </Marker>
              );
            })}
            {selected.length >= 2 ? (
              <>
                <Polyline positions={selected.map((a) => [a.lat, a.lon])} />
                <Popup position={averageCoordinate(selected)}>
                  {haversineDistanceMilesString(selected[0], selected[1])} miles
                </Popup>
              </>
            ) : null}
            {clusters
              ?.filter((c) => c.tiv >= minTIV)
              .map((c) => (
                <Cluster cluster={c} maxClusterTiv={maxClusterTiv ?? 0} />
              ))}
          </MapContainer>
          <div className={styles.controls}>
            <div className={styles.row}>
              <InputSwitch
                checked={showClusters}
                onChange={() => setShowClusters((v) => !v)}
              />
              Show risk clusters
            </div>
            <div className={styles.row}>
              <div className={styles.slider}>
                <Slider
                  min={0.5}
                  max={Math.max(Math.round(maxDistance / 5), 5.0)}
                  value={riskRadius}
                  step={0.1}
                  onChange={(e) => setRiskRadius(e.value as number)}
                />
              </div>
              Radius ({riskRadius} mi)
            </div>
            <div className={styles.row}>
              <div className={styles.slider}>
                <Slider
                  min={tiv * 0.001}
                  max={tiv}
                  value={minTIV}
                  onChange={(e) => setMinTIV(e.value as number)}
                />
              </div>
              TIV (<AbbreviatedMoney dollars={minTIV} />)
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};
