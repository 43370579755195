import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  UpdateAddresses,
  UpdateReport,
  deleteAdditionalFile,
  listAdditionalFiles,
  listReport,
  listReports,
  listUsers,
  searchAddresses,
  updateAddresses,
  updateReport,
  uploadAdditionalFile,
  validateGoogleSheetsUrl,
} from "../../api/routes";
import * as client from "../../client";
import {
  applyOverridesToAddress,
  applyOverridesToResponse,
} from "./Conversion";
import { mapValues } from "lodash";
import { AxiosProgressEvent } from "axios";

export const useReports = () => {
  return useQuery({
    queryKey: ["reports"],
    queryFn: async () => {
      return await listReports();
    },
  });
};

export const useReport = (reportId?: string) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["reports", reportId],
    queryFn: async () => {
      return reportId ? await listReport(reportId) : undefined;
    },
  });
};

export const useSafeReport = (reportId: string) => {
  return useQuery({
    queryKey: ["reports", reportId],
    queryFn: async () => {
      return applyOverridesToResponse(await listReport(reportId));
    },
  });
};

export const useUpdateAddresses = (reportId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["update-addresses", reportId],
    mutationFn: async (update: UpdateAddresses) => {
      return await updateAddresses(reportId, update);
    },
    onSuccess: (data) => {
      const prev = queryClient.getQueryData<client.ReportResponse>([
        "reports",
        reportId,
      ]);

      const updates = Object.fromEntries(
        data.addresses.map((a) => [a.address_id, a])
      );
      if (prev) {
        queryClient.setQueryData<client.ReportResponse>(["reports", reportId], {
          ...prev,
          report_json: {
            ...prev.report_json,
            sovs: prev.report_json.sovs.map((sov) => ({
              ...sov,
              addresses: sov.addresses.map((a) => updates[a.address_id] ?? a),
            })),
          },
        });
      }
    },
  });
};

export const useUpdateReport = (reportId: string) => {
  const key = ["reports", reportId];
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: key,
    mutationFn: async (update: UpdateReport) => {
      return await updateReport(reportId, update);
    },
    onSuccess: (data, update) => {
      // invalidate prefix matches against queryKeys
      // currently, only assignee and status affect the list view
      if (update.assignee || update.status) {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
      }
      queryClient.setQueryData(["reports", reportId], data);
    },
  });
};

export const useUsers = (clientId?: string | null) => {
  if (clientId === "proper") {
    return undefined;
  }
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return await listUsers();
    },
  });
};

export const useUserById = (
  userId: string | undefined | null
): client.User | undefined => {
  const users = useUsers();
  if (!userId) return undefined;
  return (users?.data?.all_company_users ?? []).find((u) => u.id === userId);
};

export const useAddressSearch = (query: string, terms: client.TermSearch[]) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["search-addresses", query, ...terms],
    queryFn: async () => {
      const response =
        query || terms.length > 0
          ? await searchAddresses(query, terms)
          : { by_report: {}, term_query: [] };

      return {
        by_report: mapValues(response.by_report, (addresses) =>
          addresses.map(applyOverridesToAddress)
        ),
      };
    },
  });
};

export const useAdditionalFiles = (
  reportId: string,
  enabled: boolean = true
) => {
  return useQuery({
    queryKey: ["report", "additional-files", reportId],
    queryFn: () => listAdditionalFiles(reportId),
    enabled,
  });
};

export const useUploadAdditionalFiles = (
  reportId: string,
  onProgress: (e: AxiosProgressEvent) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["report", "upload-additional-file", reportId],
    mutationFn: (files: File[]) =>
      uploadAdditionalFile(reportId, files, onProgress),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["report", "additional-files", reportId],
        response
      );
    },
  });
};

export const useDeleteAdditionalFile = (reportId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["report", "delete-additional-file", reportId],
    mutationFn: (names: string[]) => deleteAdditionalFile(reportId, names),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["report", "additional-files", reportId],
        response
      );
    },
  });
};

export const useGoogleSheetPreview = (sheetUrl: string) => {
  return useQuery({
    queryKey: ["google-sheet-preview", sheetUrl],
    queryFn: () => validateGoogleSheetsUrl(sheetUrl),
    enabled: sheetUrl.length > 0,
    refetchOnWindowFocus: false,
  });
};
