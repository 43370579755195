import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import styles from "./GoogleSheetsModal.module.css";
import { sendInfoToGoogleSheets } from "../../../api/routes";
import { Toast } from "primereact/toast";
import { useGoogleSheetPreview } from "../data";
import * as client from "../../../client";
import { useLogUIEventCallback } from "../../../amplitude";

const googleSheetErrorMessage = (
  error: client.ValidateUrlResponse["error"]
): string => {
  switch (error) {
    case "invalid_url":
      return "This isn't a valid Google Sheets URL.";
    case "no_permission":
      return "Pantheon doesn't have access to that spreadsheet. Check that this spreadsheet is in a drive shared with pantheon.";
    default:
      return "Pantheon can't access this spreadsheet";
  }
};

const GoogleSheetValidationResult = ({ url }: { url: string }) => {
  const sheetPreview = useGoogleSheetPreview(url);

  const okMessage = sheetPreview.data?.data
    ? `Will send to "${sheetPreview.data.data.title}"`
    : null;
  const errMessage = sheetPreview.data?.error
    ? googleSheetErrorMessage(sheetPreview.data.error)
    : null;

  return (
    <div className={styles.validationResult}>
      {!url ? (
        <></>
      ) : sheetPreview.isPending ? (
        <>Checking url...</>
      ) : okMessage ? (
        <>
          <span
            className="pi pi-check"
            style={{ color: "var(--green-500)", marginBottom: "3px" }}
          ></span>
          {okMessage}
        </>
      ) : errMessage ? (
        <>
          <span
            className="pi pi-times"
            style={{ color: "var(--red-500)", marginBottom: "3px" }}
          ></span>
          {errMessage}
        </>
      ) : null}
    </div>
  );
};

export const GoogleSheetsModal = ({
  visible,
  setVisible,
  headerText,
  reportId,
  sendAddresses,
  sendGeneralInput,
}: {
  visible: boolean;
  setVisible: (value: boolean) => void;
  headerText: string;
  reportId: string;
  sendAddresses?: boolean;
  sendGeneralInput?: boolean;
}) => {
  const [gsheetUrl, setGsheetUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const logUIEvent = useLogUIEventCallback();

  return (
    <Dialog
      header={headerText}
      visible={visible}
      style={{
        width: "600px",
        height: "300px",
      }}
      onHide={() => setVisible(false)}
    >
      <Toast ref={toast} />
      <div className={styles.verticalContainer}>
        <div className={styles.inputSection}>
          <InputText
            type="text"
            placeholder="Enter Google Sheets URL"
            onChange={(e) => setGsheetUrl(e.target.value)}
            value={gsheetUrl}
          />
          <Button
            loading={isLoading}
            label="Populate"
            disabled={gsheetUrl === ""}
            onClick={async () => {
              setIsLoading(true);
              logUIEvent("acropolis_click_send_google_sheets_info", {
                send_addresses: sendAddresses,
                send_general_input: sendGeneralInput,
              });
              const success = await sendInfoToGoogleSheets({
                reportId: reportId,
                sheetUrl: gsheetUrl,
                sendAddresses: sendAddresses,
                sendGeneralInput: sendGeneralInput,
              });
              setIsLoading(false);
              if (success) {
                toast.current?.show({
                  severity: "success",
                  summary: "Data Sent Successfully",
                  detail: "The data should now be in your google sheet!",
                  life: 5000,
                });
              } else {
                toast.current?.show({
                  severity: "warn",
                  summary: "Failed to Send Data",
                  detail: "Failed to send data to the google sheet.",
                  life: 5000,
                });
              }
            }}
          />
        </div>
        <GoogleSheetValidationResult url={gsheetUrl} />
      </div>
    </Dialog>
  );
};
