import { Dialog } from "primereact/dialog";
import styles from "./BulkEditModal.module.css";
import { useWindowSize } from "../../../util";
import * as client from "../../../client";

export type StreetViewModalState = { data: client.RawAddress } | undefined;

const streetViewUrl = ({ data }: NonNullable<StreetViewModalState>) => {
  const location = `${data.lat},${data.long}`;
  return `https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCDmbcWJmgO1__7nF7mLIm9D2s-WbCCdtg&location=${location}&pitch=10&fov=35`;
};

export const StreetViewModal = ({
  state,
  onHide,
  isLightMode,
}: {
  state: StreetViewModalState;
  onHide: () => void;
  isLightMode: boolean;
}) => {
  const windowSize = useWindowSize();

  const modeClass = isLightMode ? styles.light : styles.dark;
  void modeClass;

  return (
    <Dialog
      visible={!!state}
      onHide={onHide}
      header="Street View"
      draggable={false}
    >
      {state?.data.street_address && state?.data.location_description ? (
        <>
          "{state.data.street_address}" described as "
          {state?.data.location_description}"
        </>
      ) : null}
      {!!state && (
        <iframe
          width={windowSize[0] - 200}
          height={windowSize[1] - 250}
          src={streetViewUrl(state)}
        ></iframe>
      )}
    </Dialog>
  );
};
