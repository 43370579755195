export const BUILDING_DESCRIPTIONS = [
  "Boarding/Lodging/Rooming Houses, Fraternities, Sororities, Dorms -- <10 Units",
  "Boarding/Lodging/Rooming Houses, Fraternities, Sororities, Dorms -- 11-30 Units",
  "Boarding/Lodging/Rooming Houses, Fraternities, Sororities, Dorms -- 30+ Units",
  "Convents, Monasteries/Rectories, Orphan Homes, Nurses'/Sisters' Homes -- <10 Units",
  "Convents, Monasteries/Rectories, Orphan Homes, Nurses'/Sisters' Homes -- 11-30 Units",
  "Convents, Monasteries/Rectories, Orphan Homes, Nurses'/Sisters' Homes -- 30+ Units",
  "1 Family Dwellings (Lessor's Risk)",
  "2 Family Dwellings (Lessor's Risk)",
  "3 or 4 Family Dwellings (Lessor's Risk)",
  "Apartments w/o Merc Occupancy -- <10 Units",
  "Apartments w/o Merc Occupancy -- 11-30 Units",
  "Apartments w/o Merc Occupancy -- 30+ Units",
  "Apartments w/ Merc Occupancy -- <10 Units",
  "Apartments w/ Merc Occupancy -- 11-30 Units",
  "Apartments w/ Merc Occupancy -- 30+ Units",
  "Residential Condos w/o Merc Occupancy -- <10 Units",
  "Residential Condos w/o Merc Occupancy -- 11-30 Units",
  "Residential Condos w/o Merc Occupancy -- 30+ Units",
  "Residential Condos w/ Merc Occupancy -- <10 Units",
  "Residential Condos w/ Merc Occupancy -- 11-30 Units",
  "Residential Condos w/ Merc Occupancy -- 30+ Units",
  "Merc -- Sole Occupancy -- Classification Not Listed -- Low Susceptibility",
  "Merc -- Sole Occupancy -- Tire/Battery/Accessory Dealers w/out Tire Recapping/Vulcanizing",
  "Merc -- Sole Occupancy -- Wearing Apparel/Textiles/Shoes",
  "Merc -- Sole Occupancy -- Alcohol (Not Bars)",
  "Merc -- Sole Occupancy -- Food Products Sales Only (Retail Bakeries, Non-Alcoholic Beverages)",
  "Merc -- Sole Occupancy -- Baking on Premises, No Delivery to Outlets",
  "Merc -- Sole Occupancy -- Food Products (Limited Cooking, Excluding Bakeries)",
  "Merc -- Sole Occupancy -- Bars/Taverns",
  "Merc -- Sole Occupancy -- Restaurants w/ Limited Cooking",
  "Merc -- Sole Occupancy -- Restaurants w/ Full Cooking",
  "Merc -- Sole Occupancy -- Motor Vehicles, No Repair",
  "Merc -- Sole Occupancy -- Boat/Marine Supply Dealers",
  "Merc -- Sole Occupancy -- Drugs",
  "Merc -- Sole Occupancy -- Electrical Goods/Hardware/Machinery",
  "Merc -- Sole Occupancy -- Furniture/Home Furnishings (Not Appliances)",
  "Merc -- Sole Occupancy -- Jewelry",
  "Merc -- Sole Occupancy -- Sporting Goods",
  "Merc -- Sole Occupancy -- Classification Not Listed -- Moderate Susceptibility",
  "Merc -- Sole Occupancy -- Classification Not Listed -- High Susceptibility",
  "Greenhouses -- Sole Occupancy",
  "Merc -- Multiple Occupancy w/o 0564 Occupant",
  "Merc -- Multiple Occupancy w/ 0564 Occupant",
  "Government Offices",
  "Banks/Offices other than Governmental",
  "Motels/Hotels w/ Limited Cooking Restaurant -- <10 Units",
  "Motels/Hotels w/ Limited Cooking Restaurant -- 11-30 Units",
  "Motels/Hotels w/ Limited Cooking Restaurant -- 30+ Units",
  "Motels/Hotels w/o Restaurant -- <10 Units",
  "Motels/Hotels w/o Restaurant -- 11-30 Units",
  "Motels/Hotels w/o Restaurant -- 30+ Units",
  "Golf/Tennis and Similar Sport Facilities w/ Limited Cooking",
  "Golf/Tennis and Similar Sport Facilities w/o Cooking",
  "Clubs (Other Including Fraternal and Union Halls)",
  "Motion Picture Studios",
  "Theaters Excluding Drive-in Theaters",
  "Drive-in Theaters",
  "Skating/Roller Rinks",
  "Bowling Alleys w/o Cooking",
  "Halls/Auditoriums",
  "Recreational Facilities (Other)",
  "Boys' and Girls' Camps",
  "Dance Halls/Ballrooms/Discotheques",
  "Hospitals",
  "Nursing/Convalescent Homes",
  "Churches/Synagogues",
  "Dry Cleaners/Dyeing Plants (Not Self-Service)",
  "Laundries (Not Self-Service)",
  "Self-Service Laundries/Dry Cleaners",
  "Light Hazard Service Occupancies",
  "Service Occupancies (Not Light Hazard)",
  "Funeral Homes",
  "Auto Parking Garages/Car Washes",
  "Gasoline Service Stations",
  "Motor Vehicle/Aircraft Repair (Can Include Sales)",
  "Tire Recapping/Vulcanizing (Can Include Sales)",
  "Aircraft Hangars w/o Repair",
  "Gambling Casinos w/ Limited Cooking",
  "Gambling Casinos w/o Cooking",
  "Penal Institutions",
  "Museums/Libraries/Art Galleries (Non-Profit)",
  "Schools/Academic",
  "Fire Departments/Police/Sewage/Water Works/Similar Public Buildings",
  "Builders' Risk",
  "Freight Terminals",
  "General Storage Warehouses (Bailee)",
  "Misc Product Storage -- (Not Retail/Wholesale/Cold Storage)",
  "Household Goods Storage",
  "Cold Storage Warehouses",
  "Waste/Reclaimed Materials Including Yards",
  "Building Supply Yards (Includs Retail Lumberyards, Coal/Coke Yards)",
  "Mill Yards",
  "Oil Distributing/Terminals, LPG Tank Farms (Includes Stock)",
  "Oil Distributing/Terminals, LPG Tank Farms (Excludes Stock)",
  "Baking on Premises, Delivery to Outlets",
  "Beverage Bottlers (Non Alcoholic)",
  "Distilleries/Wineries",
  "Textile Mill Products",
  "Leather/Leather Products",
  "Printing",
];
