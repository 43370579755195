import { Avatar } from "primereact/avatar";
import * as Token from "../../api/auth_token";
import { Tooltip } from "primereact/tooltip";
import styles from "./Avatar.module.css";
import classNames from "classnames";
import * as jdenticon from "jdenticon";
import { useRef } from "react";
import { Menu } from "primereact/menu";
import { v4 as uuid } from "uuid";

type PantheonAvatarProps = {
  /* If label is undefined, a question mark for "unassigned" will be displayed */
  label?: string;
  size?: "small" | "large";
  withMenu?: boolean;
};

// It's not enough to useMemo these because many use cases for Avatars get
// unmounted and remounted frequently, and these URls take several ms to
// generate.
const DATA_URL_CACHE: { [val: string]: string | undefined } = {};
export const generateIdenticonDataUrl = (val: string): string | undefined => {
  if (!DATA_URL_CACHE[val]) {
    // Try changes at https://jdenticon.com/icon-designer.html?config=ffffffff10c32843234c1840
    const jdenticonConfig = {
      hues: [195],
      lightness: {
        color: [0.35, 0.77],
        grayscale: [0.25, 0.65],
      },
      saturation: {
        color: 0.4,
        grayscale: 0.68,
      },
      backColor: "#fff",
    };
    const canvas = document.createElement("canvas");
    canvas.width = canvas.height = 64;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      jdenticon.drawIcon(ctx, val, 64, jdenticonConfig);
      DATA_URL_CACHE[val] = canvas.toDataURL();
    } else {
      DATA_URL_CACHE[val] = undefined;
    }
  }
  return DATA_URL_CACHE[val];
};

export const PantheonAvatar: React.FC<PantheonAvatarProps> = ({
  label,
  size = "small",
  withMenu = false,
}) => {
  const ref = useRef<Menu>(null);
  const image =
    label !== undefined ? generateIdenticonDataUrl(label) : undefined;
  const tooltipId = useRef(`tooltip-${uuid()}`);

  return (
    <>
      <Tooltip target={`#${tooltipId.current}`} position="left" />
      {withMenu && (
        <Menu
          model={[
            {
              label: "Log out",
              icon: "pi pi-eject",
              command: () => {
                Token.setToken(null);
                window.location.reload();
              },
            },
          ]}
          popup
          ref={ref}
        />
      )}
      <Avatar
        shape="circle"
        className={classNames(
          styles.avatar,
          size === "small" ? styles.avatarSmall : styles.avatarLarge
        )}
        icon="pi pi-question-circle"
        image={image}
        data-pr-tooltip={label ?? "Unassigned"}
        data-pr-showdelay={500}
        onClick={(e) => ref.current?.toggle(e)}
        id={tooltipId.current}
      />
    </>
  );
};
