import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import * as client from "../client";
import { buildChannel } from "../util";
import { logEvent } from "../amplitude";
import * as Token from "./auth_token";
import { ROUTES } from "../routes";
export * as apiTypes from "../client/types.gen";
import { ListingObject, ListingType, NearestAirbnb } from "./types";
import { displayNameForListingType } from "../pages/uw-assistant/ListingIdForm";

const BASE_URL_DEV = "http://127.0.0.1:8000";
const BASE_URL_DEV_VM = "https://backend-dev.pantheonai.co";
const BASE_URL_CANARY = "https://pantheonai-canary-backend.co";
const BASE_URL_PROD = "https://backend.pantheonai.co";
const BASE_URL_XENIA = "https://proper-demo.com";

const baseUrl = () => {
  const channel = buildChannel();
  switch (channel) {
    case "prod":
      return BASE_URL_PROD;
    case "canary":
      return BASE_URL_CANARY;
    case "dev":
      return BASE_URL_DEV;
    case "dev-vm":
      return BASE_URL_DEV_VM;
    case "xenia":
      return BASE_URL_XENIA;
  }
};

client.OpenAPI.BASE = baseUrl();
client.OpenAPI.interceptors.request.use((request) => {
  const token = Token.getToken();
  if (token) {
    if (request.headers === undefined) {
      request.headers = {};
    }
    request.headers["Authorization"] = `Bearer ${token}`;
  }

  return request;
});

client.OpenAPI.interceptors.response.use((response) => {
  if (response.status === 401 && !response.config.url?.endsWith("login")) {
    Token.setToken(null);
    window.location.href = ROUTES.SIGN_IN;
  }
  return response;
});

let URL_PROGRESS_CALLBACKS: [string, (e: AxiosProgressEvent) => void][] = [];

client.OpenAPI.interceptors.request.use((request) => {
  request.onUploadProgress = (e: AxiosProgressEvent) => {
    for (const [url, cb] of URL_PROGRESS_CALLBACKS) {
      if (request.url?.startsWith(url)) {
        cb(e);
      }
    }
  };
  return request;
});

export const login = async (
  username: string,
  password: string
): Promise<
  | { success: true; clientId: string }
  | { success: false; failureReason: string }
> => {
  return await client
    .loginLoginPost({ formData: { username, password } })
    .then((r) => {
      Token.setToken(r.access_token);
      return { success: true as const, clientId: r.client_id };
    })
    .catch((rawE: client.ApiError) => {
      if (rawE.status === 422) {
        const e = rawE as client.HttpValidationError;
        console.error("login failed: ", e.detail);
      }
      return { success: false, failureReason: "unknown" };
    });
};

type ListingResponse = {
  arrayBuffer: ArrayBuffer | undefined;
  imageFailure: boolean;
  totalFailure: boolean;
  imageFailuresForListingToday: number;
  totalFailuresForListingToday: number;
  listingTypeDisplayName: string;
};

const listingResponseFromArrayBufferAndHeaders = (
  arrayBuffer: ArrayBuffer | undefined,
  response: AxiosResponse,
  listingTypeDisplayName: string
) => {
  return {
    arrayBuffer: arrayBuffer,
    imageFailure: response.headers["x-listing-type-image-failure"] !== "0",
    totalFailure: response.headers["x-listing-type-total-failure"] !== "0",
    imageFailuresForListingToday: Number(
      response.headers["x-listing-type-image-failures-today"]
    ),
    totalFailuresForListingToday: Number(
      response.headers["x-listing-type-total-failures-today"]
    ),
    listingTypeDisplayName: listingTypeDisplayName,
  };
};

const xeniaHeaders = (): { [header: string]: string } => {
  const token = Token.getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const generatePdfFromListingObject = async (
  listingObject: ListingObject,
  isRenewal: boolean
): Promise<ListingResponse | undefined> => {
  const endpointUrl = baseUrl() + "/listing_pdf";
  const numListings = Object.values(listingObject).filter(
    (value) => value !== ""
  ).length;

  logEvent("start_fetch_listing", {
    listing_object: listingObject,
    num_listings: numListings,
    is_renewal: isRenewal,
  });
  const startTime = Date.now();
  return axios
    .post(
      endpointUrl,
      {
        listing_type_ids: listingObject,
        is_renewal: isRenewal,
      },
      {
        responseType: "arraybuffer",
        headers: xeniaHeaders(),
      }
    )
    .then((response) => {
      let arrayBuffer: ArrayBuffer | undefined;
      if (response.status === 200) {
        logEvent("finish_fetch_listing", {
          time_taken: Date.now() - startTime,
          listing_object: listingObject,
          num_listings: numListings,
          is_renewal: isRenewal,
        });
        arrayBuffer = response.data;
      }

      if (response.status === 200 || response.status === 204) {
        let firstListingType: ListingType = "airbnb";
        if (listingObject.evolve !== "") {
          firstListingType = "evolve";
        } else if (listingObject.furnished_finder !== "") {
          firstListingType = "furnished_finder";
        } else if (listingObject.vrbo !== "") {
          firstListingType = "vrbo";
        }

        return listingResponseFromArrayBufferAndHeaders(
          arrayBuffer,
          response,
          displayNameForListingType(firstListingType)
        );
      } else {
        throw new Error(
          "Fetch listing API request failed with status: " + response.status
        );
      }
    })
    .catch((error) => {
      console.error("Fetch listing API request failed:", error);
      return undefined;
    });
};

const getDomain = (url: string): string => {
  // Use the URL object to parse the URL
  const domain = new URL(url).hostname;

  // Split the hostname by '.' and return the second last part (e.g. 'airbnb' from 'www.airbnb.com')
  const parts = domain.split(".");
  return parts.length > 1 ? parts[parts.length - 2] : domain;
};

export const generatePdfFromPropertyUrls = async (
  urls: string[],
  isRenewal: boolean
): Promise<ListingResponse | undefined> => {
  const endpointUrl = baseUrl() + "/generate_str_pdf_from_urls";

  logEvent("start_fetch_listing", { urls: urls, is_renewal: isRenewal });
  const startTime = Date.now();
  return axios
    .post(
      endpointUrl,
      { urls: urls, is_renewal: isRenewal },
      {
        responseType: "arraybuffer",
        headers: xeniaHeaders(),
      }
    )
    .then((response) => {
      let arrayBuffer: ArrayBuffer | undefined;
      if (response.status === 200) {
        logEvent("finish_fetch_listing", {
          time_taken: Date.now() - startTime,
          urls: urls,
          is_renewal: isRenewal,
        });
        arrayBuffer = response.data;
      }

      if (response.status === 200 || response.status === 204) {
        return listingResponseFromArrayBufferAndHeaders(
          arrayBuffer,
          response,
          getDomain(urls[0])
        );
      } else {
        throw new Error(
          "Fetch listing API request failed with status: " + response.status
        );
      }
    })
    .catch((error) => {
      console.error("Fetch listing API request failed:", error);
      return undefined;
    });
};

export const getNearestAirbnb = async (
  address: string
): Promise<NearestAirbnb | undefined> => {
  // TODO: verify address is a reasonable format
  const endpointUrl = baseUrl() + "/nearest_airbnb/" + address;

  logEvent("start_fetch_nearest_airbnb");
  const startTime = Date.now();
  return axios
    .get(endpointUrl)
    .then((response) => {
      if (response.status === 200) {
        logEvent("finish_fetch_nearest_airbnb", {
          time_taken: Date.now() - startTime,
        });
        const { listing_id, distance_text } = response.data;
        return { listingId: listing_id, distanceText: distance_text };
      } else {
        throw new Error(
          "Nearest airbnb API request failed with status: " + response.status
        );
      }
    })
    .catch((error) => {
      console.error("Nearest airbnb API request failed:", error);
      return undefined;
    });
};

export const logAmplitudeEventRemotely = async (
  eventName: string,
  eventProperties: {
    [key: string]: string | number;
  }
) => {
  return client.logEventLogEventPost({
    requestBody: { event_name: eventName, event_properties: eventProperties },
  });
};

export const continueCommercialConversation = async (
  reportId: string,
  messages: client.Message[]
): Promise<client.Message[] | undefined> => {
  return client
    .continueCommercialConversationContinueCommercialConversationPost({
      requestBody: {
        messages,
        report_id: reportId,
      },
    })
    .then((response) => response.all_messages)
    .catch((error) => {
      console.error(
        "Continue commercial conversation API request failed:",
        error
      );
      return undefined;
    });
};

export const listReports = async (): Promise<
  client.ReportMetadataResponse[]
> => {
  return client
    .acropolisListReportsAcropolisListReportsGet()
    .then((response) => response.reports);
};

export const listReport = async (reportId: string) => {
  return client.acropolisListReportAcropolisListReportReportIdGet({ reportId });
};

export type UpdateReport = {
  assignee?: client.UpdateAsignee;
  status?: client.UpdateStatus;
  sovs?: client.Sov[];
  lossRunInfo?: client.LossRunInfo;
  humanReviewQuestion?: client.HumanReviewQuestion;
  companyInfo?: client.CompanyInfo;
  rerunReport?: boolean;
};
export const updateReport = async (
  reportId: string,
  updates: UpdateReport
): Promise<client.ReportResponse> => {
  return await client.acropolisUpdateReportAcropolisUpdateReportPost({
    requestBody: {
      report_id: reportId,
      update_assignee: updates.assignee ?? null,
      update_status: updates.status ?? null,
      update_sovs: updates.sovs ?? null,
      update_loss_runs_info: updates.lossRunInfo ?? null,
      update_human_review_question: updates.humanReviewQuestion ?? null,
      update_company_info: updates.companyInfo ?? null,
      rerun_report: updates.rerunReport ?? null,
    },
  });
};

export type UpdateAddresses = {
  addresses: client.Address[];
};
export const updateAddresses = async (
  report_id: string,
  update: UpdateAddresses
): Promise<client.UpdateAddressResponse> => {
  return await client.acropolisUpdateAddressAcropolisUpdateAddressPost({
    requestBody: { report_id, addresses: update.addresses },
  });
};

export type SendGoogleSheetsInfoRequest = {
  reportId: string;
  sheetUrl: string;
  sendAddresses?: boolean;
  sendGeneralInput?: boolean;
};

export const sendInfoToGoogleSheets = async (
  request: SendGoogleSheetsInfoRequest
) => {
  try {
    await client.acropolisSendInfoToGoogleSheetsAcropolisSendInfoToGoogleSheetsPost(
      {
        requestBody: {
          google_sheet_url: request.sheetUrl,
          report_id: request.reportId,
          send_addresses: request.sendAddresses ?? null,
          send_general_input: request.sendGeneralInput ?? null,
        },
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const validateGoogleSheetsUrl = async (url: string) => {
  return await client.acropolisValidateUrlAcropolisValidateUrlPost({
    requestBody: {
      url,
    },
  });
};

export const downloadSov = async (reportId: string, format: string) => {
  const endpointUrl = `${baseUrl()}/acropolis/download_sov`;
  return axios
    .post(
      endpointUrl,
      { report_id: reportId, format: format },
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${Token.getToken()}`,
        },
      }
    )
    .then((response) => {
      const arrayBuffer: ArrayBuffer = response.data;
      const blob = new Blob([arrayBuffer], {
        type: "application/octet-stream",
      });
      return blob;
    });
};

export const downloadLossRun = async (
  parsedLossRuns: client.UserGeneratedLossRunByYear[]
) => {
  const endpointUrl = `${baseUrl()}/acropolis/download_loss_run`;
  return axios
    .post(
      endpointUrl,
      { loss_run_years: parsedLossRuns },
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${Token.getToken()}`,
        },
      }
    )
    .then((response) => {
      const arrayBuffer: ArrayBuffer = response.data;
      const blob = new Blob([arrayBuffer], {
        type: "application/octet-stream",
      });
      return blob;
    });
};

export const uploadFilesAdonis = async (
  files: File[],
  onUploadProgress: (e: AxiosProgressEvent) => void,
  onError: () => void
) => {
  // Extra function is used to ensure a unique object identity for each callback.
  const callback = (e: AxiosProgressEvent) => onUploadProgress(e);
  URL_PROGRESS_CALLBACKS.push([
    `${client.OpenAPI.BASE}/adonis/upload_files`,
    callback,
  ]);

  try {
    return await client.adonisUploadFilesAdonisUploadFilesPost({
      formData: { files },
    });
  } catch (error) {
    console.error(error);
    if (error instanceof client.ApiError && error.status === 400) {
      onError();
    }
  } finally {
    URL_PROGRESS_CALLBACKS = URL_PROGRESS_CALLBACKS.filter(
      ([, cb]) => cb != callback
    );
  }
};

export const listBatchesAdonis = async (): Promise<client.RenewalBatch[]> => {
  return client
    .adonisListBatchesAdonisListBatchesGet()
    .then((response) => response.batches);
};

export const downloadPdfZip = async (folderName: string) => {
  // TODO: error handling, and figure out if our client
  // can handle this sort of type
  const endpointUrl = `${baseUrl()}/adonis/download_pdfs?folder_name=${folderName}`;
  return axios
    .get(endpointUrl, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${Token.getToken()}`,
      },
    })
    .then((response) => {
      const arrayBuffer: ArrayBuffer = response.data;
      const blob = new Blob([arrayBuffer], {
        type: "application/octet-stream",
      });
      return blob;
    });
};

export const listUsers = async () => {
  return await client.listUsersListUsersPost();
};

export const searchAddresses = async (
  query: string,
  terms: client.TermSearch[]
) => {
  return await client.acropolisSearchReportAddressesAcropolisSearchReportAddressesPost(
    { requestBody: { raw_query: query, term_query: terms } }
  );
};

export const listAdditionalFiles = async (
  reportId: string
): Promise<string[]> => {
  return (
    await client.acropolisListAdditionalFilesAcropolisListAdditionalFilesReportIdPost(
      { reportId }
    )
  ).files;
};

export const uploadAdditionalFile = async (
  reportId: string,
  files: File[],
  onUploadProgress: (e: AxiosProgressEvent) => void
): Promise<string[]> => {
  const callback = (e: AxiosProgressEvent) => onUploadProgress(e);
  URL_PROGRESS_CALLBACKS.push([
    `${client.OpenAPI.BASE}/acropolis/upload_additional_file`,
    callback,
  ]);
  try {
    return (
      await client.acropolisUploadAdditionalFileAcropolisUploadAdditionalFilesReportIdPost(
        { reportId, formData: { files } }
      )
    ).files;
  } finally {
    URL_PROGRESS_CALLBACKS = URL_PROGRESS_CALLBACKS.filter(
      ([, cb]) => cb != callback
    );
  }
};

export const deleteAdditionalFile = async (
  reportId: string,
  files: string[]
): Promise<string[]> => {
  return (
    await client.acropolisDeleteAdditionalFilesAcropolisDeleteAdditionalFilesReportIdPost(
      { reportId, requestBody: { files } }
    )
  ).files;
};

export const downloadAdditionalFile = async (
  reportId: string,
  file: string
) => {
  const endpointUrl = `${baseUrl()}/acropolis/download_additional_file`;
  return axios
    .post(
      endpointUrl,
      { report_id: reportId, file },
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${Token.getToken()}`,
        },
      }
    )
    .then((response) => {
      const arrayBuffer: ArrayBuffer = response.data;
      const blob = new Blob([arrayBuffer], {
        type: "application/octet-stream",
      });
      return blob;
    });
};

export const generateBulkEdit = async (
  reportId: string,
  field: string,
  instructions: string
): Promise<[client.Address[], client.Address[]]> => {
  const response = await client.generateBulkEditAcropolisGenerateBulkEditPost({
    requestBody: { report_id: reportId, field, instructions },
  });
  return [response.before, response.after];
};

export const downloadReportFileUrl = (reportId: string, filename: string) =>
  `${baseUrl()}/acropolis/download_report_file/${reportId}/${encodeURIComponent(
    filename
  )}`;

export const downloadReportFile = async (
  reportId: string,
  filename: string
) => {
  const endpointUrl = downloadReportFileUrl(reportId, filename);
  return axios
    .get(endpointUrl, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${Token.getToken()}`,
      },
    })
    .then((response) => {
      const arrayBuffer: ArrayBuffer = response.data;
      const headers = response.headers;
      const blob = new Blob([arrayBuffer], {
        type: (
          (typeof headers.get === "function" && headers.get("Content-Type")) ??
          "application/octet-stream"
        ).toString(),
      });
      return blob;
    });
};

export const analyzeCodes = async (codes: string[]) => {
  return await client.dentaAnalyzeCodesDentaAnalyzeCodesPost({
    requestBody: { codes: codes },
  });
};

export const findPossibleCodes = async (message: string) => {
  return await client.dentaFindPossibleCodesDentaFindPossibleCodesPost({
    requestBody: { prodecure_description: message },
  });
};
