export const CODES_WITH_TITLES = [
  "D0120 - Periodic oral evaluation – established patient",
  "D0140 - Limited oral evaluation – problem focused",
  "D0145 - Oral evaluation for a patient under three years of age and counseling with primary caregiver",
  "D0150 - Comprehensive oral evaluation – new or established patient",
  "D0160 - Detailed and extensive oral evaluation - problem focused, by report",
  "D0170 - Re-evaluation - limited, problem focused (established patient, not post-operative visit)",
  "D0171 - Re-evaluation – post-operative office visit",
  "D0180 - Comprehensive periodontal evaluation - new or established patient",
  "D0190 - Screening of a patient",
  "D0191 - Assessment of a patient",
  "D0210 - Intraoral - comprehensive series of radiographic images",
  "D0220 - Intraoral – periapical first radiographic image",
  "D0230 - Intraoral – periapical each additional radiographic image",
  "D0240 - Intraoral - occlusal radiographic image",
  "D0250 - Extra-oral – 2D projection radiographic image created using a stationary radiation source, and detector",
  "D0251 - Extra-oral posterior dental radiographic image",
  "D0270 - Bitewing - single radiographic image",
  "D0272 - Bitewings - two radiographic images",
  "D0273 - Bitewings - three radiographic images",
  "D0274 - Bitewings - four radiographic images",
  "D0277 - Vertical Bitewings – 7 to 8 radiographic images",
  "D0310 - Sialography",
  "D0320 - Temporomandibular joint arthrogram, including injection",
  "D0321 - Other temporomandibular joint radiographic images, by report",
  "D0322 - Tomographic survey",
  "D0330 - Panoramic radiographic image",
  "D0340 - 2D cephalometric radiographic image - acquisition, measurement and analysis",
  "D0350 - 2D oral/facial photographic image obtained intra-orally or extra-orally",
  "D0364 - Cone beam CT capture and interpretation with limited field of view - less than one whole jaw",
  "D0365 - Cone beam CT capture and interpretation with field of view of one full dental arch – mandible",
  "D0366 - Cone beam CT capture and interpretation with field of view one full dental arch – maxilla, with or without cranium",
  "D0367 - Cone beam CT capture and interpretation with field of view of both jaws; with or without cranium",
  "D0368 - Cone beam CT capture and interpretation for TMJ series including two or more exposures",
  "D0369 - Maxillofacial MRI capture and interpretation",
  "D0370 - Maxillofacial ultrasound capture and interpretation",
  "D0371 - Sialoendoscopy capture and interpretation",
  "D0372 - Intraoral tomosynthesis – comprehensive series of radiographic images",
  "D0373 - Intraoral tomosynthesis - bitewing radiographic image",
  "D0374 - Intraoral tomosynthesis - periapical radiographic image",
  "D0380 - Cone beam CT image capture with limited field of view – less than one whole jaw",
  "D0381 - Cone beam CT image capture with field of view of one full dental arch – mandible",
  "D0382 - Cone beam CT image capture with field of view one full dental arch – maxilla, with or without cranium",
  "D0383 - Cone beam CT image capture with field of view of both jaws; with or without cranium",
  "D0384 - Cone beam CT capture image for TMJ series including two or more exposures",
  "D0385 - Maxillofacial MRI image capture",
  "D0386 - Maxillofacial ultrasound image capture",
  "D0387 - Intraoral tomosynthesis – comprehensive series of radiographic images – capture only",
  "D0388 - Intraoral tomosynthesis bitewing –radiographic image - capture only",
  "D0389 - Intraoral tomosynthesis - periapical radiographic image – capture only",
  "D0391 - Interpretation of diagnostic image by a practitioner not associated with capture of the image, including report",
  "D0393 - Virtual treatment simulation using 3D image volume or dental surface scan",
  "D0394 - Digital subtraction of two or more images or image volumes of the same modality",
  "D0395 - Fusion of two or more 3D image volumes of one or more modalities",
  "D0396 - 3D printing of a 3D dental surface scan",
  "D0411 - HbA1c in-office point of service testing",
  "D0412 - Blood glucose level test – in-office using a glucose meter",
  "D0414 - Laboratory processing of microbial specimen to include culture and sensitivity studies, preparation and transmission of written report",
  "D0415 - Collection of microorganisms for culture and sensitivity",
  "D0416 - Viral culture",
  "D0417 - Collection and preparation of saliva sample for laboratory diagnostic testing",
  "D0418 - Analysis of saliva sample",
  "D0419 - Assessment of salivary flow by measurement",
  "D0422 - Collection and preparation of genetic sample material for laboratory analysis and report",
  "D0423 - Genetic test for susceptibility to diseases – specimen analysis",
  "D0425 - Caries susceptibility tests",
  "D0431 - Adjunctive pre-diagnostic test that aids in detection of mucosal abnormalities including premalignant, malignant lesions not to include cytology or biopsy procedures",
  "D0460 - Pulp vitality tests",
  "D0470 - Diagnostic casts",
  "D0472 - Accession of tissue, gross examination",
  "D0473 - Accession of tissue, gross and microscopic examination",
  "D0474 - Accession of tissue, gross and microscopic examination including assessment of surgical margins",
  "D0475 - Decalcification procedure",
  "D0476 - Special stains for microorganisms",
  "D0477 - Special stains, not for microorganisms",
  "D0478 - Immunohistochemical stains",
  "D0479 - Tissue in-situ hybridization, including interpretation",
  "D0480 - Accession of exfoliative cytologic smears",
  "D0481 - Electron microscopy",
  "D0482 - Direct immunofluorescence",
  "D0483 - Indirect immunofluorescence",
  "D0484 - Consultation on slides prepared elsewhere",
  "D0485 - Consultation, including preparation of slides from biopsy material supplied by referring source",
  "D0486 - Laboratory accession of transepithelial cytologic sample",
  "D0502 - Other oral pathology procedures, by report",
  "D0600 - Non-ionizing diagnostic procedure",
  "D0601 - Caries risk assessment and documentation, with a finding of low risk",
  "D0602 - Caries risk assessment and documentation, with a finding of moderate risk",
  "D0603 - Caries risk assessment and documentation, with a finding of high risk",
  "D0604 - Antigen testing for a public health related pathogen",
  "D0605 - Antibody testing for a public health related pathogen",
  "D0606 - Molecular testing for a public health related pathogen",
  "D0701 - Panoramic radiographic image – image capture only",
  "D0702 - 2-D cephalometric radiographic image – image capture only",
  "D0703 - 2-D oral/facial photographic image obtained intra-orally or extra-orally – image capture only",
  "D0705 - Extra-oral posterior dental radiographic image – image capture only",
  "D0706 - Intraoral – occlusal radiographic image – image capture only",
  "D0707 - Intraoral – periapical radiographic image – image capture only",
  "D0708 - Intraoral – bitewing radiographic image – image capture only",
  "D0709 - Intraoral – comprehensive series of radiographic images – image capture only",
  "D0801 - 3D dental surface scan – direct",
  "D0802 - 3D dental surface scan – indirect",
  "D0803 - 3D facial surface scan – direct",
  "D0804 - 3D facial surface scan – indirect",
  "D0999 - Unspecified diagnostic procedure, by report",
  "D1110 - Prophylaxis-adult",
  "D1120 - Prophylaxis - child",
  "D1206 - Topical application of fluoride varnish",
  "D1208 - Topical application of fluoride – excluding varnish",
  "D1301 - Immunization counseling",
  "D1310 - Nutritional counseling for control of dental disease",
  "D1320 - Tobacco counseling for the control and prevention of oral disease",
  "D1321 - Counseling for the control and prevention of adverse oral, behavioral, and systemic health effects associated with high-risk substance use",
  "D1330 - Oral hygiene instructions",
  "D1351 - Sealant - per tooth",
  "D1352 - Preventive resin restoration in a moderate to high caries risk patient - permanent tooth",
  "D1353 - Sealant repair - per tooth",
  "D1354 - Application of caries arresting medicament – per tooth",
  "D1355 - Caries preventive medicament application – per tooth",
  "D1510 - Space maintainer – fixed, unilateral – per quadrant",
  "D1516 - Space maintainer – fixed – bilateral, maxillary",
  "D1517 - Space maintainer – fixed – bilateral, mandibular",
  "D1520 - Space maintainer – removable – unilateral – per quadrant",
  "D1526 - Space maintainer – removable – bilateral, maxillary",
  "D1527 - Space maintainer – removable – bilateral, mandibular",
  "D1551 - Re-cement or re-bond bilateral space maintainer – maxillary",
  "D1552 - Re-cement or re-bond bilateral space maintainer – mandibular",
  "D1553 - Re-cement or re-bond unilateral space maintainer – per quadrant",
  "D1556 - Removal of fixed unilateral space maintainer – per quadrant",
  "D1557 - Removal of fixed bilateral space maintainer – maxillary",
  "D1558 - Removal of fixed bilateral space maintainer – mandibular",
  "D1575 - Distal shoe space maintainer – fixed, unilateral – per quadrant",
  "D1701 - Pfizer-BioNTech Covid-19 vaccine administration – first dose",
  "D1702 - Pfizer-BioNTech Covid-19 vaccine administration – second dose",
  "D1703 - Moderna Covid-19 vaccine administration – first dose",
  "D1704 - Moderna Covid-19 vaccine administration – second dose",
  "D1705 - AstraZeneca Covid-19 vaccine administration – first dose",
  "D1706 - AstraZeneca Covid-19 vaccine administration – second dose",
  "D1707 - Janssen Covid-19 vaccine administration",
  "D1708 - Pfizer-BioNTech Covid-19 vaccine administration – third dose",
  "D1709 - Pfizer-BioNTech Covid-19 vaccine administration – booster dose",
  "D1710 - Moderna Covid-19 vaccine administration – third dose",
  "D1711 - Moderna Covid-19 vaccine administration – booster dose",
  "D1712 - Janssen Covid-19 Vaccine Administration - booster dose",
  "D1713 - Pfizer-BioNTech Covid-19 vaccine administration tris-sucrose pediatric - first dose",
  "D1714 - Pfizer-BioNTech Covid-19 vaccine administration tris-sucrose pediatric - second dose",
  "D1781 - Vaccine administration – human papillomavirus - Dose 1",
  "D1782 - Vaccine administration – human papillomavirus - Dose 2",
  "D1783 - Vaccine administration – human papillomavirus - Dose 3",
  "D1999 - Unspecified preventive procedure, by report",
  "D2140 - Amalgam - one surface, primary or permanent",
  "D2150 - Amalgam - two surfaces, primary or permanent",
  "D2160 - Amalgam - three surfaces, primary or permanent",
  "D2161 - Amalgam - four or more surfaces, primary or permanent",
  "D2330 - Resin-based composite – one surface, anterior",
  "D2331 - Resin-based composite - two surfaces, anterior",
  "D2332 - Resin-based composite - three surfaces, anterior",
  "D2335 - Resin-based composite - four or more surfaces (anterior)",
  "D2390 - Resin-based composite crown, anterior",
  "D2391 - Resin-based composite - one surface, posterior",
  "D2392 - Resin-based composite - two surfaces, posterior",
  "D2393 - Resin-based composite - three surfaces, posterior",
  "D2394 - Resin-based composite - four or more surfaces, posterior",
  "D2410 - Gold foil - one surface",
  "D2420 - Gold foil - two surfaces",
  "D2430 - Gold foil - three surfaces",
  "D2510 - Inlay – metallic – one surface",
  "D2520 - Inlay - metallic - two surfaces",
  "D2530 - Inlay - metallic - three or more surfaces",
  "D2542 - Onlay - metallic - two surfaces",
  "D2543 - Onlay - metallic - three surfaces",
  "D2544 - Onlay - metallic - four or more surfaces",
  "D2610 - Inlay - porcelain/ceramic - one surface",
  "D2620 - Inlay - porcelain/ceramic - two surfaces",
  "D2630 - Inlay - porcelain/ceramic - three or more surfaces",
  "D2642 - Onlay - porcelain/ceramic - two surfaces",
  "D2643 - Onlay - porcelain/ceramic - three surfaces",
  "D2644 - Onlay - porcelain/ceramic - four or more surfaces",
  "D2650 - Inlay - resin-based composite - one surface",
  "D2651 - Inlay - resin-based composite - two surfaces",
  "D2652 - Inlay - resin-based composite - three or more surfaces",
  "D2662 - Onlay - resin-based composite - two surfaces",
  "D2663 - Onlay - resin-based composite - three surfaces",
  "D2664 - Onlay - resin-based composite - four or more surfaces",
  "D2710 - Crown – resin-based composite (indirect)",
  "D2712 - Crown - ¾ resin-based composite (indirect)",
  "D2720 - Crown - resin with high noble metal",
  "D2721 - Crown - resin with predominantly base metal",
  "D2722 - Crown - resin with noble metal",
  "D2740 - Crown - porcelain/ceramic",
  "D2750 - Crown - porcelain fused to high noble metal",
  "D2751 - Crown - porcelain fused to predominantly base metal",
  "D2752 - Crown - porcelain fused to noble metal",
  "D2753 - Crown - porcelain fused to titanium or titanium alloys",
  "D2780 - Crown- ¾ cast high noble metal",
  "D2781 - Crown- ¾ cast predominantly base metal",
  "D2782 - Crown- ¾ cast noble metal",
  "D2783 - Crown - ¾ porcelain/ceramic",
  "D2790 - Crown - full cast high noble metal",
  "D2791 - Crown - full cast predominantly base metal",
  "D2792 - Crown - full cast noble metal",
  "D2794 - Crown – titanium and titanium alloys",
  "D2799 - Interim crown - further treatment or completion of diagnosis necessary prior to final impression",
  "D2910 - Re-cement or re-bond inlay, onlay, veneer or partial coverage restoration",
  "D2915 - Re-cement or re-bond indirectly fabricated or prefabricated post and core",
  "D2920 - Re-cement or re-bond crown",
  "D2921 - Reattachment of tooth fragment, incisal edge or cusp",
  "D2928 - Prefabricated porcelain/ceramic crown – permanent tooth",
  "D2929 - Prefabricated porcelain/ceramic crown – primary tooth",
  "D2930 - Prefabricated stainless steel crown - primary tooth",
  "D2931 - Prefabricated stainless steel crown - permanent tooth",
  "D2932 - Prefabricated resin crown",
  "D2933 - Prefabricated stainless steel crown with resin window",
  "D2934 - Prefabricated esthetic coated stainless steel crown - primary tooth",
  "D2940 - Protective restoration",
  "D2941 - Interim therapeutic restoration – primary dentition",
  "D2949 - Restorative foundation for an indirect restoration",
  "D2950 - Core buildup, including any pins when required",
  "D2951 - Pin retention - per tooth, in addition to restoration",
  "D2952 - Post and core in addition to crown, indirectly fabricated",
  "D2953 - Each additional indirectly fabricated post - same tooth",
  "D2954 - Prefabricated post and core in addition to crown",
  "D2955 - Post removal",
  "D2957 - Each additional prefabricated post - same tooth",
  "D2960 - Labial veneer (resin laminate) - direct",
  "D2961 - Labial veneer (resin laminate) - indirect",
  "D2962 - Labial veneer (porcelain laminate) - indirect",
  "D2971 - Additional procedures to customize a crown to fit under an existing partial denture framework",
  "D2975 - Coping",
  "D2976 - Band stabilization - per tooth",
  "D2980 - Crown repair necessitated by restorative material failure",
  "D2981 - Inlay repair necessitated by restorative material failure",
  "D2982 - Onlay repair necessitated by restorative material failure",
  "D2983 - Veneer repair necessitated by restorative material failure",
  "D2989 - Excavation of a tooth resulting in the determination of non-restorability",
  "D2990 - Resin infiltration of incipient smooth surface lesions",
  "D2991 - Application of hydroxyapatite regeneration medicament – per tooth",
  "D2999 - Unspecified restorative procedure, by report",
  "D3110 - Pulp cap - direct",
  "D3120 - Pulp cap - indirect",
  "D3220 - Therapeutic pulpotomy",
  "D3221 - Pulpal debridement, primary and permanent teeth",
  "D3222 - Partial pulpotomy for apexogenesis - permanent tooth with incomplete root development",
  "D3230 - Pulpal therapy (resorbable filling) - anterior, primary tooth",
  "D3240 - Pulpal therapy (resorbable filling) - posterior, primary tooth",
  "D3310 - Endodontic therapy, anterior tooth",
  "D3320 - Endodontic therapy, premolar tooth",
  "D3330 - Endodontic therapy, molar tooth",
  "D3331 - Treatment of root canal obstruction, non-surgical access",
  "D3332 - Incomplete endodontic therapy; inoperable, unrestorable or fractured tooth",
  "D3333 - Internal root repair of perforation defects",
  "D3346 - Retreatment of previous root canal therapy - anterior",
  "D3347 - Retreatment of previous root canal therapy - premolar",
  "D3348 - Retreatment of previous root canal therapy - molar",
  "D3351 - Apexification/recalcification – initial visit",
  "D3352 - Apexification/recalcification - interim medication replacement",
  "D3353 - Apexification/recalcification - final visit",
  "D3355 - Pulpal regeneration – initial visit",
  "D3356 - Pulpal regeneration – interim medication replacement",
  "D3357 - Pulpal regeneration – completion of treatment",
  "D3410 - Apicoectomy - anterior",
  "D3421 - Apicoectomy - premolar (first root)",
  "D3425 - Apicoectomy - molar (first root)",
  "D3426 - Apicoectomy (each additional root)",
  "D3428 - Bone graft in conjunction with periradicular surgery - per tooth; single site",
  "D3429 - Bone graft in conjunction with periradicular surgery - each additional contiguous tooth in same surgical site.",
  "D3430 - Retrograde filling - per root",
  "D3431 - Biologic materials to aid in soft and osseous tissue regeneration in conjunction with periradicular surgery",
  "D3432 - Guided tissue regeneration, resorbable barrier, per site in conjunction with periradicular surgery",
  "D3450 - Root amputation - per root",
  "D3460 - Endodontic endosseous implant",
  "D3470 - Intentional re-implantation (including necessary splinting)",
  "D3471 - Surgical repair of root resorption - anterior",
  "D3472 - Surgical repair of root resorption – premolar",
  "D3473 - Surgical repair of root resorption – molar",
  "D3501 - Surgical exposure of root surface without apicoectomy or repair of root resorption – anterior",
  "D3502 - Surgical exposure of root surface without apicoectomy or repair of root resorption – premolar",
  "D3503 - Surgical exposure of root surface without apicoectomy or repair of root resorption – molar",
  "D3910 - Surgical procedure for isolation of tooth with rubber dam",
  "D3911 - Intraorifice barrier",
  "D3920 - Hemisection (including any root removal), not including root canal therapy",
  "D3921 - Decoronation or submergence of an erupted tooth",
  "D3950 - Canal preparation and fitting of preformed dowel or post",
  "D3999 - Unspecified endodontic procedure, by report",
  "D4210 - Gingivectomy or gingivoplasty - four or more contiguous teeth or tooth bounded spaces per quadrant",
  "D4211 - Gingivectomy or gingivoplasty - one to three contiguous teeth or tooth bounded spaces per quadrant",
  "D4212 - Gingivectomy or gingivoplasty to allow access for restorative procedure, per tooth",
  "D4230 - Anatomical crown exposure - four or more contiguous teeth or tooth bounded spaces per quadrant",
  "D4231 - Anatomical crown exposure - one to three teeth or tooth bounded spaces per quadrant",
  "D4240 - Gingival flap procedure, including root planing - four or more contiguous teeth or teeth bounded spaces per quadrant",
  "D4241 - Gingival flap procedure, including root planning – one to three teeth or tooth bounded spaces per quadrant",
  "D4245 - Apically positioned flap",
  "D4249 - Clinical crown lengthening - hard tissue",
  "D4260 - Osseous surgery (including elevation of a full thickness flap and closure) - four or more contiguous teeth or tooth bounded spaces per quadrant",
  "D4261 - Osseous surgery (including elevation of a full thickness flap and closure) – one to three contiguous teeth or tooth bonded spaces per quadrant",
  "D4263 - Bone replacement graft – retained natural tooth - first site in quadrant",
  "D4264 - Bone replacement graft – retained natural tooth - each additional site in quadrant",
  "D4265 - Biologic materials to aid in soft and osseous tissue regeneration, per site",
  "D4266 - Guided tissue regeneration, natural teeth - resorbable barrier, per site",
  "D4267 - Guided tissue regeneration, natural teeth – non-resorbable barrier, per site",
  "D4268 - Surgical revision procedure, per tooth",
  "D4270 - Pedicle soft tissue graft procedure",
  "D4273 - Autogenous connective tissue graft procedure (including donor and recipient surgical sites) first tooth, implant, or edentulous tooth position in graft",
  "D4274 - Mesial/distal wedge procedure, single tooth (when not performed in conjunction with surgical procedures in the same anatomical area)",
  "D4275 - Non-autogenous connective tissue graft (including recipient site and donor material) first tooth, implant, or edentulous tooth position in graft",
  "D4276 - Combined connective tissue and pedicle graft, per tooth",
  "D4277 - Free soft tissue graft procedure (including recipient and donor surgical sites) first tooth, implant or edentulous tooth position in graft",
  "D4278 - Free soft tissue graft procedure (including recipient and donor surgical sites) each additional contiguous tooth, implant, or edentulous tooth position in same graft site",
  "D4283 - Autogenous connective tissue graft procedure (including donor and recipient surgical sites) – each additional contiguous tooth, implant or edentulous tooth position in same graft site",
  "D4285 - Non-autogenous connective tissue graft procedure (including recipient surgical site and donor material) – each additional contiguous tooth, implant or edentulous tooth position in same graft site",
  "D4286 - Non-autogenous connective tissue graft procedure (including recipient surgical site and donor material) – each additional contiguous tooth, implant or edentulous tooth position in same graft site",
  "D4322 - Splint – intra-coronal; natural teeth or prosthetic crowns",
  "D4323 - Splint – extra-coronal; natural teeth or prosthetic crowns",
  "D4341 - Periodontal scaling and root planing – four or more teeth per quadrant",
  "D4342 - Periodontal scaling and root planing - one to three teeth, per quadrant",
  "D4346 - Scaling in the presence of generalized moderate or severe gingival inflammation – full mouth, after oral evaluation",
  "D4355 - Full mouth debridement to enable comprehensive periodontal evaluation and diagnosis on subsequent visit",
  "D4381 - Localized delivery of antimicrobial agents via a controlled release vehicle into diseased crevicular tissue, per tooth",
  "D4910 - Periodontal maintenance",
  "D4920 - Unscheduled dressing change (by someone other than the treating dentist or their staff)",
  "D4921 - Gingival irrigation with medicinal agent – per quadrant",
  "D4999 - Unspecified periodontal procedure, by report",
  "D5110 - Complete denture - maxillary",
  "D5120 - Complete denture - mandibular",
  "D5130 - Immediate denture - maxillary",
  "D5140 - Immediate denture - mandibular",
  "D5211 - Maxillary partial denture - resin base",
  "D5212 - Mandibular partial denture - resin base",
  "D5213 - Maxillary partial denture - cast metal framework with resin denture bases",
  "D5214 - Mandibular partial denture - cast metal framework with resin denture bases",
  "D5221 - Immediate maxillary partial denture – resin base",
  "D5222 - Immediate mandibular partial denture – resin base",
  "D5223 - Immediate maxillary partial denture – cast metal framework with resin denture bases",
  "D5224 - Immediate mandibular partial denture – cast metal framework with resin denture bases",
  "D5225 - Maxillary partial denture - flexible base",
  "D5226 - Mandibular partial denture - flexible base",
  "D5227 - Immediate maxillary partial denture - flexible base",
  "D5228 - Immediate mandibular partial denture - flexible base",
  "D5282 - Removable unilateral partial denture – one piece cast metal",
  "D5283 - Removable unilateral partial denture – one piece cast metal",
  "D5284 - Removable unilateral partial denture – one piece flexible base",
  "D5286 - Removable unilateral partial denture – one piece resin",
  "D5410 - Adjust complete denture - maxillary",
  "D5411 - Adjust complete denture - mandibular",
  "D5421 - Adjust partial denture - maxillary",
  "D5422 - Adjust partial denture - mandibular",
  "D5511 - Repair broken complete denture base, mandibular",
  "D5512 - Repair broken complete denture base, maxillary",
  "D5520 - Replace missing or broken teeth - complete denture (each tooth)",
  "D5611 - Repair resin partial denture base, mandibular",
  "D5612 - Repair resin partial denture base, maxillary",
  "D5621 - Repair cast partial framework, mandibular",
  "D5622 - Repair cast partial framework, maxillary",
  "D5630 - Repair or replace broken retentive clasping materials – per tooth",
  "D5640 - Replace broken teeth - per tooth",
  "D5650 - Add tooth to existing partial denture",
  "D5660 - Add clasp to existing partial denture – per tooth",
  "D5670 - Replace all teeth and acrylic on cast metal framework (maxillary)",
  "D5671 - Replace all teeth and acrylic on cast metal framework (mandibular)",
  "D5710 - Rebase complete maxillary denture",
  "D5711 - Rebase complete mandibular denture",
  "D5720 - Rebase maxillary partial denture",
  "D5721 - Rebase mandibular partial denture",
  "D5725 - Rebase hybrid prosthesis",
  "D5730 - Reline complete maxillary denture (direct)",
  "D5731 - Reline complete mandibular denture (direct)",
  "D5740 - Reline maxillary partial denture (direct)",
  "D5741 - Reline mandibular partial denture (direct)",
  "D5750 - Reline complete maxillary denture (indirect)",
  "D5751 - Reline complete mandibular denture (indirect)",
  "D5760 - Reline maxillary partial denture (indirect)",
  "D5761 - Reline mandibular partial denture (indirect)",
  "D5765 - Soft liner for complete or partial removable denture – indirect",
  "D5810 - Interim complete denture (maxillary)",
  "D5811 - Interim complete denture (mandibular)",
  "D5820 - Interim partial denture (including retentive/clasping materials, rests, and teeth), maxillary",
  "D5821 - Interim partial denture (including retentive/clasping materials, rests, and teeth), mandibular",
  "D5850 - Tissue conditioning, maxillary",
  "D5851 - Tissue conditioning, mandibular",
  "D5862 - Precision attachment, by report",
  "D5863 - Overdenture – complete maxillary",
  "D5864 - Overdenture – partial maxillary",
  "D5865 - Overdenture – complete mandibular",
  "D5866 - Overdenture – partial mandibular",
  "D5867 - Replacement of replaceable part of semi-precision or precision attachment, per attachment",
  "D5875 - Modification of removable prosthesis following implant surgery",
  "D5876 - Add metal substructure to acrylic full denture (per arch)",
  "D5899 - Unspecified removable prosthodontic procedure, by report",
  "D5911 - Facial moulage (sectional)",
  "D5912 - Facial moulage (complete)",
  "D5913 - Nasal prosthesis",
  "D5914 - Auricular prosthesis",
  "D5915 - Orbital prosthesis",
  "D5916 - Ocular prosthesis",
  "D5919 - Facial prosthesis",
  "D5922 - Nasal septal prosthesis",
  "D5923 - Ocular prosthesis, interim",
  "D5924 - Cranial prosthesis",
  "D5925 - Facial augmentation implant",
  "D5926 - Nasal prosthesis, replacement",
  "D5927 - Auricular prosthesis, replacement",
  "D5928 - Orbital prosthesis, replacement",
  "D5929 - Facial prosthesis, replacement",
  "D5931 - Obturator prosthesis, surgical",
  "D5932 - Obturator prosthesis, definitive",
  "D5933 - Obturator prosthesis, modification",
  "D5934 - Mandibular resection prosthesis with guide flange",
  "D5935 - Mandibular resection prosthesis without guide flange",
  "D5936 - Obturator prosthesis, interim",
  "D5937 - Trismus appliance (not for TMD treatment)",
  "D5951 - Feeding aid",
  "D5952 - Speech aid prosthesis, pediatric",
  "D5953 - Speech aid prosthesis, adult",
  "D5954 - Palatal augmentation prosthesis",
  "D5955 - Palatal life prosthesis, definitive",
  "D5958 - Palatal lift prosthesis, interim",
  "D5959 - Palatal lift prosthesis, modification",
  "D5960 - Speech aid prosthesis, modification",
  "D5982 - Surgical stent",
  "D5983 - Radiation carrier",
  "D5984 - Radiation shield",
  "D5985 - Radiation cone locator",
  "D5986 - Fluoride gel carrier",
  "D5987 - Commissure splint",
  "D5988 - Surgical splint",
  "D5991 - Vesiculobullous disease medicament carrier",
  "D5992 - Adjust maxillofacial prosthetic appliance",
  "D5993 - Maintenance and cleaning of a maxillofacial prosthesis (extra- and intra-oral)",
  "D5995 - Periodontal medicament carrier with peripheral seal – laboratory processed - maxillary",
  "D5996 - Periodontal medicament carrier with peripheral seal - laboratory processed - mandibular",
  "D5999 - Unspecified maxillofacial prosthesis, by report",
  "D6010 - Surgical placement of implant body: endosteal implant",
  "D6011 - Surgical access to an implant body (second stage implant surgery)",
  "D6012 - Surgical placement of interim implant body for transitional prosthesis: endosteal implant",
  "D6013 - Surgical placement of mini implant",
  "D6040 - Surgical placement: eposteal implant",
  "D6050 - Surgical placement: transosteal implant",
  "D6051 - Interim implant abutment placement",
  "D6055 - Connecting bar - implant supported or abutment supported",
  "D6056 - Prefabricated abutment – includes modification and placement",
  "D6057 - Custom fabricated abutment – includes placement",
  "D6058 - Abutment supported porcelain/ceramic crown",
  "D6059 - Abutment supported porcelain fused to metal crown (high noble metal)",
  "D6060 - Abutment supported porcelain fused to metal crown (predominantly base metal)",
  "D6061 - Abutment supported porcelain fused to metal crown (noble metal)",
  "D6062 - Abutment supported cast metal crown (high noble metal)",
  "D6063 - Abutment supported cast metal crown (predominantly base metal)",
  "D6064 - Abutment supported cast metal crown (noble metal)",
  "D6065 - Implant supported porcelain/ceramic crown",
  "D6066 - Implant supported crown - porcelain fused to high noble alloys",
  "D6067 - Implant supported crown - high noble alloys",
  "D6068 - Abutment supported retainer for porcelain/ceramic FPD",
  "D6069 - Abutment supported retainer for porcelain fused to metal FPD (high noble metal)",
  "D6070 - Abutment supported retainer for porcelain fused to metal FPD (predominantly base metal)",
  "D6071 - Abutment supported retainer for porcelain fused to metal FPD (noble metal)",
  "D6072 - Abutment supported retainer for cast metal FPD (high noble metal)",
  "D6073 - Abutment supported retainer for cast metal FPD (predominantly base metal)",
  "D6074 - Abutment supported retainer for cast metal FPD (noble metal)",
  "D6075 - Implant supported retainer for ceramic FPD",
  "D6076 - Implant supported retainer for FPD - porcelain fused to high noble alloys",
  "D6077 - Implant supported retainer for metal FPD - high noble alloys",
  "D6080 - Implant maintenance procedures when prostheses are removed and reinserted, including cleansing of prostheses and abutments",
  "D6081 - Scaling and debridement in the presence of inflammation or mucositis of a single implant, including cleaning of the implant surfaces, without flap entry and closure.",
  "D6082 - Implant supported crown - porcelain fused to predominantly base alloys",
  "D6083 - Implant supported crown - porcelain fused to noble alloys",
  "D6084 - Implant supported crown - porcelain fused to titanium and titanium alloys",
  "D6085 - Interim implant crown",
  "D6086 - Implant supported crown - predominantly base alloys",
  "D6087 - Implant supported crown - noble alloys",
  "D6088 - Implant supported crown – titanium and titanium alloys",
  "D6089 - Accessing and retorquing loose implant screw - per screw",
  "D6090 - Repair implant supported prosthesis, by report",
  "D6091 - Replacement of replaceable part of semi-precision or precision attachment of implant/abutment supported prosthesis, per attachment",
  "D6092 - Re-cement or re-bond implant/abutment supported crown",
  "D6093 - Re-cement or re-bond implant/abutment supported fixed partial denture",
  "D6094 - Abutment supported crown – titanium or titanium alloys",
  "D6095 - Repair implant abutment, by report",
  "D6096 - Remove broken implant retaining screw",
  "D6097 - Abutment supported crown - porcelain fused to titanium and titanium alloys",
  "D6098 - Implant supported retainer - porcelain fused to predominantly base alloys",
  "D6099 - Implant supported retainer for FPD - porcelain fused to noble alloys",
  "D6100 - Surgical removal of implant body",
  "D6101 - Debridement of a peri-implant defect or defects surrounding a single implant, and surface cleaning of the exposed implant surfaces, including flap entry and closure",
  "D6102 - Debridement and osseous contouring of a peri-implant defect or defects surrounding a single implant and includes surface cleaning of the exposed implant surfaces, including flap entry and closure",
  "D6103 - Bone graft for repair of peri-implant defect – does not include flap entry and closure.",
  "D6104 - Bone graft at time of implant placement",
  "D6105 - Removal of implant body not requiring bone removal nor flap elevation",
  "D6106 - Guided tissue regeneration – resorbable barrier, per implant",
  "D6107 - Guided tissue regeneration – non-resorbable barrier, per implant",
  "D6110 - Implant /abutment supported removable denture for edentulous arch – maxillary",
  "D6111 - Implant /abutment supported removable denture for edentulous arch – mandibular",
  "D6112 - Implant /abutment supported removable denture for partially edentulous arch – maxillary",
  "D6113 - Implant /abutment supported removable denture for partially edentulous arch – mandibular",
  "D6114 - Implant /abutment supported fixed denture for edentulous arch – maxillary",
  "D6115 - Implant /abutment supported fixed denture for edentulous arch – mandibular",
  "D6116 - Implant /abutment supported fixed denture for partially edentulous arch – maxillary",
  "D6117 - Implant /abutment supported fixed denture for partially edentulous arch – mandibular",
  "D6118 - Implant/abutment supported interim fixed denture for edentulous arch – mandibular",
  "D6119 - Implant/abutment supported interim fixed denture for edentulous arch – maxillary",
  "D6120 - Implant supported retainer - porcelain fused to titanium and titanium alloy",
  "D6121 - Implant supported retainer for metal FPD - predominantly base alloys",
  "D6122 - Implant supported retainer for metal FPD - noble alloys",
  "D6123 - Implant supported retainer for metal FPD- titanium and titanium alloy",
  "D6190 - Radiographic/surgical implant index, by report",
  "D6191 - Semi-precision abutment – placement",
  "D6192 - Semi-precision attachment – placement",
  "D6194 - Abutment supported retainer crown for FPD – titanium and titanium alloys",
  "D6195 - Abutment supported retainer - porcelain fused to titanium and titanium alloys",
  "D6197 - Replacement of restorative material used to close an access opening of a screw-retained implant supported prosthesis, per implant",
  "D6198 - Remove interim implant component",
  "D6199 - Unspecified implant procedure, by report",
  "D6205 - Pontic - indirect resin based composite",
  "D6210 - Pontic - cast high noble metal",
  "D6211 - Pontic - cast predominantly base metal",
  "D6212 - Pontic - cast noble metal",
  "D6214 - Pontic – titanium and titanium alloys",
  "D6240 - Pontic - porcelain fused to high noble metal",
  "D6241 - Pontic - porcelain fused to predominantly base metal",
  "D6242 - Pontic - porcelain fused to noble metal",
  "D6243 - Pontic - porcelain fused to titanium and titanium alloys",
  "D6245 - Pontic - porcelain/ceramic",
  "D6250 - Pontic - resin with high noble metal",
  "D6251 - Pontic - resin with predominantly base metal",
  "D6252 - Pontic - resin with noble metal",
  "D6253 - Interim pontic- further treatment or completion of diagnosis necessary prior to final impression",
  "D6545 - Retainer - cast metal for resin bonded fixed prosthesis",
  "D6548 - Retainer - porcelain/ceramic for resin bonded fixed prosthesis",
  "D6549 - Resin retainer – for resin bonded fixed prosthesis",
  "D6600 - Retainer inlay - porcelain/ceramic, two surfaces",
  "D6601 - Retainer inlay - porcelain/ceramic, three or more surfaces",
  "D6602 - Retainer inlay - cast high noble metal, two surfaces",
  "D6603 - Retainer inlay - cast high noble metal, three or more surfaces",
  "D6604 - Retainer inlay – cast predominantly base metal, two surfaces",
  "D6605 - Retainer inlay – cast predominantly base metal, three or more surfaces",
  "D6606 - Retainer inlay – cast noble metal, two surfaces",
  "D6607 - Retainer inlay – cast noble metal – three or more surfaces",
  "D6608 - Retainer onlay - porcelain/ceramic, two surfaces",
  "D6609 - Retainer onlay porcelain/ceramic, three or more surfaces",
  "D6610 - Retainer onlay – cast high noble metal, two surfaces",
  "D6611 - Retainer onlay – cast high noble metal, three or more surfaces",
  "D6612 - Retainer onlay - cast predominantly base metal, two surfaces",
  "D6613 - Retainer onlay - cast predominantly base metal, three or more surfaces",
  "D6614 - Retainer onlay – cast noble metal, two surfaces",
  "D6615 - Retainer onlay - cast noble metal, three or more surfaces",
  "D6624 - Retainer inlay – titanium",
  "D6634 - Retainer onlay - titanium",
  "D6710 - Retainer crown – indirect resin based composite",
  "D6720 - Retainer crown - resin fused to high noble metal",
  "D6721 - Retainer crown - resin with predominantly base metal",
  "D6722 - Retainer crown - resin with noble metal",
  "D6740 - Retainer crown - porcelain/ceramic",
  "D6750 - Retainer crown - porcelain fused to high noble metal",
  "D6751 - Retainer crown - porcelain fused to predominantly base metal",
  "D6752 - Retainer crown - porcelain fused to noble metal",
  "D6753 - Retainer crown - porcelain fused to titanium and titanium alloys",
  "D6780 - Retainer crown - 3/4 - cast high noble metal",
  "D6781 - Retainer crown - 3/4 - cast predominantly base metal",
  "D6782 - Retainer crown - 3/4 - cast noble metal",
  "D6783 - Retainer crown - 3/4 porcelain/ceramic",
  "D6784 - Retainer crown ¾ - titanium and titanium alloys",
  "D6790 - Retainer crown - full cast high noble metal",
  "D6791 - Retainer crown - full cast predominantly base metal",
  "D6792 - Retainer crown - full cast noble metal",
  "D6793 - Interim retainer crown - further treatment or completion of diagnosis necessary prior to final impression",
  "D6794 - Retainer crown – titanium and titanium alloys",
  "D6920 - Connector bar",
  "D6930 - Re-cement or re-bond fixed partial denture",
  "D6940 - Stress breaker",
  "D6950 - Precision attachment",
  "D6980 - Fixed partial denture repair",
  "D6985 - Pediatric partial denture, fixed",
  "D6999 - Unspecified fixed prosthodontic procedure, by report",
  "D7111 - Extraction, coronal remnants – primary tooth",
  "D7140 - Extraction, erupted tooth or exposed root",
  "D7210 - Extraction, erupted tooth requiring removal of bone",
  "D7220 - Removal of impacted tooth - soft tissue",
  "D7230 - Removal of impacted tooth - partially bony",
  "D7240 - Removal of impacted tooth - completely bony",
  "D7241 - Removal of impacted tooth - completely bony, with unusual surgical complications",
  "D7250 - Removal of residual tooth roots (cutting procedure)",
  "D7251 - Coronectomy – intentional partial tooth removal, impacted teeth only",
  "D7260 - Oroantral fistula closure",
  "D7261 - Primary closure of a sinus perforation",
  "D7270 - Tooth re-implantation and/or stabilization of accidentally avulsed or displaced tooth",
  "D7272 - Tooth transplantation",
  "D7280 - Exposure of an unerupted tooth",
  "D7282 - Mobilization of erupted or malpositioned tooth to aid eruption",
  "D7283 - Placement of device to facilitate eruption of impacted tooth",
  "D7284 - Excisional biopsy of minor salivary glands",
  "D7285 - Incisional biopsy of oral tissue – hard (bone, tooth)",
  "D7286 - Incisional Biopsy of oral tissue – soft",
  "D7287 - Exfoliative cytology sample collection",
  "D7288 - Brush biopsy- transepithelial sample collection",
  "D7290 - Surgical repositioning of teeth",
  "D7291 - Transseptal fiberotomy/supra crestal fiberotomy, by report",
  "D7292 - Placement of temporary anchorage device [screw retained plate] requiring flap",
  "D7293 - Placement of temporary anchorage device requiring flap",
  "D7294 - Placement of temporary anchorage device without flap",
  "D7295 - Harvest of bone for use in autogenous grafting procedures",
  "D7296 - Corticotomy – one to three teeth or tooth spaces, per quadrant",
  "D7297 - Corticotomy – four or more teeth or tooth spaces, per quadrant",
  "D7298 - Removal of temporary anchorage device [screw retained plate], requiring flap",
  "D7299 - Removal of temporary anchorage device, requiring flap",
  "D7300 - Removal of temporary anchorage device without flap",
  "D7310 - Alveoloplasty in conjunction with extractions - four or more teeth or tooth spaces, per quadrant",
  "D7311 - Alveoloplasty in conjunction with extractions - one to three teeth or tooth spaces, per quadrant",
  "D7320 - Alveoloplasty not in conjunction with extractions - four or more teeth or tooth spaces, per quadrant",
  "D7321 - Alveoloplasty not in conjunction with extractions - one to three teeth or tooth spaces, per quadrant",
  "D7340 - Vestibuloplasty - ridge extension (secondary epithelialization)",
  "D7350 - Vestibuloplasty - ridge extension (including soft tissue grafts, muscle reattachments, revision of soft tissue attachment and management of hypertrophied and hyperplastic tissue)",
  "D7410 - Excision of benign lesion up to 1.25 cm",
  "D7411 - Excision of benign lesion greater than 1.25 cm",
  "D7412 - Excision of benign lesion, complicated",
  "D7413 - Excision of malignant lesion up to 1.25 cm",
  "D7414 - Excision of malignant lesion greater than 1.25 cm",
  "D7415 - Excision of malignant lesion, complicated",
  "D7440 - Excision of malignant tumor - lesion diameter up to 1.25 cm",
  "D7441 - Excision of malignant tumor - lesion diameter greater than 1.25 cm",
  "D7450 - Removal of benign odontogenic cyst or tumor – lesion diameter up to 1.25 cm",
  "D7451 - Removal of benign odontogenic cyst or tumor - lesion diameter greater than 1.25 cm",
  "D7460 - Removal of benign nonodontogenic cyst or tumor - lesion diameter up to 1.25 cm",
  "D7461 - Removal of benign nonodontogenic cyst or tumor – lesion diameter greater than 1.25 cm",
  "D7465 - Destruction of lesion(s) by physical or chemical method, by report",
  "D7471 - Removal of lateral exostosis - (maxilla or mandible)",
  "D7472 - Removal of torus palatinus",
  "D7473 - Removal of torus mandibularis",
  "D7485 - Reduction of osseous tuberosity",
  "D7490 - Radical resection of maxilla or mandible",
  "D7509 - Marsupialization of odontogenic cyst",
  "D7510 - Incision and drainage of abscess - intraoral soft tissue",
  "D7511 - Incision and drainage of abscess - intraoral soft tissue- complicated (includes drainage of multiple fascial spaces)",
  "D7520 - Incision and drainage of abscess - extraoral soft tissue",
  "D7521 - Incision and drainage of abscess - extraoral soft tissue- complicated (includes drainage of multiple fascial spaces)",
  "D7530 - Removal of foreign body from mucosa, skin or subcutaneous alveolar tissue",
  "D7540 - Removal of reaction-producing foreign bodies, musculoskeletal system",
  "D7550 - Partial ostectomy/ sequestretomy for removal of non-vital bone",
  "D7560 - Maxillary sinusotomy for removal of tooth fragment or foreign body",
  "D7610 - Maxilla - open reduction (teeth immobilized, if present)",
  "D7620 - Maxilla - closed reduction (teeth immobilized, if present)",
  "D7630 - Mandible - open reduction (teeth immobilized, if present)",
  "D7640 - Mandible - closed reduction (teeth immobilized, if present)",
  "D7650 - Malar and/or zygomatic arch - open reduction",
  "D7660 - Malar and/or zygomatic arch - closed reduction",
  "D7670 - Alveolus - closed reduction, may include stabilization of teeth",
  "D7671 - Alveolus, open reduction, may include stabilization of teeth",
  "D7680 - Facial bones - complicated reduction with fixation and multiple surgical approaches",
  "D7710 - Maxilla - open reduction",
  "D7720 - Maxilla - closed reduction",
  "D7730 - Mandible - open reduction",
  "D7740 - Mandible - closed reduction",
  "D7750 - Malar and/or zygomatic arch - open reduction",
  "D7760 - Malar and/or zygomatic arch - closed reduction",
  "D7770 - Alveolus - open reduction stabilization of teeth",
  "D7771 - Alveolus, closed reduction stabilization of teeth",
  "D7780 - Facial bones - complicated reduction with fixation and multiple approaches",
  "D7810 - Open reduction of dislocation",
  "D7820 - Closed reduction of dislocation",
  "D7830 - Manipulation under anesthesia",
  "D7840 - Condylectomy",
  "D7850 - Surgical discectomy, with/without implant",
  "D7852 - Disc repair",
  "D7854 - Synovectomy",
  "D7856 - Myotomy",
  "D7858 - Joint reconstruction",
  "D7860 - Arthrotomy",
  "D7865 - Arthoplasty",
  "D7870 - Arthrocentesis",
  "D7871 - Non-arthroscopic lysis and lavage",
  "D7872 - Arthroscopy - diagnosis, with or without biopsy",
  "D7873 - Arthroscopy: lavage and lysis of adhesions",
  "D7874 - Arthroscopy: disc repositioning and stabilization",
  "D7875 - Arthroscopy: synovectomy",
  "D7876 - Arthroscopy: discectomy",
  "D7877 - Arthroscopy: debridement",
  "D7880 - Occlusal orthotic device, by report",
  "D7881 - Occlusal orthotic device adjustment",
  "D7899 - Unspecified TMJ therapy, by report",
  "D7910 - Suture of recent small wounds up to 5 cm",
  "D7911 - Complicated suture - up to 5 cm",
  "D7912 - Complicated suture - greater than 5 cm",
  "D7920 - Skin graft (identify defect covered, location and type of graft)",
  "D7921 - Collection and application of autologous blood concentrate product",
  "D7922 - Placement of intra-socket biological dressing to aid in hemostasis or clot stabilization, per site",
  "D7939 - Indexing for osteotomy using dynamic robotic assisted or dynamic navigation",
  "D7940 - Osteoplasty - for orthognathic deformities",
  "D7941 - Osteotomy – mandibular rami",
  "D7943 - Osteotomy – mandibular rami with bone graft; includes obtaining the graft",
  "D7944 - Osteotomy - segmented or subapical",
  "D7945 - Osteotomy - body of mandible",
  "D7946 - LeFort I (maxilla - total)",
  "D7947 - LeFort I (maxilla - segmented)",
  "D7948 - LeFort II or LeFort III (osteoplasty of facial bones for midface hypoplasia or retrusion) - without bone graft",
  "D7949 - LeFort II or LeFort III - with bone graft",
  "D7950 - Osseous, osteoperiosteal, or cartilage graft of the mandible or maxilla - autogenous or nonautogenous, by report",
  "D7951 - Sinus augmentation with bone or bone substitutes via a lateral open approach",
  "D7952 - Sinus augmentation via a vertical approach",
  "D7953 - Bone replacement graft for ridge preservation - per site",
  "D7955 - Repair of maxillofacial soft and/or hard tissue defect",
  "D7956 - Guided tissue regeneration, edentulous area – resorbable barrier, per site",
  "D7957 - Guided tissue regeneration, edentulous area – non-resorbable barrier, per site",
  "D7961 - Buccal/labial frenectomy (frenulectomy)",
  "D7962 - Lingual frenectomy (frenulectomy)",
  "D7963 - Frenuloplasty",
  "D7970 - Excision of hyperplastic tissue - per arch",
  "D7971 - Excision of pericoronal gingiva",
  "D7972 - Surgical reduction of fibrous tuberosity",
  "D7979 - Non – surgical sialolithotomy",
  "D7980 - Surgical sialolithotomy",
  "D7981 - Excision of salivary gland, by report",
  "D7982 - Sialodochoplasty",
  "D7983 - Closure of salivary fistula",
  "D7990 - Emergency tracheotomy",
  "D7991 - Coronoidectomy",
  "D7993 - Surgical placement of craniofacial implant – extra oral",
  "D7994 - Surgical placement: zygomatic implant",
  "D7995 - Synthetic graft - mandible or facial bones, by report",
  "D7996 - Implant-mandible for augmentation purposes (excluding alveolar ridge), by report",
  "D7997 - Appliance removal (not by dentist who placed appliance), includes removal of archbar",
  "D7998 - Intraoral placement of a fixation device not in conjunction with a fracture",
  "D7999 - Unspecified oral surgery procedure, by report",
  "D8010 - Limited orthodontic treatment of the primary dentition",
  "D8020 - Limited orthodontic treatment of the transitional dentition",
  "D8030 - Limited orthodontic treatment of the adolescent dentition",
  "D8040 - Limited orthodontic treatment of the adult dentition",
  "D8070 - Comprehensive orthodontic treatment of the transitional dentition",
  "D8080 - Comprehensive orthodontic treatment of the adolescent dentition",
  "D8090 - Comprehensive orthodontic treatment of the adult dentition",
  "D8210 - Removable appliance therapy",
  "D8220 - Fixed appliance therapy",
  "D8660 - Pre-orthodontic treatment examination",
  "D8670 - Periodic orthodontic treatment visit",
  "D8680 - Orthodontic retention",
  "D8681 - Removable orthodontic retainer adjustment",
  "D8695 - Removal of fixed orthodontic appliances for reasons other than at completion of treatment",
  "D8696 - Repair of orthodontic appliance – maxillary",
  "D8697 - Repair of orthodontic appliance – mandibular",
  "D8698 - Re-cement or re-bond fixed retainer – maxillary",
  "D8699 - Re-cement or re-bond fixed retainer – mandibular",
  "D8701 - Repair of fixed retainer, includes reattachment – maxillary",
  "D8702 - Repair of fixed retainer, includes reattachment – mandibular",
  "D8703 - Replacement of lost or broken retainer – maxillary",
  "D8704 - Replacement of lost or broken retainer – mandibular",
  "D8999 - Unspecified orthodontic procedure, by report",
  "D9110 - Palliative treatment of dental pain - per visit",
  "D9120 - Fixed partial denture sectioning",
  "D9130 - Temporomandibular joint dysfunction – non-invasive physical therapies",
  "D9210 - Local anesthesia not in conjunction with operative or surgical procedures",
  "D9211 - Regional block anesthesia",
  "D9212 - Trigeminal division block anesthesia",
  "D9215 - Local anesthesia in conjunction with operative or surgical procedures",
  "D9219 - Evaluation for moderate sedation, deep sedation or general anesthesia",
  "D9222 - Deep sedation/general anesthesia – first 15 minutes",
  "D9223 - Deep sedation/general anesthesia – each subsequent 15 minute increment",
  "D9230 - Inhalation of nitrous oxide/anxiolysis, analgesia",
  "D9239 - Intravenous moderate (conscious) sedation/analgesia – first 15 minutes",
  "D9243 - Intravenous moderate (conscious) sedation/analgesia – each subsequent 15 minute increment",
  "D9248 - Non-intravenous conscious sedation",
  "D9310 - Consultation - diagnostic service provided by dentist or physician other than requesting dentist or physician",
  "D9311 - Consultation with a medical health care professional",
  "D9410 - House/extended care facility call",
  "D9420 - Hospital or ambulatory surgical center call",
  "D9430 - Office visit for observation (during regularly scheduled hours) - no other services performed",
  "D9440 - Office visit - after regularly scheduled hours",
  "D9450 - Case presentation, subsequent to detailed and extensive treatment planning",
  "D9610 - Therapeutic parenteral drug, single administration",
  "D9612 - Therapeutic parenteral drugs, two or more administrations, different medications",
  "D9613 - Infiltration of sustained release therapeutic drug, per quadrant",
  "D9630 - Drugs or medicaments dispensed in the office for home use",
  "D9910 - Application of desensitizing medicament",
  "D9911 - Application of desensitizing resin for cervical and/or root surface, per tooth",
  "D9912 - Pre-visit patient screening",
  "D9920 - Behavior management, by report",
  "D9930 - Treatment of complications (post-surgical) - unusual circumstances, by report",
  "D9932 - Cleaning and inspection of removable complete denture, maxillary",
  "D9933 - Cleaning and inspection of removable complete denture, mandibular",
  "D9934 - Cleaning and inspection of removable partial denture, maxillary",
  "D9935 - Cleaning and inspection of removable partial denture, mandibular",
  "D9938 - Fabrication of a custom removable clear plastic temporary aesthetic appliance",
  "D9939 - Placement of a custom removable clear plastic temporary aesthetic appliance",
  "D9941 - Fabrication of athletic mouthguard",
  "D9942 - Repair and/or reline of occlusal guard",
  "D9943 - Occlusal guard adjustment",
  "D9944 - Occlusal guard – hard appliance, full arch",
  "D9945 - Occlusal guard – soft appliance, full arch",
  "D9946 - Occlusal guard – hard appliance, partial arch",
  "D9947 - Custom sleep apnea appliance fabrication and placement",
  "D9948 - Adjustment of custom sleep apnea appliance",
  "D9949 - Repair of a custom sleep apnea appliance",
  "D9950 - Occlusion analysis - mounted case",
  "D9951 - Occlusal adjustment - limited",
  "D9952 - Occlusal adjustment – complete",
  "D9953 - Reline custom sleep apnea appliance (indirect)",
  "D9954 - Fabrication and delivery of oral appliance therapy (OAT) morning repositioning device",
  "D9955 - Oral appliance therapy (OAT) titration visit",
  "D9956 - Administration of a home sleep apnea test",
  "D9957 - Screening for sleep related breathing disorders",
  "D9961 - Duplicate/copy patient's records",
  "D9970 - Enamel microabrasion",
  "D9971 - Odontoplasty per tooth",
  "D9972 - External bleaching per arch-performed in office",
  "D9973 - External bleaching - per tooth",
  "D9974 - Internal bleaching - per tooth",
  "D9975 - External bleaching for home applications, per arch; includes materials and fabrication of custom trays",
  "D9985 - Sales Tax",
  "D9986 - Missed appointment",
  "D9987 - Cancelled appointment",
  "D9990 - certified translation or sign language services- per visit",
  "D9991 - Dental case management – addressing appointment compliance barriers",
  "D9992 - Dental case management – care coordination",
  "D9993 - Dental case management – motivational interviewing",
  "D9994 - Dental case management- patient education to improve oral health literacy",
  "D9995 - Teledentistry – synchronous; real-time encounter",
  "D9996 - Teledentistry – asynchronous; information stored and forwarded to dentist for subsequent review",
  "D9997 - Dental case management – patients with special health care needs",
  "D9999 - Unspecified adjunctive procedure, by report",
];
